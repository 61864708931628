<template>
  <div>
    <div class="mr-1">
      <b-row>
        <b-col :cols="col1()" style="border-left: solid lightgrey 1px; border-right: solid lightgrey 1px;">
          <cbs-ref-sel-parent v-if="parentObj" :object="parentObj" :entityid="parentEntityId()" @select="onSelectParent" />
        </b-col>
        <b-col :cols="col2()">
          <cbs-ref-sel-picker v-if="refObj"
                              :object="refObj"
                              :parent-key="parentKey()"
                              :parent-value="parentid"
                              :values="values"
                              @select="onSelect"
          />
        </b-col>
      </b-row>
    </div>
    <cbs-data-object :entityid="entityid" hide @load="onLoadRefObj" />
    <cbs-data-reference :entityid="entityid" hide @load="onLoadRef" />
    <cbs-data-object v-if="refObj" :objectid="refObj.object.parentobjectid" hide @load="onLoadParentObj" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BCol, BRow,
} from 'bootstrap-vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsRefSelParent from '@/cubus/components/reference-selector/CbsRefSelParent.vue'
import CbsRefSelPicker from '@/cubus/components/reference-selector/CbsRefSelPicker.vue'

export default {
  name: 'CbsReferenceSelector',
  props: {
    entityid: {
      type: Number,
      default: null,
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CbsRefSelPicker,
    CbsRefSelParent,
    CbsDataObject,
    CbsDebug,
    CbsDataReference,
    BRow,
    BCol,
  },
  data() {
    return {
      ref: null,
      refObj: null,
      parentObj: null,
      parentRef: null,
      parentid: -1,
    }
  },
  methods: {
    onLoadRef(ref) {
      this.ref = ref
    },
    onSelect(ref) { this.$emit('select', ref) },
    onLoadRefObj(refObj) {
      this.refObj = refObj
    },
    onLoadParentObj(parentObj) {
      this.parentObj = parentObj
    },
    col1() {
      if (this.parentObj) return 2
      return 0
    },
    col2() {
      return 12 - this.col1()
    },
    parentEntityId() {
      if (this.parentObj) {
        const attr = this.refObj.attributes.find(a => a.refobjectid === this.parentObj.object.id)
        if (attr) {
          return attr.entityid
        }
        return null
      }
      return null
    },
    parentKey() {
      if (this.parentObj) {
        const attr = this.refObj.attributes.find(a => a.refobjectid === this.parentObj.object.id)
        if (attr) {
          return attr.key
        }
        return null
      }
      return null
    },
    onLoadParentRef(parentRef) {
      this.parentRef = parentRef
    },
    onSelectParent(parentid) {
      this.parentid = parentid
    },
  },
}
</script>

<style scoped>

</style>
