<template>
  <div>
    <v-select v-if="options"
              v-model="record[field]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="options"
              @input="onInputVSelect($event)"
              :class="variant"
              :filterBy="filterBy"
    >
      <template slot="option" slot-scope="item">
        {{ item.title }}
        <small class="text-muted"><i>({{ item.value }})</i></small>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'CbsReferencePicker2',
  components: {
    vSelect,
  },
  props: {
    field: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onInputVSelect(val) {
      // console.log('onInputVSelect', val)
      if (!val) this.record[this.field] = { value: 0, title: '' }
    },
  },
}
</script>

<style scoped>

</style>
