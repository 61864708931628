<template>
  <div>
    <h5 style="margin-top:10px;margin-bottom:20px;">Mount Parameters</h5>
    <cbs-data-object objectsid="mountstep" :hide="true" @load="initObjStep" />
    <cbs-data-table v-if="false && objStep"
                    :object-full="objStep"
                    :filter="filterStep"
                    :hidden-fields="['jsonfilter','jsonmod','jsonparamrecord']"
    />
    <cbs-object-table v-if="objStep"
                      :object-full="objStep"
                      :filter="filterStep"
                      :hidden-fields="['jsonfilter','jsonmod','jsonparamrecord']"
    />
    <cbs-debug v-if="isAdmin()" :context="this" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import Ripple from 'vue-ripple-directive'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataTable from '@/cubus/components/query/CbsDataTable.vue'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsMountParam',
  components: {
    CbsDataTable,
    CbsDataObject,
    CbsObjectTable,
    CbsDebug,
  },
  directives: {
    Ripple,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDebug: false,
      objStep: null,
      filterStep: {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: 'object',
                },
                {
                  type: { sid: 'value' },
                  value: this.objectFull.object.id,
                },
              ],
            },
          ],
        },
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    getProps() { return this._props },
    // eslint-disable-next-line no-underscore-dangle
    getData() { return this._data },
    isAdmin() { return useCubus.isAdmin() },
    initObjStep(obj) {
      this.objStep = obj
    },
  },
}
</script>

<style scoped>

</style>
