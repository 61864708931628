<template>
  <div class="border rounded border-secondary p-1">
    <cbs-json-object v-if="valueType() === 'object'" :value="value" :name="'ROOT'" />
    <!--
    <div v-if="false" class="border border-secondary rounded p-1">
      <div>
        <b-form-group :label="name ? name : 'no_name'" label-cols-md="4">
          <b-input-group id="qwe" size="sm" class="mr-1 mb-1">
            <b-form-input size="sm" v-model="newTitle" />
            <b-input-group-append>
              <b-button size="sm" variant="outline-primary" @click="addString" :disabled="!newTitle"><feather-icon icon="PlusIcon" /> Field</b-button>
              <b-button size="sm" variant="outline-primary" @click="addObject" :disabled="!newTitle"><feather-icon icon="PlusIcon" />  Object</b-button>
              <b-button size="sm" variant="outline-primary" @click="addArray" :disabled="!newTitle"><feather-icon icon="PlusIcon" />  Array</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <div class="mb-1">
          elements:
          <div v-for="(key, keyidx) in Object.keys(value)" :key="keyidx">
            <div v-if="attrType(key) === 'object'">
              <cbs-json-editor :value="value[key]" :name="key" />
            </div>
            <div v-else-if="attrType(key) === 'array'">{{ key }} is array</div>
            <div v-else>{{ key }} is field</div>
          </div>
        </div>
        <div class="mb-1">
          {{ value }}
        </div>
      </div>
      <cbs-debug :context="this" />
    </div>
    -->
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsJsonObject from '@/cubus/components/json-editor/CbsJsonObject.vue'

export default {
  name: 'CbsJsonEditor',
  components: {
    CbsJsonObject,
    CbsDebug,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newTitle: null,
    }
  },
  methods: {
    clearTitle() {
      this.newTitle = null
    },
    addString() {
      this.$set(this.value, this.newTitle, null)
      this.clearTitle()
    },
    addObject() {
      this.$set(this.value, this.newTitle, {})
      this.clearTitle()
    },
    addArray() {
      this.$set(this.value, this.newTitle, [])
      this.clearTitle()
    },
    attrType(key) {
      console.log('key', key)
      console.log('attr', this.value[key])
      const attr = this.value[key]
      if (attr instanceof Array) return 'array'
      if (attr === null) return 'field'
      return typeof this.value[key]
    },
    valueType() {
      if (this.value instanceof Array) return 'array'
      if (this.value === null) return 'field'
      return typeof this.value
    },
  },
}
</script>

<style scoped>

</style>
