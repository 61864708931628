<template>
  <div>
    <v-select v-if="ref"
              v-model="record[fieldName]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="ref"
              @input="onInputVSelect($event)"
              :class="variant"
              :filterBy="filterBy"
    >
      <template slot="option" slot-scope="item">
        {{ item.title }}
        <small class="text-muted"><i>({{ item.value }})</i></small>
      </template>
    </v-select>
    <cbs-data-reference hide :entityid="refEntityId()" @load="onLoadRef" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'

export default {
  name: 'CbsReferencePickerThree',
  components: {
    vSelect,
    CbsDataReference,
  },
  props: {
    fieldName: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
    entityId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ref: null,
    }
  },
  methods: {
    onLoadRef(ref) {
      this.ref = ref
    },
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onInputVSelect(val) {
      // console.log('onInputVSelect', val)
      if (!val) this.record[this.field.key] = { value: 0, title: '' }
    },
    refEntityId() {
      return this.entityId
    },
  },
}
</script>

<style scoped>

</style>
