<template>
  <div>
    <b-button v-show="false"
              :ref="'modal_btn_' + uuid"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :v-b-modal="'modal_box_' + uuid"
    >
      Message Form
    </b-button>
    <b-modal
      :id="'modal_box_' + uuid"
      :ref="'ref_modal_' + uuid"
      ok-only
      ok-title="OK"
      centered
      title="Message"
      :modal-class="'modal-' + variant"
      @ok="onClick"
    >
      {{ message }}
    </b-modal>
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BModal,
} from 'bootstrap-vue'

export default {
  name: 'CbsModal',
  components: {
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    message: {
      type: String,
      default: 'Modal message text.',
    },
  },
  emits: [],
  data() {
    return {
      uuid: null,
    }
  },
  created() { this.init() },
  methods: {
    init() {
      this.uuid = useCubus.guid()
    },
    onClick() {
      this.hide()
    },
    show() {
      this.$refs[`ref_modal_${this.uuid}`].show()
    },
    hide() {
      this.$refs[`ref_modal_${this.uuid}`].hide()
    },
  },
}
</script>

<style scoped>

</style>
