import { render, staticRenderFns } from "./CbsCtrlRelation.vue?vue&type=template&id=5ae87447&scoped=true"
import script from "./CbsCtrlRelation.vue?vue&type=script&lang=js"
export * from "./CbsCtrlRelation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae87447",
  null
  
)

export default component.exports