<template>
  <div>
    <div v-if="thread">
      <div v-if="thread.currenttask || preThread" style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
        <div v-if="preThread" class="progress-wrapper" style="margin-bottom: 10px;">
          <b-card-text class="mb-0">
            {{ preElapsed || elapsed(preThread) }} - <b>{{ (preThread.progress || 0) + '%' }}</b> - {{ preThread.currenttask || 'idle' }}
          </b-card-text>
          <b-progress :variant="preThread.status === 'idle' ? 'secondary' : (preThread.progress === 100 ? 'success' : 'primary')" v-model="preThread.progress || 0" max="100" />
        </div>
        <div class="progress-wrapper">
          <b-card-text class="mb-0">
            {{ elapsed(thread) }} - <b>{{ (thread.progress || 0) + '%' }}</b> - {{ thread.currenttask || 'idle' }}
          </b-card-text>
          <b-progress v-model="thread.progress || 0" max="100" />
        </div>
        <b-button-toolbar
          key-nav
          aria-label="Toolbar with button groups"
          style="margin-top:15px;"
        >
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button
              :variant="activeTab === 'result' ? 'primary' : 'outline-primary'"
              @click="showTab('result')"
            >
              {{ t('Result') }}
            </b-button>
            <b-button
              :variant="activeTab === 'log' ? 'primary' : 'outline-primary'"
              @click="showTab('log')"
            >
              {{ t('Log') }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <div v-if="thread.html" style="margin-top: 10px;">
          <p v-if="activeTab === 'result'" style="margin-top:10px;" v-html="thread.html" />
        </div>
        <div v-if="thread && thread.log && thread.log.length > 0" style="margin-top: 10px;">
          <div v-if="activeTab === 'log'" style="margin-top:10px;max-height: 300px;overflow: auto; border-radius: 5px;padding:5px;margin-top:5px;" class="border-secondary">
            <p v-for="(logLine, logIdx) in thread.log" :key="logIdx" style="margin-top:0px;margin-bottom:0px;">
              {{ logLine }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar, BCardText, BProgress,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  name: 'CbsThread',
  components: {
    BCardText,
    BProgress,
    BButton,
    BButtonToolbar,
    BButtonGroup,
  },
  props: {
    thread: {
      type: Object,
      default: () => ({}),
    },
    preThread: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  data() {
    return {
      showResult: false,
      showLog: false,
      activeTab: 'none',
      preElapsed: null,
    }
  },
  methods: {
    showTab(tab) {
      if (this.activeTab === tab) {
        this.activeTab = 'none'
      } else {
        this.activeTab = tab
      }
    },
    elapsed(thr) {
      if (!thr) return 'no_thread'
      if (!thr.start) return 'no_start'
      if (thr.start === 0) return 'null_start'
      // console.log('thr', thr)
      const finish = (thr.finish && thr.finish > 0) ? thr.finish : Date.now()
      // const currentTime = Math.floor(Date.now() / 1000) // Получаем текущее время в секундах
      const elapsedTime = Math.floor((finish - thr.start) / 1000) // Вычисляем прошедшее время в секундах
      // Вычисляем часы, минуты и секунды
      const hours = Math.floor(elapsedTime / 3600)
      const minutes = Math.floor((elapsedTime % 3600) / 60)
      const seconds = elapsedTime % 60
      // Форматируем время в формат "чч:мм:сс"
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      if (this.preTread && this.preThread.status === 'process') {
        this.checkPreThread()
      }
      return formattedTime
    },
    delayPreThread() {
      setTimeout(() => this.timer(), 1000)
    },
    timer() {
      // console.log('checkPreThread', this.preThread)
      if (this.preThread && this.preThread.status === 'process') {
        this.preElapsed = this.elapsed(this.preThread)
        this.delayPreThread()
      }
    },
  },
}
</script>

<style scoped>

</style>
