<template>
  <div>
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import useJwt from '@/cubus/jwt/useJwt'

export default {
  name: 'CbsDataRefsid',
  components: {
    CbsDebug,
  },
  props: {
    entityid: {
      type: Number,
      default: null,
    },
    entitysid: {
      type: String,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'load',
  ],
  data() {
    return {
      ref: null,
    }
  },
  watch: {
    entityid(newVal, oldVal) {
      this.init()
    },
    entitysid(newVal, oldVal) {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadRef()
    },
    loadRef() {
      useJwt.query({
        query: {
          method: 'entrefsid',
          param: {
            entityid: this.entityid ? this.entityid : 0,
            entitysid: this.entitysid ? this.entitysid : '',
          },
        },
      })
        .then(response => {
          console.log('entrefsid response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadRef(response.data.thread)
          } else {
            useCubus.toastError(this, `Reference ${this.entitysid ? this.entitysid : this.entityid} not found.`)
          }
        })
        .catch(error => {
          console.log('entrefsid error', error)
          useCubus.toastError(this, error)
        })
    },
    threadRef(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                console.log(`entrefsid result${this.title()}`, response.data.thread.result)
                this.ref = response.data.thread.result.refsid
                this.onLoad()
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayRef(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayRef(thread) { setTimeout(() => this.threadRef(thread), 500) },
    onLoad() {
      this.$emit('load', this.ref)
    },
    title() {
      return ` [${this.entityid ? this.entityid : 0},${this.entitysid ? this.entitysid : ''}]`
    },
  },
}
</script>

<style scoped>

</style>
