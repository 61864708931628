<template>
  <div>
    <div v-show="!hide">
      <b-row>
        <b-col style="padding-bottom: 5px;">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-primary" @click="saveNewRecord">
                <feather-icon icon="PlusIcon" />
                Create ViewParam
              </b-button>
            </b-input-group-prepend>
            <b-form-input v-model="newName" placeholder="Name of ViewParam" />
          </b-input-group>
        </b-col>
      </b-row>
      <cbs-object-table v-if="srcObject"
                        :ref="`refDataTable_${uuid}`"
                        :object-full="srcObject"
                        :row-buttons="rowButtons"
                        :hidden-fields="hiddenFields"
                        :filter="tableViewFilter()"
                        :is-toolbar="false"
                        @clickRowButton="onClickRowButton"
      />

      <div v-show="false">
        <b-button-group size="sm">
          <b-button size="sm" variant="outline-primary" @click="getNewRecord">
            <feather-icon icon="PlusIcon" />
            New Record
          </b-button>
          <b-button size="sm" variant="outline-primary" @click="saveRecord">
            <feather-icon icon="SaveIcon" />
            Save Record
          </b-button>
        </b-button-group>
        <cbs-data-object objectsid="tableviewparam"
                         @load="loadObject"
        />
        <cbs-data-query v-if="srcObject"
                        :object="srcObject"
                        :filter="tableViewFilter()"
                        @load="loadQuery"
        />
        <cbs-data-new-record v-if="srcObject"
                             :ref="'refNewRecord_' + uuid"
                             :object-full="srcObject"
                             :filter="tableViewFilter()"
                             @load="onNewRecord"
        />
        <cbs-data-save-record v-if="srcObject"
                              :ref="'refSaveRecord_' + uuid"
                              :object-full="srcObject"
                              :record="recordToSave"
                              @save="onSave"
        />
      </div>

      <!-- save modal -->
      <div>
        <b-button
          v-show="false"
          :ref="'btn_save_' + uuid"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal="'modal_save_' + uuid"
          variant="outline-primary"
        >
          Save Modal
        </b-button>
        <b-modal
          :id="'modal_save_' + uuid"
          cancel-variant="outline-warning"
          ok-title="Yes"
          cancel-title="No"
          centered
          title="Save parameters"
          @ok="onOkSave"
          @cancel="onCancelSave"
          @close="onCloseSave"
        >
          Are you sure to rewrite table view parameters?
        </b-modal>
      </div>
      <!-- /save modal -->
      <cbs-debug v-if="isAdmin()" :context="this" />
    </div>
  </div>
</template>

<script>

import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsDataNewRecord from '@/cubus/components/query/CbsDataNewRecord.vue'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BModal, BRow, VBToggle,
} from 'bootstrap-vue'
import CbsDataSaveRecord from '@/cubus/components/query/CbsDataSaveRecord.vue'
import store from '@/store/index'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CbsTableViewParam',
  components: {
    CbsDataObject,
    // CbsDataTable: () => import('@/cubus/components/query/CbsDataTable.vue'),
    CbsObjectTable: () => import('@/cubus/components/object/CbsObjectTable.vue'),
    CbsDataQuery,
    CbsDataNewRecord,
    BButton,
    CbsDataSaveRecord,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BRow,
    BCol,
    CbsDebug,
    BModal,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    filter: {
      type: Object,
      default: () => ({
        node: {
          isactive: false,
          isexpanded: false,
          name: 'Root Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [],
          conds: [],
        },
      }),
    },
    groupby: {
      type: Object,
      default: () => ({
        isActive: false,
        groups: [],
      }),
    },
    orderby: {
      type: Object,
      default: () => ({
        isActive: false,
        groups: [],
      }),
    },
    pagesize: {
      type: Number,
      default: 10,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'changepagesize', 'applyparam', 'applyparamready', 'applydefault',
  ],
  data() {
    return {
      uuid: null,
      srcObject: null,
      rowButtons: [
        {
          title: 'Apply',
          icon: 'PlayIcon',
          emit: 'applyViewParam',
          class: 'secondary',
        },
        {
          title: 'Save',
          icon: 'SaveIcon',
          emit: 'saveViewParam',
          class: 'secondary',
        },
      ],
      isDebug: false,
      newRecord: null,
      query: null,
      newName: null,
      recordToSave: null,
      hiddenFields: [
        'jsonstring',
      ],
      currentRow: null,
      mode: 'init',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
    },
    loadObject(obj) {
      // console.log('load object', obj)
      this.srcObject = obj
      this.initObject()
      this.getNewRecord()
    },
    initObject() {
      this.srcObject.attributes.find(attr => attr.key === 'jsonstring').visible = false
    },
    loadQuery(qry) {
      this.query = qry
      if (this.mode === 'init') {
        this.applyDefault()
      }
    },
    applyDefault() {
      // eslint-disable-next-line no-shadow
      const rec = this.query.recordset.records.find(rec => rec.isdefault.value === true)
      if (rec) {
        // this.applyViewParam(rec.jsonstring.value)
        this.mode = 'apply'
        const prm = this.getParam(rec.jsonstring.value)
        prm.status = 'default'
        this.$emit('applydefault', prm)
      } else {
        const param = {
          filter: store.getters['cubus-store/BLANK_FILTER'],
          groupby: null,
          orderby: null,
          pagesize: 10,
          fields: null,
          status: 'no_default',
        }
        this.$emit('applydefault', param)
      }
    },
    tableViewFilter() {
      return {
        isActive: true,
        node: {
          isactive: true,
          oper: { sid: 'and', name: 'And' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: {
                sid: 'equal',
                name: '=',
              },
              args: [
                {
                  type: {
                    sid: 'entitysid',
                    name: 'EntitySid',
                  },
                  value: 'object',
                },
                {
                  type: {
                    sid: 'value',
                    name: 'Value',
                  },
                  value: `${this.object.id}`,
                },
              ],
            },
            {
              isactive: true,
              oper: {
                sid: 'equal',
                name: '=',
              },
              args: [
                {
                  type: {
                    sid: 'entitysid',
                    name: 'EntitySid',
                  },
                  value: 'usersid',
                },
                {
                  type: {
                    sid: 'value',
                    name: 'Value',
                  },
                  value: `${this.$store.getters['cubus-store/SESSION'].user.sid}`,
                },
              ],
            },
          ],
        },
      }
    },
    onClickRowButton(btn, row) {
      console.log('onClickRowButton', btn, row)
      if (btn.emit === 'applyViewParam') {
        this.applyViewParam(row.item.jsonstring.value)
      } else if (btn.emit === 'saveViewParam') {
        this.clickSaveViewParam(row)
      }
    },
    clickSaveViewParam(row) {
      this.currentRow = row
      this.$refs[`btn_save_${this.uuid}`].click()
    },
    saveViewParam() {
      const row = this.currentRow
      console.log('saveViewParam row', row)
      const r = JSON.parse(JSON.stringify(row.item))
      r.jsonstring = {
        value: this.getCurrentParam(),
        status: 'changed',
      }
      this.recordToSave = r
      console.log('recordToSave', this.recordToSave)
      this.saveRecord()
    },
    onOkSave() {
      console.log('ok')
      this.saveViewParam()
    },
    onCancelSave() {
      console.log('cancel')
      this.currentRow = null
    },
    onCloseSave() {
      console.log('close')
      this.currentRow = null
    },
    async getNewRecord() {
      await this.$nextTick()
      this.$refs[`refNewRecord_${this.uuid}`].getNewRecord()
    },
    onNewRecord(newrec) {
      // console.log('load new record', newrec)
      this.newRecord = newrec
    },
    saveNewRecord() {
      this.prepareNewRecord()
      this.saveRecord()
    },
    async saveRecord() {
      // console.log('save new record')
      // this.prepareNewRecord()
      await this.$nextTick()
      this.$refs[`refSaveRecord_${this.uuid}`].save()
    },
    prepareNewRecord() {
      const r = JSON.parse(JSON.stringify(this.newRecord.record))
      if (this.newName) {
        r.name = {
          value: this.newName,
          status: 'changed',
        }
      }
      r.jsonstring = {
        value: this.getCurrentParam(),
        status: 'changed',
      }
      // console.log('r', r)
      this.recordToSave = r
      return r
    },
    onSave(rec) {
      // console.log('saved record', rec)
      this.$refs[`refDataTable_${this.uuid}`].loadQuery()
    },
    applyViewParam(prm) {
      console.log('prm', prm)
      this.applyFilter(prm.filter || store.getters['cubus-store/BLANK_FILTER'])
      this.applyGroupby(prm.groupby || store.getters['cubus-store/BLANK_GROUPBY'])
      this.applyOrderby(prm.orderby || store.getters['cubus-store/BLANK_ORDERBY'])
      this.applyFields(prm.fields || [])
      this.$emit('changepagesize', Number(prm.pagesize))
      this.$emit('applyparam')
      const param = {
        filter: prm.filter,
        groupby: prm.groupby,
        orderby: prm.orderby,
        pagesize: Number(prm.pagesize),
        fields: prm.fields,
      }
      this.$emit('applyparamready', param)
    },
    getParam(prm) {
      // this.applyFilter(prm.filter || store.getters['cubus-store/BLANK_FILTER'])
      // this.applyGroupby(prm.groupby || store.getters['cubus-store/BLANK_GROUPBY'])
      // this.applyOrderby(prm.orderby || store.getters['cubus-store/BLANK_ORDERBY'])
      // this.applyFields(prm.fields || [])
      return {
        filter: prm.filter,
        groupby: prm.groupby,
        orderby: prm.orderby,
        pagesize: Number(prm.pagesize),
        fields: prm.fields,
      }
    },
    applyFilter(flt) {
      this.filter.node = flt.node
    },
    applyGroupby(grb) {
      this.groupby.isActive = grb.isActive
      this.groupby.groups = grb.groups
    },
    applyOrderby(grb) {
      this.orderby.isActive = grb.isActive
      this.orderby.sorts = grb.sorts
    },
    applyFields(prm) {
      this.fields.splice(0, this.fields.length)
      prm.forEach(p => this.fields.splice(this.fields.length, 0, p))
    },
    getCurrentParam() {
      return {
        filter: this.filter,
        groupby: this.groupby,
        orderby: this.orderby,
        pagesize: this.pagesize,
        fields: this.fields,
      }
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
  },
}
</script>

<style scoped>

</style>
