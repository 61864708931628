<template>
  <div>
    <div style="margin-bottom: 1rem;">
      <span style="cursor: pointer;" @click="expanded = !expanded">
        <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />&nbsp;{{ scenario[keyScenario].title }}
      </span>
    </div>
    <cbs-collapse :trigger="expanded">
      <div v-if="expanded">
        <kbv-bis-table
          :scenario="scenario"
          :indicators="indicators"
          :base-year="baseYear"
          :obj-data="objData"
          :key-by-product="keyByProduct"
          :products="products"
          :key-metric="keyMetric"
          :key-scenario="keyScenario"
        />
      </div>
    </cbs-collapse>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import KbvBisTable from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBISTable.vue'

export default {
  name: 'KbvBudgetIndicatorScenario',
  components: {
    KbvBisTable,
    CbsCollapse,
    CbsDebug,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    indicators: {
      type: Array,
      default: null,
    },
    baseYear: {
      type: Number,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
    keyByProduct: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    keyMetric: {
      type: String,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {},
}
</script>

<style scoped>
</style>
