<template>
  <div v-show="!hide">
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsGroupbyOperands',
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'loaded',
  ],
  components: {
    CbsDebug,
  },
  data() {
    return {
      opers: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadOpers()
    },
    loadOpers() {
      useJwt.query({
        query: {
          method: 'groupbyoperands',
          param: {},
        },
      })
        .then(response => {
          // console.log('groupbyoperands response', response)
          if (response.data.thread) {
            this.delayOpers(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('groupbyoperands error', error)
          useCubus.toastError(this.error)
        })
    },
    delayOpers(thread) {
      setTimeout(() => this.threadOpers(thread), 100)
    },
    threadOpers(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.opers = response.data.thread.result.opers
            this.$emit('loaded', this.opers)
          } else if (response.data.thread.error) {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayOpers(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
  },
}
</script>

<style scoped>

</style>
