<template>
  <div>
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsDataAttribute',
  components: {
    CbsDebug,
  },
  props: {
    attributeId: {
      type: Number,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'load',
  ],
  data() {
    return {
      attr: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadAttr()
    },
    loadAttr() {
      useJwt.query({
        query: {
          method: 'attribute',
          param: {
            attributeid: this.attributeId,
          },
        },
      })
        .then(response => {
          // console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadAttr(response.data.thread)
          } else {
            useCubus.toastError(this, `Entity ${this.entitysid ? this.entitysid : this.entityid} not found.`)
          }
        })
        .catch(error => {
          console.log('entref error', error)
          useCubus.toastError(this, error)
        })
    },
    threadAttr(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.attr = response.data.thread.result.attribute
                this.onLoad()
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayAttr(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayAttr(thread) { setTimeout(() => this.threadAttr(thread), 500) },
    onLoad() {
      this.$emit('load', this.attr)
    },
  },
}
</script>

<style scoped>

</style>
