<template>
  <div>
    <v-select v-if="ref"
              v-model="valueContainer[valueName]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="ref"
              @input="onInputVSelect($event)"
              :class="variant"
              :filterBy="filterBy"
              :placeholder="placeholder"
    >
      <template slot="option" slot-scope="item">
        {{ item.title }}
        <small class="text-muted"><i>({{ item.value }})</i></small>
      </template>
    </v-select>
    <cbs-data-reference hide :entitysid="entitysid" @load="onLoadRef" />
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsReferencePickerSid',
  components: {
    CbsDebug,
    vSelect,
    CbsDataReference,
  },
  props: {
    valueContainer: {
      type: Object,
      default: null,
    },
    valueName: {
      type: String,
      default: null,
    },
    entitysid: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ref: null,
    }
  },
  methods: {
    onLoadRef(ref) {
      this.ref = ref
    },
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onInputVSelect(val) {
      // console.log('onInputVSelect', val)
      // if (!val) this.record[this.field.key] = { value: null, title: null, sid: null }
    },
    refEntityId() {
      if (this.field.entitysid === 'parent') {
        return this.field.parententityid
      }
      return this.field.entityid
    },
  },
}
</script>

<style scoped>

</style>
