<template>
  <div>
    <div style="margin-bottom: 1rem;">
      <span>Режим отображения:</span>&nbsp;&nbsp;
      <b-button-group size="sm">
        <b-button :variant="viewMode === 'tree' ? 'primary' : 'outline-primary'" @click="viewMode = 'tree'"><feather-icon icon="ListIcon" />&nbsp;Иерархия</b-button>
        <b-button :variant="viewMode === 'table' ? 'primary' : 'outline-primary'" @click="viewMode = 'table'"><feather-icon icon="LayoutIcon" />&nbsp;Таблица</b-button>
      </b-button-group>
    </div>

    <cbs-table-view v-if="viewMode === 'table'"
                    :object-full="object"
                    :domainsid="domainsid"
                    :row-buttons="rowButtons"
                    :row-dropdown-buttons="rowDropdownButtons"
                    :hidden-fields="hiddenFields"
    />

    <cbs-tree-view v-else-if="viewMode === 'tree'" :object="object" />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import CbsTableView from '@/cubus/components/object/CbsTableView.vue'
import CbsTreeView from '@/cubus/components/tree-view/CbsTreeView.vue'

export default {
  name: 'CbsCatalog',
  components: {
    CbsTreeView,
    CbsTableView,
    CbsDebug,
    BButtonGroup,
    BButton,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    domainsid: {
      type: String,
      default: '',
    },
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    rowDropdownButtons: {
      type: Array,
      default: () => ([]),
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      viewMode: 'tree',
    }
  },
}
</script>

<style scoped>

</style>
