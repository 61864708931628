<template>
  <div>
    <div>
      <b-row>
        <b-col style="margin-top: 0px; margin-bottom: 10px;"><b>ВСЕГО:</b></b-col>
      </b-row>
      <b-row v-if="totalsQuery">
        <b-col cols="md-4" style="margin-bottom: 0px;">Проекты: <b>{{ totalsQuery.recordset.records[0].qty.value || 0 }}</b></b-col>
        <b-col cols="md-4" style="margin-bottom: 0px;">Бюджет: <b>{{ totalsQuery.recordset.records[0].ent_163.value.toLocaleString('ru-RU') || 0 }}</b> тенге</b-col>
        <b-col cols="md-4" style="margin-bottom: 20px;">Маржа: <b>{{ totalsQuery.recordset.records[0].ent_165.value.toLocaleString('ru-RU') || 0 }}</b> тенге</b-col>
      </b-row>
    </div>
    <div>
      <cbs-object-table v-if="sourceObject"
                        :ref="'cbs_obj_tbl_' + uuid"
                        :object-full="sourceObject"
                        :is-toolbar="false"
                        :hidden-fields="['actions']"
      />
    </div>
    <div v-show="false">
      <cbs-data-object v-if="sourceObjectId" hide :objectid="sourceObjectId" @load="onLoadSourceObject" />
      <cbs-data-object v-if="totalsObjectId" hide :objectid="totalsObjectId" @load="onLoadTotalsObject" />
      <cbs-object-table v-if="totalsObject"
                        :ref="'totals_obj_tbl_' + uuid"
                        :object-full="totalsObject"
                        :is-toolbar="false"
                        :hidden-fields="['actions']"
                        @loaded="onLoadTotalsQuery"
      />
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'
import {
  BCol, BRow,
} from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'AslProjects',
  components: {
    CbsDataObject,
    CbsDebug,
    CbsObjectTable,
    BRow,
    BCol,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      uuid: null,
      sourceObjectId: null,
      sourceObject: null,
      totalsObjectId: null,
      totalsObject: null,
      totalsQuery: null,
    }
  },
  created() {
    this.init()
  },
  computed: {
    totProj() {
      const r = this.$refs[`cbs_obj_tbl_${this.uuid}`]
      if (r) {
        console.log('cbs_obj_tbl', r.$data)
        return this.$refs[`cbs_obj_tbl_${this.uuid}`].$data.query.recordcount
      }
      console.log('cbs_obj_tbl EMPTY')
      return 0
    },
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      this.initSourceObjectId()
      this.initTotalsObjectId()
    },
    initSourceObjectId() {
      if (this.objectFull && this.objectFull.params && this.objectFull.params.params) {
        const prm = this.objectFull.params.params.find(p => p.paramname === 'source_object_id')
        if (prm) {
          this.sourceObjectId = Number(prm.paramvalue)
        }
      }
    },
    initTotalsObjectId() {
      if (this.objectFull && this.objectFull.params && this.objectFull.params.params) {
        const prm = this.objectFull.params.params.find(p => p.paramname === 'totals_object_id')
        if (prm) {
          this.totalsObjectId = Number(prm.paramvalue)
        }
      }
    },
    onLoadSourceObject(obj) {
      this.sourceObject = obj
    },
    onLoadTotalsObject(obj) {
      this.totalsObject = obj
    },
    onLoadTotalsQuery(qry) {
      this.totalsQuery = qry
    },
    totalProjects() {
      const r = this.$refs[`cbs_obj_tbl_${this.uuid}`]
      if (r) {
        return this.$refs[`cbs_obj_tbl_${this.uuid}`].$data.query.recordcount
      }
      return 0
    },
  },
}
</script>

<style scoped>

</style>
