<template>
  <span class="mr-0">
    <feather-icon v-if="status === 'changed'"
                  icon="ArrowUpCircleIcon"
                  class="text-warning"
                  size="14"
    />
    <feather-icon v-else-if="status === 'saved'"
                  icon="CheckCircleIcon"
                  class="text-success"
                  size="14"
    />
    <feather-icon v-else-if="status === 'error'"
                  icon="XCircleIcon"
                  class="text-danger"
                  size="14"
    />
    <feather-icon v-else
                  icon="CircleIcon"
                  class="text-secondary"
                  size="14"
    />
  </span>
</template>

<script>
export default {
  name: 'CbsTableRecordStatus',
  props: {
    status: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
