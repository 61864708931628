<template>
  <b-collapse v-model="trigger">
    <transition name="fade">
      <template>
        <slot />
      </template>
    </transition>
  </b-collapse>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'

export default {
  name: 'CbsCollapse',
  components: {
    BCollapse,
  },
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
