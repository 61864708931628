<template>
<div>
  <div>
    <div>
      Scalar:
      <cbs-reference-picker-sid entitysid="scalar" :value-container="primaryRecord" value-name="scalar" />
      <b-button :disabled="!primaryRecord.scalar.value" size="sm" variant="outline-primary" @click="showScalar()">Show</b-button>
    </div>
    <div>
      Mount:
      <cbs-reference-picker-sid entitysid="scalar" :value-container="primaryRecord" value-name="scalar" />
      <b-button :disabled="!primaryRecord.scalar.value" size="sm" variant="outline-primary" @click="showScalar()">Show</b-button>
    </div>
  </div>
  <div v-if="show === 'scalar'">
    <cbs-view-scalar :scalar="primaryRecord.scalar" />
  </div>
  <div style="margin-top: 1rem;">
    Content:
  </div>
  <cbs-debug :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'
import {
  BButton,
} from 'bootstrap-vue'
import CbsViewScalar from '@/cubus/components/cbs-form/custom-forms/calc-scheme/CbsViewScalar.vue'
import useCubus from "@/cubus/services/useCubus";

export default {
  name: 'CbsCalcScheme',
  components: {
    CbsViewScalar,
    CbsDebug,
    CbsReferencePickerSid,
    BButton,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      primaryRecord: {
        scalar: { value: null, title: null, sid: null },
        function: { value: null, title: null, sid: null },
        formula: { value: null, title: null, sid: null },
      },
      show: null,
      qryMount: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.loadMounts()
    },
    async loadMounts() {
      const params = {
        keymode: 'sid',
        objectsid: 'object',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
      }
      this.qryMount = await useCubus.loadQuery(this, params)
    },
    showScalar() {
      console.log('scalar', this.primaryRecord.scalar)
      this.show = 'scalar'
    },
  },
}
</script>

<style scoped>

</style>
