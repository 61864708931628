<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="12">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Search" v-model="search" />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="clearSearch">
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-if="query">
      <b-col v-for="rec in filtered()" :key="rec.id.value" cols="3" class="mb-1">
        <b-button block :variant="buttonVariant(rec)" @click="onSelect(rec)">
          {{rec.name.value}}
        </b-button>
      </b-col>
    </b-row>

    <cbs-data-query v-if="object" :object="object" :orderby="orderby" :page-size="0" hide @load="onLoadQuery" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import {
  BButton, BCol, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BRow,
} from 'bootstrap-vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'

export default {
  name: 'CbsRefSelPicker',
  components: {
    CbsDataQuery,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    CbsDebug,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'select',
  ],
  data() {
    return {
      query: null,
      search: null,
      orderby: {
        isActive: true,
        sorts: [
          {
            isActive: true,
            name: 'asc by name',
            oper: { sid: 'asc' },
            entity: { sid: 'name' },
          },
        ],
      },
    }
  },
  methods: {
    onSelect(el) {
      this.$emit('select', { id: el.id.value, name: el.name.value })
    },
    onLoadQuery(query) { this.query = query },
    parentFiltered() {
      if (this.parentValue === -1) {
        return this.query.recordset.records
      }
      if (this.parentValue === 0) {
        return this.query.recordset.records.filter(el => el[this.parentKey].value === null)
      }
      return this.query.recordset.records.filter(el => el[this.parentKey].value === this.parentValue)
    },
    filtered() {
      if (!this.search) {
        return this.parentFiltered()
      }
      return this.parentFiltered().filter(el => el.name.value.toLowerCase().includes(this.search.toLowerCase()))
    },
    clearSearch() {
      this.search = null
    },
    buttonVariant(rec) {
      if (this.values.some(v => v.value === rec.id.value)) {
        return 'info'
      }
      return 'outline-info'
    },
  },
}
</script>

<style scoped>

</style>
