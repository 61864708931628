<template>
  <div>
    <b-row class="pt-1">
      <b-col cols="4">
        <div class="pl-1 pb-1">
          TOTAL: {{total()}}
        </div>
        <b-table v-if="query"
          style="min-height: 250px;"
          class="ml-1 pr-1"
          responsive
          small
          :fields="visibleFields"
          :items="records()"
        >
          <template v-for="(field, fldidx) in visibleFields"
                    v-slot:[`cell(${field.key})`]="data"
          >
            <div v-bind:key="fldidx">
              <div>
                <span v-if="data.value.title">
                  {{data.value.title}} ({{data.value.value}})
                  <span v-if="field.key === refKey()" class="text-danger" style="cursor: pointer" @click="deleteRecord(data.item)">
                    &nbsp;<feather-icon icon="Trash2Icon" />
                  </span>
                </span>
                <div v-else-if="field.key === qtyKey()">
                  <b-button size="sm" pill variant="outline-info" @click="oneLess(data.item)">-</b-button>
                  &nbsp;{{data.value.value}}&nbsp;
                  <b-button size="sm" pill variant="outline-info" @click="oneMore(data.item)">+</b-button>
                </div>
                <span v-else>
                  {{data.value.value}}
                </span>
              </div>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button size="sm" variant="flat-danger"><feather-icon icon="Trash2Icon" @click="deleteRecord(data.item)" /></b-button>
          </template>
        </b-table>
      </b-col>
      <b-col cols="8">
        <cbs-reference-selector :entityid="refEntityid()" :values="values()" @select="onSelectRef" />
      </b-col>
    </b-row>
    <cbs-data-query v-if="objectFull" :object="objectFull" :filter="filter" @load="onLoadQuery" />
    <cbs-data-new-record hide :ref="'new_rec_' + uuid" v-if="objectFull" :object-full="objectFull" :filter="newRecordFilter" @load="onLoadNewRecord" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BCol, BRow, BTable,
} from 'bootstrap-vue'
import CbsReferenceSelector from '@/cubus/components/reference-selector/CbsReferenceSelector.vue'
import CbsDataNewRecord from '@/cubus/components/query/CbsDataNewRecord.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsOrderSpec',
  components: {
    CbsDataNewRecord,
    CbsReferenceSelector,
    CbsDebug,
    CbsDataQuery,
    BRow,
    BCol,
    BTable,
    BButton,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      query: null,
      blankRecord: null,
      newRecordFilter: null,
      selectedRef: null,
      uuid: null,
    }
  },
  computed: {
    visibleFields() {
      const flds = this.query.columns
        .filter(prm => prm.visible)
        .filter(prm => !this.hiddenFields.some(fld => fld === prm.key))
        .map(prm => prm)
      // flds.unshift({ key: 'actions', label: '' })
      return flds
    },
  },
  created() {
    this.uuid = useCubus.guid()
  },
  methods: {
    onLoadQuery(query) {
      this.query = query
    },
    refEntityid() {
      const prm = this.objectFull.params.params.find(p => p.paramname === 'orderspec_entity')
      if (prm) {
        return prm.entityid
      }
      return null
    },
    qtyEntityid() {
      const prm = this.objectFull.params.params.find(p => p.paramname === 'orderspec_qty')
      if (prm) {
        return prm.entityid
      }
      return null
    },
    amountEntityid() {
      const prm = this.objectFull.params.params.find(p => p.paramname === 'orderspec_amount')
      if (prm) {
        return prm.entityid
      }
      return null
    },
    qtyKey() {
      const entid = this.qtyEntityid()
      const attr = this.objectFull.attributes.find(a => a.entityid === entid)
      if (attr) {
        return attr.key
      }
      return null
    },
    refKey() {
      const entid = this.refEntityid()
      const attr = this.objectFull.attributes.find(a => a.entityid === entid)
      if (attr) {
        return attr.key
      }
      return null
    },
    amountKey() {
      const entid = this.amountEntityid()
      const attr = this.objectFull.attributes.find(a => a.entityid === entid)
      if (attr) {
        return attr.key
      }
      return '_no_key_'
    },
    refAttribute() {
      return this.objectFull.attributes.find(attr => attr.entityid === this.refEntityid())
    },
    qtyAttribute() {
      return this.objectFull.attributes.find(attr => attr.entityid === this.qtyEntityid())
    },
    amountAttribute() {
      return this.objectFull.attributes.find(attr => attr.entityid === this.amountEntityid())
    },
    records() {
      /*
      return this.query.recordset.records.map(r => {
        return { actions: { value: null, title: 'ACTIONS' }, ...r }
      })
      */
      return this.query.recordset.records
    },
    values() {
      if (this.query) {
        return this.query.recordset.records.map(rec => rec[this.refAttribute().key])
      }
      return []
    },
    onSelectRef(ref) {
      this.selectedRef = ref
      this.blankRecord = null
      // this.newRecordFilter = null
      const rec = this.getRecord(ref)
      if (!rec) {
        this.addRecord(ref)
      } else {
        this.increaseRecord(rec)
        // rec[this.qtyAttribute().key].value += 1
      }
    },
    getRecord(ref) {
      return this.query.recordset.records.find(rec => rec[this.refAttribute().key].value === ref.id)
    },
    onLoadNewRecord(newRecord) {
      this.blankRecord = newRecord
      if (this.selectedRef) {
        const rec = this.getNewRecord(this.selectedRef)
        rec[this.refKey()].status = 'changed'
        this.query.recordset.records.push(rec)
        this.saveRecord(rec)
      }
    },
    getNewRecord(ref) {
      let r = JSON.parse(JSON.stringify(this.blankRecord.record))
      r = Object.assign(r, {
        [this.refAttribute().key]: { value: ref.id, title: ref.name },
      })
      r = Object.assign(r, {
        [this.qtyAttribute().key]: { value: 1, title: '' },
      })
      return r
    },
    addRecord(ref) {
      this.newRecordFilter = this.getNewRecordFilter(ref)
    },
    getNewRecordFilter(ref) {
      const flt = JSON.parse(JSON.stringify(this.filter))
      flt.node.conds.push(this.getNewRecordRefCondition(ref))
      flt.node.conds.push(this.getNewRecordQtyCondition(ref))
      return flt
    },
    getNewRecordRefCondition(ref) {
      return {
        isactive: true,
        name: `Ref Condition ${ref.name}`,
        oper: {
          id: 1, sid: 'equal', name: '=', args: 2,
        },
        args: [
          { type: { id: 1, sid: 'entity', name: 'Entity' }, value: this.refEntityid() },
          { type: { id: 2, sid: 'value', name: 'Value' }, value: ref.id },
        ],
      }
    },
    getNewRecordQtyCondition(ref) {
      return {
        isactive: true,
        name: `Qty Condition ${ref.name}`,
        oper: {
          id: 1, sid: 'equal', name: '=', args: 2,
        },
        args: [
          { type: { id: 1, sid: 'entity', name: 'Entity' }, value: this.qtyEntityid() },
          { type: { id: 2, sid: 'value', name: 'Value' }, value: 1 },
        ],
      }
    },
    increaseRecord(rec) {
      // eslint-disable-next-line no-param-reassign
      // rec[this.qtyAttribute().key].value += 1
      const cell = {
        cell: rec[this.qtyAttribute().key],
        attribute: { entityid: this.qtyEntityid() },
      }
      cell.cell.value += 1
      this.$set(rec[this.qtyKey()], 'status', 'changed')
      this.refreshRecord(rec, cell)
    },
    decreaseRecord(rec) {
      const cell = {
        cell: rec[this.qtyAttribute().key],
        attribute: { entityid: this.qtyEntityid() },
      }
      cell.cell.value -= 1
      this.$set(rec[this.qtyKey()], 'status', 'changed')
      if (cell.cell.value > 0) {
        this.refreshRecord(rec, cell)
      } else {
        this.deleteRecord(rec)
      }
    },
    deleteRecord(rec) {
      console.log('deleteRecord', rec)
      const index = this.query.recordset.records.indexOf(rec)
      if (index > -1) {
        this.query.recordset.records.splice(index, 1)
        this.markRecord(rec)
      }
    },
    refreshRecord(record, cell) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'changerecord',
          param: {
            withsave: true,
            object: { id: this.objectFull.object.id },
            record,
            cell,
          },
        },
      })
        .then(response => {
          console.log('refresh record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadRefresh(response.data.thread, record)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadRefresh(thread, record) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadRefresh response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterRefresh(record, response.data.thread.result.record)
          } else {
            this.delayRefresh(thread, record)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayRefresh(thread, record) { setTimeout(() => this.threadRefresh(thread, record), 500) },
    afterRefresh(record, newRecord) {
      // console.log('afterRefresh', record, newRecord)
      for (let key in record) {
        if (record.hasOwnProperty(key) && newRecord.hasOwnProperty(key)){
          if (record[key].value !== newRecord[key].value) {
            this.$set(record[key], 'status', 'changed')
          }
          record[key].value = newRecord[key].value
          record[key].title = newRecord[key].title
        }
      }
      // this.saveRecord(record)
    },
    oneLess(rec) {
      this.decreaseRecord(rec)
    },
    oneMore(rec) {
      this.increaseRecord(rec)
    },
    saveRecord(record) {
      useJwt.query({
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objectFull.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record) { setTimeout(() => this.threadSaveRecord(thread, record), 500) },
    afterSave(initRec, savedRec) {
      this.query.columns.forEach(fld => {
        // console.log('fld', fld)
        if (fld.key !== 'actions') {
          initRec[fld.key] = savedRec[fld.key]
          // initRec[fld.key].status = 'init'
          this.$set(initRec[fld.key], 'status', 'init')
        }
        /*
        if (initRec.hasOwnProperty(fld.key)) {
          this.$set(initRec[fld.key], 'status', 'init')
        }
        */
      })
    },
    removeRecord(rec) {
      rec.status = 'changed'
      rec.del.value = true
      useJwt.query({
        query: {
          method: 'removerecord',
          param: {
            objectid: this.objectFull.object.id,
            record: rec,
          },
        },
      })
        .then(response => {
          console.log('remove record response', response)
          if (response.data) {
            if (response.data.thread) {
              this.delayRemove(response.data.thread, rec)
            } else if (response.data.error) {
              this.toast(response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
          useCubus.toastError(this, error)
        })
    },
    delayRemove(threadName, row) { setTimeout(() => this.threadRemove(threadName, row), 500) },
    threadRemove(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadRemove response', response)
          if (response.data && response.data.thread) {
            if (response.data.thread.error) {
              useCubus.toastError(this, response.data.thread.error)
            } else if (response.data.thread.status === 'done') {
              // this.records.splice(row.index, 1)
              console.log('record removed')
            } else {
              this.delayThreadRemove(threadName, row)
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    markRecord(rec) {
      // console.log('restore record', row)
      rec.status = 'changed'
      rec.del.value = true
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'deleterecord',
          param: {
            objectid: this.objectFull.object.id,
            record: rec,
          },
        },
      })
        .then(response => {
          console.log('delete record response', response)
          if (response.data) {
            if (response.data.thread) this.delayMark(response.data.thread, rec)
            if (response.data.error) useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    delayMark(threadName, row) { setTimeout(() => this.threadMark(threadName, row), 500) },
    threadMark(threadName, row) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadDelete response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done'
                  && response.data.thread.result && response.data.thread.result.record) {
                // this.records.splice(row.index, 1, response.data.thread.result.record)
                console.log('record marked')
                this.removeRecord(row)
              } else {
                this.threadMark(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    total() {
      console.log('amountKey', this.amountKey())
      const ak = this.amountKey()
      if (this.query) {
        return this.query.recordset.records.reduce(function (accumulator, currentValue) {
          console.log('accumulator', accumulator)
          console.log('currentValue', currentValue)
          if (currentValue[ak]) {
            return accumulator + currentValue[ak].value
          }
          return accumulator
        }, 0)
      }
      return 0
    },
  },
}
</script>

<style scoped>

</style>
