<template>
  <div>
    <div>
      <b-button-toolbar class="cbs-inline-spacing mb-1">
        <b-button-group>
          <b-button variant="flat-primary" @click="isExpanded = !isExpanded">
            <feather-icon :icon="isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
          </b-button>
        </b-button-group>
        <b-input-group>
          <div class="ml-1 mr-1">{{ name ? name : 'no_name' }}:</div>
        </b-input-group>
        <b-col>
          <b-input-group>
            <b-form-input v-model="newTitle" placeholder="New attribute name" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="addString" :disabled="!newTitle"><feather-icon icon="PlusIcon" /> Field</b-button>
              <b-button variant="outline-primary" @click="addObject" :disabled="!newTitle"><feather-icon icon="PlusIcon" />  Object</b-button>
              <b-button variant="outline-primary" @click="addArray" :disabled="!newTitle"><feather-icon icon="PlusIcon" />  Array</b-button>
              <b-button v-if="name !== 'ROOT'" variant="outline-danger" @click="deleteMe"><feather-icon icon="TrashIcon" />  Delete</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-button-toolbar>

      <cbs-collapse :trigger="isExpanded">
        <div v-if="isExpanded && value" class="ml-1">
          <div v-for="(key, keyidx) in Object.keys(value)" :key="keyidx" class="mb-1">
            <cbs-json-object v-if="attrType(key) === 'object'" :value="value[key]" :name="key" @delete="deleteAttr" />
            <cbs-json-array v-else-if="attrType(key) === 'array'" :container="value" :name="key" @delete="deleteAttr" />
            <cbs-json-value v-else :container="value" :name="key" @delete="deleteAttr" />
          </div>
        </div>
        <div v-if="isExpanded && parentArray" class="ml-1">
          <div v-for="(key, keyidx) in Object.keys(parentArray[parentArrayIndex])" :key="keyidx" class="mb-1">
            <cbs-json-object v-if="attrType(key) === 'object'" :value="parentArray[parentArrayIndex]" :name="key" @delete="deleteAttr" />
            <cbs-json-array v-else-if="attrType(key) === 'array'" :container="parentArray[parentArrayIndex]" :name="key" @delete="deleteAttr" />
            <cbs-json-value v-else :container="parentArray[parentArrayIndex]" :name="key" @delete="deleteAttr" />
          </div>
        </div>
      </cbs-collapse>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormInput, BInputGroup, BInputGroupAppend, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsJsonValue from '@/cubus/components/json-editor/CbsJsonValue.vue'
import CbsJsonArray from '@/cubus/components/json-editor/CbsJsonArray.vue'

export default {
  name: 'CbsJsonObject',
  components: {
    CbsJsonValue,
    CbsDebug,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    CbsCollapse,
    CbsJsonArray,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    parentArray: {
      type: Array,
      default: null,
    },
    parentArrayIndex: {
      type: Number,
      default: null,
    },
  },
  emit: [
    'delete',
  ],
  data() {
    return {
      isExpanded: false,
      newTitle: null,
    }
  },
  methods: {
    clearTitle() {
      this.newTitle = null
    },
    addString() {
      if (this.value) {
        this.$set(this.value, this.newTitle, null)
      } else {
        this.$set(this.parentArray[this.parentArrayIndex], this.newTitle, null)
      }
      this.clearTitle()
    },
    addObject() {
      if (this.value) {
        this.$set(this.value, this.newTitle, {})
      } else {
        this.$set(this.parentArray[this.parentArrayIndex], this.newTitle, {})
      }
      this.clearTitle()
    },
    addArray() {
      if (this.value) {
        this.$set(this.value, this.newTitle, [])
      } else {
        this.$set(this.parentArray[this.parentArrayIndex], this.newTitle, [])
      }
      this.clearTitle()
    },
    attrType(key) {
      if (this.value) {
        if (this.value[key] instanceof Array) return 'array'
        if (this.value[key] === null) return 'field'
        return typeof this.value[key]
      }
      if (this.parentArray[this.parentArrayIndex] instanceof Array) return 'array'
      if (this.parentArray[this.parentArrayIndex] === null) return 'field'
      return typeof this.parentArray[this.parentArrayIndex]
    },
    deleteAttr(name) {
      if (this.value) {
        this.$delete(this.value, name)
      } else {
        this.parentArray[this.parentArrayIndex].splice(name, 1)
      }
    },
    deleteMe() {
      if (this.name) {
        this.$emit('delete', this.name)
      } else {
        this.$emit('delete', this.parentArrayIndex)
      }
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

</style>
