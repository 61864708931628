<template>
  <div>
    <div style="display: flex;">
      <b-button size="sm"
                :variant="record[keyAccess].value === null ? 'outline-secondary' : 'flat-secondary'"
                :disabled="record[keyAccess].value === null"
                @click="onClickAccess('no')"
      >
        <feather-icon icon="XCircleIcon" />
      </b-button>
      <b-button size="sm"
                :variant="record[keyAccess].value === roValue ? 'primary' : 'flat-secondary'"
                :disabled="record[keyAccess].value === roValue"
                @click="onClickAccess('ro')"
      >
        <feather-icon icon="EyeIcon" />
      </b-button>
      <b-button size="sm"
                :variant="record[keyAccess].value === rwValue ? 'success' : 'flat-secondary'"
                :disabled="record[keyAccess].value === rwValue"
                @click="onClickAccess('rw')"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
    </div>
    <cbs-debug hidden :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'CbsIndAccessCell',
  components: {
    CbsDebug,
    BButton,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    keyAccess: {
      type: String,
      default: null,
    },
    roValue: {
      type: Number,
      default: null,
    },
    rwValue: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update',
  ],
  methods: {
    onClickAccess(value) {
      this.$emit('update', value)
    },
  },
}
</script>

<style scoped>

</style>
