<template>
  <div>
    <div style="margin-bottom: 10px;">
      Export:
    </div>
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="clear"
        >
          <feather-icon icon="XIcon" />
          Clear
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :disabled="url !== null || !obj"
          @click="run"
        >
          <feather-icon icon="PlayIcon" />
          Export
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :href="url"
          :download="filename"
          :disabled="!url"
        >
          <feather-icon icon="DownloadIcon" />
          Download
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <cbs-modal :ref="'export_modal_' + uuid" message="Export done. Now you can download the report." />
    <cbs-debug v-if="isDebug && isAdmin()" :context="this" />
    <cbs-data-object hide objectsid="repexportquery" @load="onLoadObj" />
  </div>
</template>

<script>
import CbsModal from '@/cubus/components/modal/CbsModal.vue'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'

export default {
  name: 'CbsExportQuery',
  components: {
    CbsDataObject,
    CbsDebug,
    CbsModal,
    BButtonToolbar,
    BButtonGroup,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      uuid: null,
      url: null,
      filename: null,
      downloadtoken: null,
      obj: null,
      threadLog: null,
      isDebug: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
    },
    onLoadObj(obj) {
      this.obj = obj
    },
    run_v1() {
      this.$refs[`export_modal_${this.uuid}`].show()
    },
    clear() {
      this.filename = null
      this.exportToken = null
      this.url = null
    },
    run() {
      this.threadLog = []
      this.downloadtoken = null
      // this.record = this.$refs.recordForm.getRecord()
      useJwt.query({
        query: {
          method: 'doreport',
          param: {
            objectid: this.obj.object.id,
            record: this.record,
          },
        },
      })
        .then(response => {
          console.log('report response', response)
          if (response.data.thread) {
            this.threadReport(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'No thread provided.')
          }
        })
        .catch(errror => {
          console.log('report error', errror)
          useCubus.toastError(this, errror)
        })
    },
    threadReport(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('thread upload response', response)
          if (response.data && response.data.thread) {
            if (response.data.thread.log) {
              this.threadLog = response.data.thread.log
            }
            if (response.data.thread.status === 'done') {
              this.downloadtoken = response.data.thread.result.downloadtoken
              this.processDownload()
            } else {
              this.delayReport(thread)
            }
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('thread report error', error)
          useCubus.toastError(this, error)
        })
    },
    delayReport(thread) { setTimeout(() => this.threadReport(thread), 500) },
    processDownload() {
      console.log(this.downloadtoken)
      useJwt.download({
        param: {
          downloadtoken: this.downloadtoken,
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/ms-excel' })
          this.url = window.URL.createObjectURL(blob)
          this.filename = decodeURIComponent(response.headers.filename.replace(/\+/g, ' '))
          this.$refs[`export_modal_${this.uuid}`].show()
        })
        .catch(error => {
          console.log('download error', error)
          useCubus.toastError(this, error)
        })
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
  },
}
</script>

<style scoped>

</style>
