<template>
  <div>
    <label class="mt-1">Widget Type:</label><cbs-reference-picker-sid entitysid="widgettype" :value-container="value" value-name="widgettype" />
    <label class="mt-1">Chart Type:</label><b-form-input v-model="value.charttype" placeholder="Input chart type" />
    <label class="mt-1">Title:</label><b-form-input v-model="value.title" placeholder="Input title" />
    <label class="mt-1">Icon:</label><b-form-input v-model="value.icon" placeholder="Input title" />
    <label class="mt-1">Color:</label><b-form-input v-model="value.color" placeholder="Input title" />
    <label class="mt-1">Metric:</label><b-form-input v-model="value.metric" placeholder="Input title" />
    <label class="mt-1">Source Object:</label><cbs-reference-picker-sid entitysid="object" :value-container="value" value-name="srcobject" />
    <label class="mt-1">Entity:</label><cbs-reference-picker-sid entitysid="entity" :value-container="value" value-name="srcentity" />
    <label class="mt-1">Value Entity:</label><cbs-reference-picker-sid entitysid="entity" :value-container="value" value-name="valueentity" />
    <label class="mt-1">Value Format:</label><b-form-input v-model="value.valueformat" placeholder="Input value format" />
    <label class="mt-1">Value Round:</label><b-form-input v-model="value.valueround" placeholder="Value round" />
    <label class="mt-1">Category Entity:</label><cbs-reference-picker-sid entitysid="entity" :value-container="value" value-name="categoryentity" />
    <label class="mt-1">Category Format:</label><b-form-input v-model="value.categoryformat" placeholder="Input category format" />
    <label class="mt-1">Stack Entity:</label><cbs-reference-picker-sid entitysid="entity" :value-container="value" value-name="stackentity" />
    <label class="mt-1">Scalar:</label><cbs-reference-picker-sid entitysid="scalar" :value-container="value" value-name="scalar" />
    <label class="mt-1">Koef:</label><b-form-input v-model="value.koef" placeholder="Input koef" type="number" :formatter="formatter" />
    <label class="mt-1">Columns:</label><b-form-input v-model="value.columns" placeholder="Input koef" type="number" :formatter="formatter" />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'CbsWidgetParam',
  components: {
    CbsDebug,
    CbsReferencePickerSid,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      refSrcObj: null,
      refSrcEnt: null,
    }
  },
  methods: {
    formatter(value) {
      return Number(value)
    },
  },
}
</script>

<style scoped>

</style>
