<template>
  <div>
    <cbs-object-table v-if="configObj" :object-full="configObj" :filter="configFilter()" />
    <cbs-data-object objectsid="reportconfig" @load="onLoadConfigObj" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'

export default {
  name: 'CbsReportConfig',
  components: {
    CbsDebug,
    CbsDataObject,
    CbsObjectTable,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      configObj: null,
    }
  },
  methods: {
    onLoadConfigObj(obj) {
      this.configObj = obj
    },
    configFilter() {
      return {
        isActive: true,
        node: {
          isactive: true,
          oper: { sid: 'and', name: 'And' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: {
                sid: 'equal',
                name: '=',
              },
              args: [
                {
                  type: {
                    sid: 'entitysid',
                    name: 'EntitySID',
                  },
                  value: 'object',
                },
                {
                  type: {
                    sid: 'value',
                    name: 'Value',
                  },
                  value: `${this.object.object.id}`,
                },
              ],
            },
          ],
        },
      }
    },
  },
}
</script>

<style scoped>

</style>
