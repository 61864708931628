<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="1"><b-form-checkbox v-model="group.isActive"
                                       name="check-button"
                                       switch
                                       inline
                                       class="custom-control-success"
      /></b-col>
      <b-col cols="3">
        <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" :text="group.oper.name" variant="primary" class="btn-block">
        <b-dropdown-item v-for="(oper, operidx) in opers"
                         :key="'oper' + operidx"
                         @click="selectOper(oper.sid)"
        >
          {{ oper.name }}
        </b-dropdown-item>
      </b-dropdown></b-col>
      <b-col cols="3">
        <v-select
          v-model="group.entity"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="fields"
          size="sm"
          placeholder="Select Entity"
        />
      </b-col>
      <b-col cols="3">
        <v-select v-if="group.oper.sid === 'countdistinct'"
            v-model="group.resentity"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="resultFields"
            size="sm"
            placeholder="Select Entity"
        />
      </b-col>
      <b-col v-if="false" cols="2">
        <span v-if="!isEdit" @dblclick="isEdit = !isEdit" class="font-weight-bold" style="font-size: 1.1rem;">
              &nbsp;&nbsp;&nbsp; {{ group.name }} &nbsp;&nbsp;&nbsp;
            </span>
        <b-form-input v-if="isEdit" v-model="group.name" placeholder="Enter Name" size="sm"
                      @dblclick="isEdit = !isEdit" />
      </b-col>
      <b-col cols="2"><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" @click="onDelete">
          <feather-icon icon="TrashIcon" />
          Delete
        </b-button></b-col>
      <b-col v-if="false">
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-form-checkbox v-model="group.isActive"
                             name="check-button"
                             switch
                             inline
                             class="custom-control-success"
            />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :text="group.oper.name"
              variant="primary"
            >
              <b-dropdown-item v-for="(oper, operidx) in opers"
                               :key="'oper' + operidx"
                               @click="selectOper(oper.sid)"
              >
                {{ oper.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <v-select
              v-model="group.entity"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="fields"
              size="sm"
              placeholder="Select Entity"
            />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <span v-if="!isEdit" @dblclick="isEdit = !isEdit" class="font-weight-bold" style="font-size: 1.1rem;">
              &nbsp;&nbsp;&nbsp; {{ group.name }} &nbsp;&nbsp;&nbsp;
            </span>
            <b-form-input v-if="isEdit" v-model="group.name" placeholder="Enter Name" size="sm"
                          @dblclick="isEdit = !isEdit" />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" @click="onDelete">
              <feather-icon icon="TrashIcon" />
              Delete
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsGroupbyItem',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    vSelect,
    BFormCheckbox,
  },
  props: {
    group: {
      type: Object,
      default: () => ({
        isActive: false,
        name: 'new group',
        oper: { sid: 'group', name: 'Group' },
        entity: { id: null, name: null },
      }),
    },
    index: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    resultFields: {
      type: Array,
      default: () => ([]),
    },
    opers: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      isEdit: false,
    }
  },
  methods: {
    selectOper(sid) {
      this.group.oper = this.opers.find(o => o.sid === sid)
    },
    onDelete() {
      console.log('delete group')
      this.$emit('delete', this.index)
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.v-select {
  min-width: 150px;
}
</style>
