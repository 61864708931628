import { render, staticRenderFns } from "./CbsReferencePickerSid.vue?vue&type=template&id=260e58f8&scoped=true"
import script from "./CbsReferencePickerSid.vue?vue&type=script&lang=js"
export * from "./CbsReferencePickerSid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260e58f8",
  null
  
)

export default component.exports