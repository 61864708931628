<template>
  <div>
    <b-row>
      <b-col>
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-form-checkbox v-model="sort.isActive"
                             name="check-button"
                             switch
                             inline
                             class="custom-control-success"
            />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <!--<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="toggleOper()">
              {{ sort.oper.name }}
            </b-button>-->
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :text="sort.oper.name"
              variant="primary"
            >
              <b-dropdown-item v-for="(oper, operidx) in opers"
                               :key="'oper' + operidx"
                               @click="selectOper(oper.sid)"
              >
                {{ oper.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <v-select
              v-model="sort.entity"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="fields"
              size="sm"
              placeholder="Select Entity"
            />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <span v-if="!isEdit" @dblclick="isEdit = !isEdit" class="font-weight-bold" style="font-size: 1.1rem;">
              &nbsp;&nbsp;&nbsp; {{ sort.name }} &nbsp;&nbsp;&nbsp;
            </span>
            <b-form-input v-if="isEdit" v-model="sort.name" placeholder="Enter Name" size="sm"
                          @dblclick="isEdit = !isEdit" />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" @click="onDelete">
              <feather-icon icon="TrashIcon" />
              Delete
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CbsOrderbyItem',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    vSelect,
    BFormCheckbox,
  },
  props: {
    sort: {
      type: Object,
      default: () => ({
        isActive: false,
        name: 'new sort',
        oper: { sid: 'asc', name: 'Asc' },
        entity: { id: null, name: null },
      }),
    },
    index: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    opers: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      isEdit: false,
    }
  },
  methods: {
    selectOper(sid) {
      this.sort.oper = this.opers.find(o => o.sid === sid)
    },
    onDelete() {
      console.log('delete sort')
      this.$emit('delete', this.index)
    },
    toggleOper() {
      if (this.sort.oper.sid === 'asc') {
        this.selectOper('desc')
      } else {
        this.selectOper('asc')
      }
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.v-select {
  min-width: 150px;
}
</style>
