<template>
  <div>
    <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button size="sm" variant="flat-primary" @click="isExpanded = !isExpanded">
          <feather-icon :icon="expandIcon()" />
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-form-checkbox
            v-model="groupby.isActive"
            name="check-button"
            switch
            inline
            class="custom-control-success"
            @click="switchActive"
        />
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="addGroup()">
          <feather-icon icon="PlusIcon" />
          Group
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <cbs-collapse :trigger="isExpanded">
      <div>
        <b-row class="mt-1 mb-1">
          <b-col cols="1"><label>Active:</label></b-col>
          <b-col cols="3"><label>Operand:</label></b-col>
          <b-col cols="3"><label>Entity:</label></b-col>
          <b-col cols="3"><label>Result:</label></b-col>
          <b-col v-if="false" cols="2"><Label>Name:</Label></b-col>
          <b-col cols="2"><label>Delete:</label></b-col>
        </b-row>
        <cbs-groupby-item v-for="(group, index) in groupby.groups"
                          :key="index"
                          :group="group"
                          :index="index"
                          :fields="fields"
                          :result-fields="resultFields"
                          :opers="opers"
                          @delete="onGroupDelete(index)"
        />
      </div>
    </cbs-collapse>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import CbsGroupbyItem from '@/cubus/components/groupby/CbsGroupbyItem.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsGroupby',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
    CbsCollapse,
    CbsGroupbyItem,
    BButtonGroup,
    BFormCheckbox,
    BButton,
    BButtonToolbar,
    BRow,
    BCol,
  },
  props: {
    groupby: {
      type: Object,
      default: () => ({ isActive: false, groups: [] }),
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
    resultFields: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isExpanded: true,
      opers: [
        { sid: 'group', name: 'Group' },
        { sid: 'sum', name: 'Sum' },
      ],
    }
  },
  created() {
    this.init()
  },
  methods: {
    expandIcon() {
      if (this.isExpanded) {
        return 'ChevronDownIcon'
      }
      return 'ChevronRightIcon'
    },
    addGroup() {
      this.groupby.groups.push(this.getDefaultGroup())
    },
    getDefaultGroup() {
      return {
        isActive: false,
        name: 'group',
        oper: { sid: 'group', name: 'Group' },
      }
    },
    onGroupDelete(index) {
      this.groupby.groups.splice(index, 1)
    },
    init() {
      this.loadOpers()
    },
    loadOpers() {
      useJwt.query({
        query: {
          method: 'groupbyoperands',
          param: {},
        },
      })
        .then(response => {
          // console.log('groupbyoperands response', response)
          if (response.data.thread) {
            this.delayOpers(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('groupbyoperands error', error)
          useCubus.toastError(this.error)
        })
    },
    delayOpers(thread) {
      setTimeout(() => this.threadOpers(thread), 1000)
    },
    threadOpers(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.opers = response.data.thread.result.opers
          } else if (response.data.thread.error) {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayOpers(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    switchActive() {
      console.log('switchActive', this.groupby.isActive)
    },
  },
}
</script>

<style scoped>

</style>
