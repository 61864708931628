<template>
  <div>
    <cbs-record-form-old
      v-if="false && record"
      ref="recordForm"
      :fields="fields"
      :prop-record="record"
      no-debug
    />

    <cbs-record-form :object="objectFull" :fields="fields" :record="record" :compare="srcRecord" />

    <cbs-data-new-record hide :object-full="objectFull" @load="onLoadNewRecord" />

    <!-- buttons -->
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="onCancel"
        >
          <feather-icon icon="XIcon" />
          {{t('Cancel')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :disabled="status === 'processing'"
          @click="onRun"
        >
          <feather-icon icon="PlayIcon" />
          {{t('Run')}}
        </b-button>
      </b-button-group>
      <b-button-group v-if="isAdmin()" size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="isDebug ? 'primary' : 'outline-primary'"
          @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" /> Debug
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <!-- /buttons -->

    <!-- thread log -->
    <cbs-thread :thread="thread" />
    <!--<div v-if="thread && thread.currenttask" style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
      <p><b>Current Task:</b></p>
      <p>
        {{ thread.currenttask }}
      </p>
    </div>
    <div v-if="thread && thread.html"
         style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
        <p><b>Result:</b></p>
        <p v-html="thread.html"></p>
    </div>
    <div v-if="thread.info || thread.log.length > 0"
         style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
      <div v-if="thread.info">
        <p><b>Thread Info:</b></p>
        <p>
          {{ thread.info }}
        </p>
      </div>
      <div v-if="thread.log.length > 0">
        <p><b>Thread Log:</b></p>
        <p v-for="(logLine, logIdx) in thread.log" :key="logIdx">
          {{ logLine }}
        </p>
      </div>
    </div>-->
    <!-- /thread log -->

    <!-- modal -->
    <div>
      <b-button v-show="false" ref="modalProcedureComplete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-procedure
                variant="outline-primary"
      >
        Message Form
      </b-button>
      <b-modal
        id="modal-procedure"
        ok-only
        ok-title="OK"
        centered
        title="Message"
        @ok="onModalClick"
      >
        Procedure completed.
      </b-modal>
    </div>
    <cbs-modal :ref="'procedure_modal_' + uuid" message="Procedure completed." />
    <!-- /modal -->

    <cbs-debug v-if="isDebug" :context="this" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import CbsRecordFormOld from '@/cubus/components/object/CbsRecordForm_old.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataNewRecord from '@/cubus/components/query/CbsDataNewRecord.vue'
import CbsRecordForm from '@/cubus/components/record/CbsRecordForm.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsThread from '@/cubus/components/thread/CbsThread.vue'
import CbsModal from '@/cubus/components/modal/CbsModal.vue'

export default {
  name: 'CbsObjectProcedure',
  components: {
    CbsRecordForm,
    CbsDataNewRecord,
    CbsDebug,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    CbsRecordFormOld,
    CbsThread,
    CbsModal,
  },
  directives: {
    Ripple,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [
    'close',
  ],
  data() {
    return {
      isDebug: false,
      fields: [],
      record: null,
      srcRecord: null,
      threadLog: [],
      thread: { info: null, log: [] },
      status: 'init',
      uuid: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      // this.initRecord()
    },
    onCancel() {
      this.$emit('close')
    },
    onRun() {
      this.status = 'processing'
      this.threadLog = []
      // this.record = this.$refs.recordForm.getRecord()
      useJwt.query({
        query: {
          method: 'runprocedure',
          param: {
            objectid: this.objectFull.object.id,
            record: this.record,
          },
        },
      })
        .then(response => {
          console.log('runprocedure response', response)
          if (response.data) {
            if (response.data.thread) {
              this.delayProc(response.data.thread)
            } else if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread.error) {
              useCubus.toastError(this, response.data.thread.error)
            }
          }
        })
        .catch(error => {
          console.log('runprocedure error', error)
        })
    },
    delayProc(thread) {
      setTimeout(() => this.threadProc(thread), 1000)
    },
    threadProc(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              this.thread = response.data.thread
              if (response.data.thread.status === 'error' || response.data.thread.error) {
                this.status = 'error'
                useCubus.toastError(this, response.data.thread.error)
              } else if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('procedure result', response.data.thread.result)
                this.status = 'done'
                // this.$refs.modalProcedureComplete.click()
                setTimeout(() => this.showModal(), 500)
              } else {
                this.delayProc(thread)
              }
            }
          } else this.delayProc(thread)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
    initRecord() {
      useJwt.query({
        query: {
          method: 'newrecord',
          param: {
            objectid: this.objectFull.object.id,
          },
        },
      })
        .then(response => {
          // console.log('newrecord response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
            if (response.data.thread) {
              this.threadNewRecord(response.data.thread)
            }
          }
        })
        .catch(error => {
          console.log('newrecord error', error)
          useCubus.toastError(this, error)
        })
    },
    threadNewRecord(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('new record result', response.data.thread.result)
                this.fields = response.data.thread.result.record.columns
                // this.$set(this, 'record', response.data.thread.result.record)
                this.record = response.data.thread.result.record.record
              } else if (response.data.thread.error) {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayNewRecord(threadName)
              }
            }
          } else this.delayNewRecord(threadName)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayNewRecord(thread) {
      setTimeout(() => this.threadNewRecord(thread), 1000)
    },
    onModalClick() {},
    onLoadNewRecord(rec) {
      this.fields = rec.columns
      this.record = rec.record
      this.srcRecord = JSON.parse(JSON.stringify(rec.record))
    },
    showModal() {
      this.$nextTick(() => {
        this.$refs[`procedure_modal_${this.uuid}`].show()
      })
    },
  },
}
</script>

<style scoped>

</style>
