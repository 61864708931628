<template>
  <div>
    <cbs-object
      v-if="objectFull"
      :objectid="objectid"
      :object-full="objectFull"
      @close="onClose"
    />
    <cbs-data-object
      hide
      :objectid="objectid"
      @load="onObjectLoad"
    />
  </div>
</template>

<script>
import CbsObject from '@/cubus/components/object/CbsObject.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'

export default {
  name: 'CbsCtrlObject',
  components: {
    CbsObject,
    CbsDataObject,
  },
  props: {
    objectid: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'close',
  ],
  data() {
    return {
      objectFull: null,
    }
  },
  created() {},
  methods: {
    onObjectLoad(obj) {
      this.objectFull = obj
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
