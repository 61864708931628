<template>
  <div>
    <cbs-record-form v-if="record"
                     :ref="'recordForm' + uuid"
                     :object="objectFull"
                     :fields="fields"
                     :record="record"
                     :compare="defaultRecord"
    />

    <!-- buttons -->
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            @click="onCancel"
        >
          <feather-icon icon="XIcon" />
          {{t('Cancel')}}
        </b-button>
      </b-button-group>
      <b-button-group v-if="record" size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :disabled="!isRecordValid() || status === 'processing'"
          @click="onRun"
        >
          <feather-icon icon="PlayIcon" />
          {{t('Generate')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :href="reportUrl"
          :download="filename"
          variant="success"
          :disabled="!reportUrl"
        >
          <feather-icon icon="DownloadIcon" />
          {{t('Download')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="showLog ? 'primary' : 'outline-primary'"
          @click="showLog = !showLog"
        >
          <feather-icon icon="EyeIcon" />
          Log
        </b-button>
        <b-button v-if="isAdmin()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="isConfig ? 'primary' : 'outline-primary'"
                  @click="isConfig = !isConfig"
        >
          <feather-icon icon="SettingsIcon" />
          Config
        </b-button>
        <b-button v-if="isAdmin()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="isDebug ? 'primary' : 'outline-primary'"
                  @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" />
          {{ $options.name }}
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <!-- /buttons -->

    <cbs-collapse :trigger="isConfig" style="border: solid #cccccc 1px; border-radius: 5px; margin-top: 20px; padding: 10px;">
      <cbs-report-config :object="objectFull" />
    </cbs-collapse>

    <!-- thread log -->
    <div v-if="showLog && threadLog.length > 0" style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
      <p><b>Thread Log:</b></p>
      <p v-for="(logLine, logIdx) in threadLog" :key="logIdx">
        {{ logLine }}
      </p>
    </div>
    <!-- /thread log -->

    <!-- modal -->
    <div>
      <b-button v-show="false"
                :ref="`modalReportComplete_${this.uuid}`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal="'modalRemove_' + this.uuid"
                variant="outline-primary"
      >
        Message Form
      </b-button>
      <b-modal
        :id="'modalRemove_' + this.uuid"
        ok-only
        ok-title="OK"
        centered
        title="Message"
        @ok="onModalClick"
      >
        Report done. Now you can download it.
      </b-modal>
    </div>
    <!-- /modal -->

    <cbs-data-new-record :object-full="objectFull" hide @load="onLoadNewRecord" />

    <div v-if="isDebug" style="margin-top: 1rem;">
      <cbs-debug :context="this" />
    </div>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsRecordForm from '@/cubus/components/record/CbsRecordForm.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataNewRecord from '@/cubus/components/query/CbsDataNewRecord.vue'
import CbsReportConfig from '@/cubus/components/object-report/CbsReportConfig.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  name: 'CbsObjectReport',
  components: {
    CbsCollapse,
    CbsReportConfig,
    CbsDataNewRecord,
    CbsRecordForm,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    CbsDebug,
  },
  directives: {
    Ripple,
  },
  props: {
    object: {
      type: Object,
      default: () => ({}),
    },
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [
    'cancel',
  ],
  data() {
    return {
      isDebug: false,
      fields: [],
      record: null,
      defaultRecord: null,
      files: [],
      threadLog: [],
      downloadtoken: null,
      reportUrl: null,
      filename: null,
      uuid: null,
      isConfig: false,
      status: 'init',
      showLog: false,
    }
  },
  created() {
    this.init()
  },
  computed: {},
  methods: {
    init() {
      this.uuid = useCubus.guid()
      // this.initRecord()
    },
    initRecord() {
      useJwt.query({
        query: {
          method: 'newrecord',
          param: {
            objectid: this.objectFull.object.id,
          },
        },
      })
        .then(response => {
          // console.log('newrecord response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
            if (response.data.thread) {
              this.threadNewRecord(response.data.thread)
            }
          }
        })
        .catch(error => {
          console.log('newrecord error', error)
          useCubus.toastError(this, error)
        })
    },
    threadNewRecord(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('new record result', response.data.thread.result)
                this.fields = response.data.thread.result.record.columns
                this.record = response.data.thread.result.record.record
                /*
                this.$nextTick(() => {
                  console.log(this.$refs)
                  console.log(this.$refs.recordForm)
                })
                */
              } else {
                this.delayNewRecord(threadName)
              }
            }
          } else this.delayNewRecord(threadName)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayNewRecord(thread) { setTimeout(() => this.threadNewRecord(thread), 500) },
    showFiles() {
      console.log('files', this.files)
    },
    onCancel() {
      this.$emit('close')
    },
    clearFiles() {
      this.files = []
    },
    isSaveable() {
      return this.recordStatus() !== 'init' && this.recordState()
    },
    recordState() {
      return this.fields.some(fld => !this.fldState(fld).state)
    },
    recordStatus() {
      if (this.fields.some(fld => this.cellStatus(fld) === 'changed')) {
        return 'changed'
      }
      return 'init'
    },
    isAdmin() { return useCubus.isAdmin() },
    onRun() {
      this.status = 'processing'
      this.threadLog = []
      this.downloadtoken = null
      // this.record = this.$refs.recordForm.getRecord()
      useJwt.query({
        query: {
          method: 'doreport',
          param: {
            objectid: this.objectFull.object.id,
            record: this.record,
          },
        },
      })
        .then(response => {
          console.log('report response', response)
          if (response.data.thread) {
            this.delayReport(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'No thread provided.')
          }
        })
        .catch(errror => {
          console.log('report error', errror)
          useCubus.toastError(this, errror)
        })
    },
    delayReport(thread) { setTimeout(() => this.threadReport(thread), 1000) },
    threadReport(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('thread upload response', response)
          if (response.data && response.data.thread) {
            if (response.data.thread.log) {
              this.threadLog = response.data.thread.log
            }
            if (response.data.thread.status === 'error' && response.data.thread.error) {
              useCubus.toastError(this, response.data.thread.error)
            } else if (response.data.thread.status === 'done') {
              this.downloadtoken = response.data.thread.result.downloadtoken
              this.processDownload()
              // this.processDownloadAxios()
              this.$refs[`modalReportComplete_${this.uuid}`].click()
              this.status = 'done'
            } else {
              this.delayReport(thread)
            }
          } else {
            useCubus.toastError(this, 'Thread not found.')
            this.status = 'error'
          }
        })
        .catch(error => {
          console.log('thread report error', error)
          useCubus.toastError(this, error)
        })
    },
    onModalClick() {},
    onChange(isValid) {
      this.isRecordValid = isValid
    },
    processDownload() {
      console.log(this.downloadtoken)
      useJwt.download({
        param: {
          downloadtoken: this.downloadtoken,
        },
      })
        .then(response => {
          console.log('download response', response)
          const blob = new Blob([response.data], { type: 'application/ms-excel' })
          this.reportUrl = window.URL.createObjectURL(blob)
          console.log('url', this.reportUrl)
          this.filename = decodeURIComponent(response.headers.filename.replace(/\+/g, ' '))
        })
        .catch(error => {
          console.log('download error', error)
          useCubus.toastError(this, error)
        })
    },
    onLoadNewRecord(rec) {
      this.fields = rec.columns
      this.record = rec.record
      this.defaultRecord = JSON.parse(JSON.stringify(rec.record))
    },
    isRecordValid() {
      return this.fields.find(fld => !this.isFieldValid(fld)) === undefined
    },
    isFieldValid(fld) {
      return !(fld.isrequired && this.isCellEmpty(fld))
    },
    isCellEmpty(fld) {
      const cell = this.record[fld.key]
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
  },
}
</script>

<style scoped>

</style>
