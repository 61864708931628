<template>
  <div style="width: 100%; height: 100%; text-align: center;">
    <div v-if="!isEdit" @click="editOn">
      {{ record ? formatted(record[keyValue].value) : '\u00a0' }}
    </div>
    <div v-show="isEdit" :id="`edit_${uuid}`" contenteditable="true" @blur="onBlur" />
    <cbs-debug v-if="false && isRoot" :context="this" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'KbvBisTableCell',
  components: {
    CbsDebug,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    keyValue: {
      type: String,
      default: null,
    },
    indicator: {
      type: Object,
      default: null,
    },
    valueType: {
      type: String,
      default: 'string',
    },
  },
  emits: [
    'edit',
  ],
  setup() {
    const isRoot = useCubus.isRoot()
    return {
      isRoot,
    }
  },
  data() {
    return {
      uuid: useCubus.guid(),
      isEdit: false,
    }
  },
  methods: {
    onBlur(event) {
      if (this.isEdit) {
        this.isEdit = false
        const newValue = event.target.innerText === '\n' ? null : Number(JSON.parse(JSON.stringify(event.target.innerText)))
        // console.log(`event.target.innerText = '${event.target.innerText}'`)
        // console.log('newValue', newValue)
        if ((this.record && this.record[this.keyValue].value !== newValue) || (!this.record && newValue)) {
          this.$emit('edit', newValue)
        }
      }
    },
    editOn() {
      if (this.indicator.access === 'rw') {
        this.isEdit = true
        this.$nextTick(() => {
          const el = document.getElementById(`edit_${this.uuid}`)
          el.innerText = this.record ? this.record[this.keyValue].value : '\u00a0'
          el.focus()
        })
      }
    },
    formatted(value) {
      if (this.valueType === 'date') return this.toDate(value)
      if (this.valueType === 'number') return value ? Number(value).toLocaleString() : '\u00a0'
      return value
    },
  },
}
</script>

<style scoped>

</style>
