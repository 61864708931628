<template>
  <div>
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsDataRecord',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
    rowsid: {
      type: String,
      default: null,
    },
    rowid: {
      type: Number,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      record: null,
      isDebug: false,
    }
  },
  watch: {
    objectFull() {
      this.init()
    },
    rowsid() {
      this.init()
    },
    rowid() {
      this.init()
    },
  },
  emits: [
    'load',
  ],
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadRecord()
    },
    loadRecord() {
      useJwt.query({
        query: {
          method: 'record',
          param: {
            objectid: this.objectFull.object.id,
            rowsid: this.rowsid ? this.rowsid : '',
            rowid: this.rowid ? this.rowid : 0,
          },
        },
      })
        .then(response => {
          console.log('record response', response)
          if (response.data.thread) {
            this.threadRecord(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('record error', error)
          useCubus.toastError(this, error)
        })
    },
    threadRecord(thread) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: thread,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.thread.status === 'done') {
            this.record = response.data.thread.result.record
            this.$emit('load', this.record)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayRecord(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayRecord(thread) { setTimeout(() => this.threadRecord(thread), 500) },
  },
}
</script>

<style scoped>

</style>
