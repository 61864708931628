export function selectAllText() {
  setTimeout(() => {
    const el = document.activeElement
    if (!el || !el.isContentEditable) return

    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(el)
    selection.removeAllRanges()
    selection.addRange(range)
  }, 100)
}

export default function navigateToNextEditableCell(event) {
  // accept: Enter, Tab
  if (event.key !== 'Enter' && event.key !== 'Tab') return false
  const shiftTab = event.shiftKey && event.key === 'Tab'

  event.preventDefault()
  console.log(event)
  const el = event.target
  console.log('navigateToNextEditableCell')
  if (!el) return false

  let td = el.closest('td')
  let tr = td.closest('tr')

  while (td) {
    if (shiftTab) {
      td = td.previousElementSibling
    } else {
      td = td.nextElementSibling
    }

    if (td) {
      const nextEl = td.querySelector('.activator')
      if (nextEl) {
        nextEl.click()
        selectAllText()
        return true
      }
    }
  }

  tr = tr.nextElementSibling
  if (tr) {
    td = tr.querySelector('td')
    if (td) {
      const nextEl = td.querySelector('.activator')
      if (nextEl) {
        nextEl.click()
        selectAllText()
        return true
      }
    }
  }
  return false
}
