<template>
  <div style="width:100%; height:100%;" @dblclick="changeMode()">
    <div v-if="cellViewMode() === 'view'">
      <span v-if="field.datatype === 'refsid'"
            :title="cell.title"
      >
        {{ (cell.title && cell.title !== '') ? cell.title : cell.value }}
      </span>
      <span v-else-if="cell.title"
            :title="cell.title + ' (' + cell.value + ')'"
      >
        {{ cell.title }}
        <span style="font-size: 0.8em;">({{ cell.value }})</span>
      </span>
      <span v-else-if="typeof cell.value == 'boolean'">
        <feather-icon v-if="cell.value === true" icon="CheckSquareIcon" />
        <feather-icon v-else icon="SquareIcon" />
      </span>
      <div v-else-if="field.datatype === 'double'" style="text-align: right;">
        <span v-if="field.rendertype === 'percent'">{{ formatPercent(cell.value) }}</span>
        <span v-else>{{ formatDouble(cell.value) }}</span>
      </div>
      <div v-else-if="field.datatype === 'int'" style="text-align: right;">
        {{ formatInteger(cell.value) }}
      </div>
      <span v-else :title="valueTitle(cell.value)">
        {{ valueTitle(cell.value) }}
      </span>
    </div>
    <div v-else class="align-top">
      <div v-if="field.datatype === 'id'">
        {{cell.value}}
      </div>
      <b-form-checkbox v-else-if="field.datatype === 'bit'" v-model="cell.value" @change="onBlur" />
      <b-form-input v-else-if="field.datatype === 'string'" v-model="cell.value" placeholder="Input string" @blur="onBlur(cell)" />
      <b-form-textarea v-else-if="field.datatype === 'text'" v-model="cell.value" placeholder="Input text2" rows="1" @blur="onBlur(cell)" />
      <div v-else-if="field.datatype === 'ref'">
        <cbs-reference-picker :record="record" :field="field" @updateCell="onUpdateRefpicker" />
<!--        <cbs-reference-picker3 :record="record"
                               :field="field"
                               @updateCell="onUpdateRefpicker"
        />-->
      </div>
      <div v-else>
        {{cell.value}}
      </div>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BFormCheckbox, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'

export default {
  name: 'CbsSheetCell',
  components: {
    CbsReferencePicker3,
    CbsDebug,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    CbsReferencePicker,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    cell: {
      type: Object,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    objectFull: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateCell'],
  data() {
    return {
      changed: false,
      mode: 'view', // view or edit
    }
  },
  watch: {
    cell: {
      handler(newValue, oldValue) {
        this.changed = true
      },
      deep: true,
    },
  },
  methods: {
    onBlur(event) {
      console.log('onBlur input', event, this.cell)
      if (this.changed) {
        this.$emit('updateCell', this.field, this.record)
        this.changed = false
      }
    },
    onUpdateRefpicker(cell) {
      console.log('onUpdateRefpicker', cell)
      this.$emit('updateCell', this.field, this.record)
    },
    formatDouble(value) {
      return useCubus.formatNumber(value, null)
    },
    formatPercent(value) {
      return useCubus.formatPercent(value)
    },
    formatInteger(value) {
      return useCubus.formatNumber(value, { precision: 0 })
    },
    valueTitle(val) {
      // console.log('val', val)
      return val ? val.toString().slice(0, 100) : ''
    },
    changeMode() {
      console.log('changeMode', this.record.viewmode)
      if (!this.record.viewmode) {
        this.$set(this.record, 'viewmode', 'edit')
      } else {
        this.record.viewmode = (this.record.viewmode === 'view' ? 'edit' : 'view')
      }
    },
    cellViewMode() {
      return !this.record.viewmode ? 'view' : this.record.viewmode
    },
  },
}
</script>

<style scoped>

</style>
