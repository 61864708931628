<template>
  <div>
    <div v-show="!hide">
      <cbs-debug :context="this" />
    </div>
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import Ripple from 'vue-ripple-directive'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsDataObject',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
  },
  props: {
    objectid: {
      type: Number,
      default: null,
    },
    objectsid: {
      type: String,
      default: null,
    },
    domainsid: {
      type: String,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    entityid: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'load',
  ],
  data() {
    return {
      fullObject: null,
      isDebug: false,
    }
  },
  created() {
    this.init()
  },
  watch: {
    objectid() {
      this.loadObject()
    },
    objectsid() {
      this.loadObject()
    },
  },
  methods: {
    init() {
      this.loadObject()
    },
    loadObject() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'objectfull',
          param: {
            objectid: this.objectid,
            objectsid: this.objectsid,
            domainsid: this.domainsid,
            entityid: this.entityid,
          },
        },
      })
        .then(response => {
          if (response.data.thread) {
            this.threadObject(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    threadObject(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: {
            threadname: thread,
          },
        },
      }).then(response => {
        // console.log('response', response)
        if (response.data.thread.status === 'done') {
          this.fullObject = response.data.thread.result.objectfull
          this.$emit('load', this.fullObject)
        } else if (response.data.thread.status === 'error') {
          useCubus.toastError(this, response.data.thread.error)
        } else {
          this.delayObject(thread)
        }
      }).catch(error => {
        console.log('error', error)
        useCubus.toastError(this, error)
      })
    },
    delayObject(thread) {
      setTimeout(() => this.threadObject(thread), 500)
    },
  },
}
</script>

<style scoped>

</style>
