<template>
  <div>
    <div v-if="ready" style="overflow: scroll; max-height: 75vh;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th :id="`firstcol_${uuid}`" style="position: sticky; left: 0; top: 0; z-index: 4">Код</th>
            <th style="position: sticky; top: 0; z-index: 4" :style="'left: ' + stick() + 'px;'">Показатель</th>
            <th v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" style="position: sticky; top: 0; z-index: 3;">{{ role.name.value }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ind in qryIndicator.recordset.records" :key="'ind_' + ind.id.value">
            <td style="position: sticky; left: 0; z-index: 1; padding: 0.5rem 1rem 0.5rem 1rem;" class="table-light">{{ ind.code.value }}</td>
            <td style="position: sticky; z-index: 1; padding: 0.5rem 1rem 0.5rem 1rem;" :style="'left: ' + stick() + 'px;'" class="table-light">{{ ind.name.value }}</td>
            <td v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" style="padding: 0.5rem 1rem 0.5rem 1rem;">
              <cbs-ind-access-cell :record="getRecord(ind, role)" :key-access="keyAccess"
                                   :ro-value="roValue" :rw-value="rwValue"
                                   @update="onUpdateCell(ind, role, $event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsIndAccessCell from '@/cubus/components/cbs-form/custom-forms/indicator-access/CbsIndAccessCell.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsIndicatorAccess',
  components: {
    CbsIndAccessCell,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uuid: null,
      objAccess: null,
      objRole: null,
      objIndicator: null,
      objAccessType: null,
      qryAccess: null,
      qryRole: null,
      qryIndicator: null,
      qryAccessType: null,
      keyIndicator: null,
      keyRole: null,
      keyAccess: null,
      roValue: null,
      rwValue: null,
      firstCol: null,
      ready: false,
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.loadObjAccess()
    },
    async loadObjAccess() {
      this.objAccess = await useCubus.loadObject(this, { objectsid: 'budgetindicatoraccess' })
      this.objRole = await useCubus.loadObject(this, { objectsid: 'role' })
      this.objIndicator = await useCubus.loadObject(this, { objectsid: 'budgetindicator' })
      this.objAccessType = await useCubus.loadObject(this, { objectsid: 'accesstype' })
      this.loadQryAccess()
    },
    async loadQryAccess() {
      this.qryAccess = await useCubus.loadQuery(this, { objectsid: 'budgetindicatoraccess', pageSize: 100000 })
      this.keyRole = this.qryAccess.columns.find(c => c.entitysid === 'role').key
      this.keyIndicator = this.qryAccess.columns.find(c => c.entitysid === 'budget_indicator').key
      this.keyAccess = this.qryAccess.columns.find(c => c.entitysid === 'indicatoraccess').key
      const qryRoleFilter = {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'notequal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: 'sid',
                },
                {
                  type: { sid: 'value' },
                  value: 'admin',
                },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'notequal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: 'sid',
                },
                {
                  type: { sid: 'value' },
                  value: 'dev',
                },
              ],
            },
          ],
        },
      }
      this.qryRole = await useCubus.loadQuery(this, { objectsid: 'role', filter: qryRoleFilter, pageSize: 100000 })
      const paramQryIndicator = {
        objectsid: 'budgetindicator',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: 0 },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      }
      this.qryIndicator = await useCubus.loadQuery(this, paramQryIndicator)
      this.loadQryAccessType()
    },
    async loadQryAccessType() {
      this.qryAccessType = await useCubus.loadQuery(this, { objectsid: 'accesstype', pageSize: 100000 })
      this.ready = true
      this.roValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'ro').id.value
      this.rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
      this.$nextTick(() => {
        this.firstCol = document.getElementById(`firstcol_${this.uuid}`)
      })
    },
    stick() {
      const c = document.getElementById(`firstcol_${this.uuid}`)
      return c ? c.offsetWidth : 0
    },
    getRecord(ind, role) {
      if (this.qryAccess) {
        const rec = this.qryAccess.recordset.records.find(r => r[this.keyIndicator].value === ind.id.value && r[this.keyRole].value === role.id.value)
        if (rec) return rec
        const newrec = this.getNewRecord(ind, role)
        this.qryAccess.recordset.records.push(newrec)
        return newrec
      }
      return null
    },
    getNewRecord(ind, role) {
      const rec = {}
      this.qryAccess.columns.forEach(c => {
        if (c.key !== 'actions') {
          rec[c.key] = { value: null, title: null }
        }
      })
      rec.del.value = false
      rec[this.keyIndicator] = { value: ind.id.value, title: ind.name.value }
      rec[this.keyRole] = { value: role.id.value, title: role.name.value }
      return JSON.parse(JSON.stringify(rec))
    },
    onUpdateCell(ind, role, value) {
      const rec = this.getRecord(ind, role)
      if (value === 'no') {
        rec[this.keyAccess].value = null
        rec[this.keyAccess].title = null
      } else {
        const acc = this.qryAccessType.recordset.records.find(r => r.sid.value === value)
        rec[this.keyAccess].value = acc.id.value
        rec[this.keyAccess].title = acc.name.value
      }
      rec[this.keyAccess].status = 'changed'
      this.saveRecord(rec, 'indicatorAccess')
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objAccess.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 200)
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      this.qryAccess.columns.forEach(fld => {
        initRec[fld.key] = savedRec[fld.key]
      })
      initRec.status = 'saved'
    },
  },
}
</script>

<style scoped>

</style>
