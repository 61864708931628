<template>
  <div>
    <b-row>
      <b-col v-if="allowedChildren" cols="1" style="padding-right: 0.2rem; padding-left: 0; padding-bottom: 1rem;">
        <div style="cursor: pointer; text-align: center; height: 100%;" @click="expand()">
          <feather-icon style="margin-top: 0.8rem;" :icon="item.expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="16" />
        </div>
      </b-col>
      <b-col :cols="allowedChildren ? 3 : 4" style="padding-left: 0.2rem; padding-right: 0.2rem;">
        <b-form-input v-model="item.title" />
      </b-col>
      <b-col cols="3" style="padding-left: 0.2rem; padding-right: 0.2rem;">
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon :key="icon" :icon="icon || 'CircleIcon'" />
            </b-input-group-prepend>
            <b-form-input v-model="item.icon" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4" style="padding-left: 0.2rem; padding-right: 0;">
        <b-form-group v-if="false">
          <b-form-input size="sm" v-model="item.route" />
        </b-form-group>
        <cbs-reference-picker :record="item" :field="fieldObject()" :prop-ref="refObject" no-refresh no-link />
      </b-col>
      <b-col cols="1" style="padding-left: 0.5rem; padding-right: 0.5rem;">
        <b-button variant="flat-danger" block @click="removeItem(item)">
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <cbs-collapse :trigger="item.expanded">
          <div class="children">
            <draggable v-model="item.children" tag="ul" handle=".handle">
              <b-list-group-item v-for="(subItem, idx) in item.children" :key="idx" tag="div" class="list">
                <div>
                  <b-row>
                    <b-col cols="1" style="margin-bottom: 1rem;">
                      <div class="handle cursor-move handler border-secondary">
                        <feather-icon icon="MoreVerticalIcon" size="16" />
                      </div>
                    </b-col>
                    <b-col cols="11" style="padding-left: 0;">
                      <cbs-user-menu-item :item="subItem"
                                          :level="level + 1"
                                          :ent-object="entObject"
                                          :ref-object="refObject"
                                          @remove-item="removeSubItem(subItem)"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-list-group-item>
            </draggable>
            <b-button variant="outline-primary" class="btn-sm" @click="addSubItem()">
              + Add Child
            </b-button>
          </div>
        </cbs-collapse>
      </b-col>
    </b-row>
    <cbs-debug hidden :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BListGroupItem, BRow,
} from 'bootstrap-vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'
import draggable from 'vuedraggable'

export default {
  name: 'CbsUserMenuItem',
  components: {
    CbsReferencePicker,
    CbsCollapse,
    CbsDebug,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
    draggable,
    BListGroupItem,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    level: {
      type: Number,
      default: 1,
    },
    entObject: {
      type: Object,
      default: null,
    },
    refObject: {
      type: Array,
      default: null,
    },
  },
  emits: ['remove-item'],
  data() {
    return {
    }
  },
  computed: {
    icon() {
      return this.item ? this.item.icon : 'CircleIcon'
    },
    allowedChildren() {
      return this.level < 3
    },
  },
  methods: {
    removeItem(item) {
      this.$emit('remove-item', item)
    },
    expand() {
      if (!this.item.expanded) {
        this.$set(this.item, 'expanded', true)
      } else {
        this.item.expanded = !this.item.expanded
      }
    },
    removeSubItem(subItem) {
      const idx = this.item.children.indexOf(subItem)
      this.item.children.splice(idx, 1)
    },
    addSubItem() {
      this.item.children.push({
        title: 'New Item',
        icon: 'CircleIcon',
        route: '',
        expanded: false,
        children: [],
      })
    },
    fieldObject() {
      return {
        entitysid: this.entObject.sid,
        entityid: this.entObject.id,
        key: 'object',
      }
    },
  },
}
</script>

<style scoped>
.children {
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  border-left: solid #cccccc 1px;
  border-right: solid #cccccc 1px;
  border-bottom: solid #cccccc 1px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.handler {
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: .3rem;
  max-width: 2rem;
}
.list {
  padding: 0;
  margin: 0;
  border: none;
}
ul {
  padding-left:1rem;
}
</style>
