<template>
  <div>
    <div v-if="qryBudgetScn">
      <cbs-budreq-scenario v-for="scn in qryBudgetScn.recordset.records" :key="'scn_' + scn.id.value"
                           :scenario="scn"
                           :key-scenario="keyScenario"
                           :key-base-year="keyBaseYear"
                           :qry-access="qryAccess"
                           :ro-value="roValue"
                           :rw-value="rwValue"
      />
    </div>

    <cbs-debug v-if="session.user.sid === 'root'" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsBudreqScenario from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqScenario.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsBudgetRequest',
  components: {
    CbsBudreqScenario,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const session = useCubus.getSession()
    return {
      session,
    }
  },
  data() {
    return {
      objBudgetScn: null,
      qryBudgetScn: null,
      scenario: null,
      keyScenario: null,
      keyBaseYear: null,
      objAccess: null,
      qryAccess: null,
      objAccessType: null,
      qryAccessType: null,
      roValue: null,
      rwValue: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadObjBudgetScn()
    },
    async loadObjBudgetScn() {
      this.objBudgetScn = await useCubus.loadObject(this, { objectsid: 'scenario_budget' })
      if (this.objBudgetScn) {
        this.loadQryBudgetScn()
      }
    },
    async loadQryBudgetScn() {
      const params = {
        objectid: this.objBudgetScn.object.id,
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
        pageSize: 100000,
      }
      this.qryBudgetScn = await useCubus.loadQuery(this, params)
      if (this.qryBudgetScn) {
        this.keyScenario = this.qryBudgetScn.columns.find(col => col.entitysid === 'scenario').key
        this.keyBaseYear = this.qryBudgetScn.columns.find(col => col.entitysid === 'baseyear').key
        this.loadObjAccessType()
      }
    },
    async loadObjAccessType() {
      this.objAccessType = await useCubus.loadObject(this, { objectsid: 'accesstype' })
      if (this.objAccessType) {
        await this.loadQryAccessType()
      }
    },
    async loadQryAccessType() {
      const params = {
        objectid: this.objAccessType.object.id,
        pageSize: 100000,
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
      }
      this.qryAccessType = await useCubus.loadQuery(this, params)
      if (this.qryAccessType) {
        this.roValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'ro').id.value
        this.rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
        this.loadObjAccess()
      }
    },
    async loadObjAccess() {
      this.objAccess = await useCubus.loadObject(this, { objectsid: 'budget_request_access' })
      if (this.objAccess) {
        this.loadQryAccess()
      }
    },
    async loadQryAccess() {
      const params = {
        objectid: this.objAccess.object.id,
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
        pageSize: 100000,
      }
      this.qryAccess = await useCubus.loadQuery(this, params)
    },
  },
}
</script>

<style scoped>

</style>
