<template>
  <div>
    <cbs-object v-if="objectFull"
                :object-full="objectFull"
                :filter="filter"
                :hidden-fields="hiddenFields"
                @close="onClose"
    />
    <cbs-data-object :objectid="objectid" :objectsid="objectsid" hide @load="loadObject" />
  </div>
</template>

<script>
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'

export default {
  name: 'CbsCtrlRelation',
  components: {
    CbsDataObject,
    CbsObject: () => import('@/cubus/components/object/CbsObject.vue'),
  },
  props: {
    objectid: {
      type: Number,
      default: null,
    },
    objectsid: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'close',
  ],
  data() {
    return {
      objectFull: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    loadObject(obj) {
      this.objectFull = obj
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
