<template>
  <div>
    <div v-if="cell.value">
      <b-input-group>
        <b-form-input :id="field.key + 'Label'"
                      v-model="cell.title"
                      placeholder="Normal Input"
                      :state="fieldState().state"
                      @change="onChange()"
        />
        <b-form-invalid-feedback>
          {{ fieldState().status }}
        </b-form-invalid-feedback>
        <b-input-group-append>
          <b-button variant="outline-warning" @click="clearCell()">
            <feather-icon icon="XIcon" /> {{t('Clear')}}
          </b-button>
          <b-button v-if="canDownload && !url" variant="outline-primary" @click="download()">
            <feather-icon icon="LinkIcon" /> {{t('GetLink')}}
          </b-button>
          <b-button v-if="url" variant="outline-success" :href="url" :download="filename">
            <feather-icon icon="DownloadIcon" /> {{t('Download')}}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-form-invalid-feedback>
      {{ fieldState().status }}
    </b-form-invalid-feedback>

    <b-form-file v-if="!cell.value"
                 v-model="cell.file"
                 placeholder="Choose a file or drop it here..."
                 drop-placeholder="Drop file here..."
    />
  </div>
</template>

<script>
import {
  BButton, BFormFile, BFormInput, BFormInvalidFeedback, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  name: 'CbsFileEntity',
  components: {
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    cell: {
      type: Object,
      default: null,
    },
    initcell: {
      type: Object,
      default: null,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    objectid: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [],
  data() {
    return {
      file: null,
      url: null,
      filename: null,
    }
  },
  methods: {
    fieldState() {
      const fld = this.field
      let stat = { state: null, status: '' }
      const isEmpty = this.isCellEmpty()
      if (fld.isrequired) {
        if (isEmpty) {
          stat = { state: false, status: 'Attribute is required.' }
        } else if (this.isCellChanged()) {
          stat = { state: true, status: '' }
        }
      }
      if (fld.isunique && !isEmpty) {
        if (!this.isCellChanged()) {
          stat = { state: null, status: '' }
        } else if (this.cell.isUnique === true) {
          stat = { state: true, status: '' }
        } else if (this.cell.isUnique === false) {
          stat = { state: false, status: 'Value is not unique.' }
        } else {
          stat = { state: false, status: 'Unique check is required.' }
        }
      }
      return stat
    },
    isCellEmpty() {
      const fld = this.field
      const c = this.cell
      return c.value === null || c.value === '' || (fld.datatype === 'ref' && c.value === 0)
    },
    isCellChanged() {
      return this.cellStatus(this.field) === 'changed'
    },
    cellStatus() {
      const c = this.cell
      const ic = this.initcell
      if (c) {
        return (`${c.value}` === `${ic.value}` || (!c.value && !ic.value)) ? 'init' : 'changed'
      }
      return 'init'
    },
    onChange() {},
    clearCell() {
      this.cell.value = null
      this.cell.title = null
    },
    fldState() {
      // console.log('fld', fld)
      let stat = { state: null, status: '' }
      const isEmpty = this.isCellEmpty()
      if (this.field.isrequired) {
        if (isEmpty) {
          stat = { state: false, status: 'Attribute is required.' }
        } else if (this.isCellChanged()) {
          stat = { state: true, status: '' }
        }
      }
      if (this.field.isunique && !isEmpty) {
        if (!this.isCellChanged()) {
          stat = { state: null, status: '' }
        } else if (this.cell.isUnique === true) {
          stat = { state: true, status: '' }
        } else if (this.cell.isUnique === false) {
          stat = { state: false, status: 'Value is not unique.' }
        } else {
          stat = { state: false, status: 'Unique check is required.' }
        }
      }
      return stat
    },
    download() {
      console.log(this.downloadtoken)
      useJwt.download({
        param: {
          method: 'downloadfromrecord',
          objectid: this.objectid,
          fileid: Number(this.cell.value),
        },
      })
        .then(response => {
          console.log('download response', response)
          const blob = new Blob([response.data], { type: 'application/ms-excel' })
          this.url = window.URL.createObjectURL(blob)
          this.filename = decodeURIComponent(response.headers.filename.replace(/\+/g, ' '))
          // this.$refs.btnRunDownload.click()
        })
        .catch(error => {
          console.log('download error', error)
          useCubus.toastError(this, error)
        })
    },
  },
}
</script>

<style scoped>

</style>
