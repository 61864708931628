<template>
  <div>
    <div v-if="subobject.rendertype === 'orderspec'">
      <cbs-order-spec v-if="objectFull" :object-full="objectFull" :filter="filter" :hidden-fields="hiddenFields" />
      <cbs-data-object :objectid="subobject.id" hide @load="loadObject" />
    </div>
    <div v-else-if="subobject.rendertype === 'ordertimeline'">
      <cbs-order-timeline :prop-object="subobject" :prop-record="record"></cbs-order-timeline>
    </div>
    <cbs-ctrl-relation v-else :objectid="subobject.id" :filter="filter" :hidden-fields="hiddenFields" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsCtrlRelation from '@/cubus/components/relation/CbsCtrlRelation.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsOrderSpec from '@/cubus/components/order-spec/CbsOrderSpec.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsOrderTimeline from '@/cubus/components/order-timeline/CbsOrderTimeline.vue'

export default {
  name: 'CbsSubobject',
  components: {
    CbsOrderTimeline,
    CbsDataObject,
    CbsDebug,
    CbsCtrlRelation,
    CbsOrderSpec,
  },
  props: {
    subobject: {
      type: Object,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      objectFull: null,
    }
  },
  methods: {
    loadObject(obj) {
      this.objectFull = obj
    },
  },
}
</script>

<style scoped>

</style>
