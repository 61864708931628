<template>
  <div>
    <label :class="cellFull.state.change === 'changed' ? 'text-warning' : ''">
      {{ cellFull.field.label }}
      <span v-if="cellFull.field.isrequired === true">*</span>
      <span v-if="cellFull.field.isunique === true">!</span>
    </label>
    <small v-if="cellFull.state.change === 'changed'" class="text-warning">
      &nbsp;(changed)
    </small>
  </div>
</template>

<script>
export default {
  name: 'CbsCellLabel',
  props: {
    cellFull: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
