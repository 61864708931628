<template>
  <div style="margin-bottom: 2rem;">
    <div>
      <span style="cursor: pointer;" @click="expanded = !expanded">
        <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />&nbsp;{{ scenario[keyScenario].title }}
      </span>
    </div>
    <cbs-collapse :trigger="expanded">
      <cbs-budreq-table v-if="expanded" :scenario="scenario" :key-scenario="keyScenario" :key-base-year="keyBaseYear"
                        :qry-access="qryAccess"
                        :ro-value="roValue"
                        :rw-value="rwValue"
      />
    </cbs-collapse>
    <cbs-debug v-if="session.user.sid === 'root'" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsBudreqTable from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqTable.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsBudreqScenario',
  components: {
    CbsDebug,
    CbsCollapse,
    CbsBudreqTable,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
    keyBaseYear: {
      type: String,
      default: null,
    },
    qryAccess: {
      type: Object,
      default: null,
    },
    roValue: {
      type: Number,
      default: null,
    },
    rwValue: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const session = useCubus.getSession()
    return {
      session,
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style scoped>

</style>
