<template>
  <div>
    <div>
      <b-button-toolbar style="align-items: center;">
        <b-button-group style="margin-right:0rem;">
          <b-form-checkbox switch class="custom-control-success" v-model="modification.isactive" />
        </b-button-group>
        <b-button-group>
          <b-button size="sm" variant="outline-danger" @click="onDelete">
            <feather-icon icon="TrashIcon" /> Delete
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <div style="margin-left:3.5rem;margin-top:0.5rem;">
      <b-row>
        <b-col cols="3">
          <small style="margin-left:0.5rem;">Entity:</small>
          <v-select v-model="modification.destentity"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="fields"
                    :filter-by="filterBy"
                    @input="onChangeEntity"
          />
        </b-col>
        <b-col cols="3">
          <small style="margin-left:0.5rem;">Operand:</small>
          <v-select v-model="modification.oper"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="opers"
                    :filter-by="filterBy"
                    @input="onChange"
          />
        </b-col>
        <b-col cols="6">
          <small style="margin-left:0.5rem;">Value:</small>
          <cbs-view-input v-if="modification.destentity && modification.oper.sid === 'value'"
                          :field="modification.destentity"
                          :value="modification.value"
          />
          <v-select v-else-if="modification.destentity && modification.oper.sid === 'entity' && fields"
                    v-model="modification.value.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="fields"
                    :filter-by="filterBy"
          />
          <b-form-textarea v-else-if="modification.destentity && modification.oper.sid === 'sql'"
                           v-model="modification.value.value.value"
                           placeholder="Textarea"
                           rows="3"
          />
          <div v-else-if="modification.destentity && modification.oper.sid === 'scalar'">
            <v-select v-if="scalars"
                      v-model="modification.scalar"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="scalars"
                      :filter-by="filterBy"
            />
            <cbs-data-reference hide entitysid="scalar" @load="onLoadScalars" />
          </div>
          <div v-else-if="modification.destentity && modification.oper.sid === 'formula'">
            <v-select v-if="formulas"
                      v-model="modification.formula"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="formulas"
                      :filter-by="filterBy"
            />
            <cbs-data-reference hide entitysid="formula" @load="onLoadFormulas" />
          </div>
          <div v-else-if="modification.oper.sid === 'sourceentity'">
            <v-select v-if="refEntities"
                      v-model="modification.entity"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="refEntities"
                      :filter-by="filterBy"
            />
            <cbs-data-reference hide entitysid="entity" @load="onLoadEntities" />
          </div>
          <div v-else-if="modification.oper.sid === 'function'">
            <v-select v-if="refEntities"
                      v-model="modification.function"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="refEntities"
                      :filter-by="filterBy"
            />
            <cbs-data-reference hide entitysid="function" @load="onLoadEntities" />
          </div>
          <v-select v-else-if="modification.destentity && modification.oper.sid === 'paramentity' && fields"
                    v-model="modification.value.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="fields"
                    :filter-by="filterBy"
          />
        </b-col>
      </b-row>
    </div>
    <cbs-debug v-if="isDebug" :name="$options.name" :context="this" />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BFormTextarea, BRow, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsViewInput from '@/cubus/components/value/CbsViewInput.vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'

export default {
  name: 'CbsModification',
  components: {
    CbsDataReference,
    CbsViewInput,
    CbsDebug,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    vSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    modification: {
      type: Object,
      default: null,
    },
    opers: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      isDebug: false,
      scalars: null,
      formulas: null,
      refEntities: null,
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete')
    },
    selectOper(sid) {
      this.modification.oper = this.opers.find(o => o.sid === sid)
    },
    filterBy(option, label, search) {
      const pattern = `.*${search.replace(new RegExp(' ', 'gi'), '.*').replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onChange() {},
    onChangeEntity() {
      this.resetOperand()
      this.modification.value = { value: { value: null } }
    },
    resetOperand() {
      this.modification.oper = this.opers.find(o => o.sid === 'value')
    },
    onLoadScalars(ref) {
      this.scalars = ref
    },
    onLoadFormulas(ref) {
      this.formulas = ref
    },
    onLoadEntities(ref) {
      this.refEntities = ref
    },
  },
}
</script>

<style scoped>

</style>
