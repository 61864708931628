<template>
  <div>
    <div>
      <cbs-cell-label :cell-full="cellFull" />
      <b-input-group class="mb-0">
        <b-form-input
          v-model="cellFull.cell.value"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
          show-decade-nav
          :state="cellFull.state.check"
          @change="onChange"
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="cellFull.cell.value"
            show-decade-nav
            button-only
            button-variant="outline-primary"
            right
            size="sm"
            :locale="locale"
            aria-controls="example-input"
            @context="onContext"
            @change="onChange"
          />
        </b-input-group-append>
      </b-input-group>
      <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
        {{ cellFull.state.status }}
      </div>
      <b-form-invalid-feedback>
        {{ cellFull.state.status }}
      </b-form-invalid-feedback>
      <div class="invalid-feedback">
        {{ cellFull.state.status }}
      </div>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import {
  BFormDatepicker,
  BFormInput, BFormInvalidFeedback, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsCellLabel from '@/cubus/components/object/CbsCellLabel.vue'

export default {
  name: 'CbsCardDatePicker',
  components: {
    CbsDebug,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BFormInvalidFeedback,
    CbsCellLabel,
  },
  props: {
    cellFull: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'change',
  ],
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    onChange() {
      this.$emit('change')
    },
  },
}
</script>

<style scoped>

</style>
