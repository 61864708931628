<template>
  <div>
    <div>
      <b-button-toolbar class="mb-1" style="align-items: center;">
        <b-button-group>
          <b-button variant="flat-primary" @click="isExpanded = !isExpanded">
            <feather-icon :icon="isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
          </b-button>
        </b-button-group>
        <b-input-group>
          <div class="ml-1 mr-1">{{ name ? name : 'no_name' }}</div>
        </b-input-group>
        <b-col>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon v-if="newValueType() === 'string'" icon="TypeIcon" />
              <feather-icon v-if="newValueType() === 'number'" icon="HashIcon" />
              <feather-icon v-if="newValueType() === 'date'" icon="CalendarIcon" />
              <b-form-checkbox v-if="newValueType() === 'boolean'" v-model="newValue" />
            </b-input-group-prepend>
            <b-form-input v-if="newValueType() !== 'boolean'" v-model="newValue" placeholder="New element value" />
            <b-input-group-append>
              <b-dropdown text="Convert" variant="outline-primary">
                <b-dropdown-item @click="toStringValue">String</b-dropdown-item>
                <b-dropdown-item @click="toNumber">Number</b-dropdown-item>
                <b-dropdown-item @click="toBoolean">Boolean</b-dropdown-item>
                <b-dropdown-item disabled>Date</b-dropdown-item>
              </b-dropdown>
              <b-button variant="outline-primary" @click="addString"><feather-icon icon="PlusIcon" /> Field</b-button>
              <b-button variant="outline-primary" @click="addObject"><feather-icon icon="PlusIcon" />  Object</b-button>
              <b-button variant="outline-primary" @click="addArray"><feather-icon icon="PlusIcon" />  Array</b-button>
              <b-button variant="outline-danger" @click="deleteMe"><feather-icon icon="TrashIcon" />  Delete</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-button-toolbar>

      <cbs-collapse :trigger="isExpanded">
        <div v-if="container" class="ml-1">
          <div v-for="(elem, elemidx) in container[name]" :key="elemidx" class="mb-1">
            <cbs-json-object v-if="elemType(elem) === 'object'" :parent-array="container[name]" :parent-array-index="elemidx" @delete="deleteElem" />
            <cbs-json-array v-else-if="elemType(elem) === 'array'" :parent-array="container[name]" :parent-array-index="elemidx" @delete="deleteElem" />
            <cbs-json-array-value v-else :parent-array="container[name]" :array-index="elemidx" @delete="deleteElem" />
          </div>
        </div>
        <div v-if="parentArray" class="ml-1">
          <div v-for="(elem, elemidx) in parentArray[parentArrayIndex]" :key="elemidx" class="mb-1">
            <cbs-json-object v-if="elemType(elem) === 'object'" :parent-array="parentArray[parentArrayIndex]" :parent-array-index="elemidx" @delete="deleteElem" />
            <cbs-json-array v-else-if="elemType(elem) === 'array'" :parent-array="parentArray[parentArrayIndex]" :parent-array-index="elemidx" @delete="deleteElem" />
            <cbs-json-array-value v-else :parent-array="parentArray[parentArrayIndex]" :array-index="elemidx" @delete="deleteElem" />
          </div>
        </div>
      </cbs-collapse>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsJsonValue from '@/cubus/components/json-editor/CbsJsonValue.vue'
import CbsJsonArrayValue from '@/cubus/components/json-editor/CbsJsonArrayValue.vue'
// import CbsJsonObject from '@/cubus/components/json-editor/CbsJsonObject.vue'

export default {
  name: 'CbsJsonArray',
  components: {
    CbsDebug,
    CbsCollapse,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BButtonGroup,
    BButtonToolbar,
    BFormInput,
    CbsJsonValue,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BFormCheckbox,
    CbsJsonArrayValue,
    // CbsJsonObject,
    CbsJsonObject: () => import('@/cubus/components/json-editor/CbsJsonObject.vue'),
  },
  props: {
    container: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    parentArray: {
      type: Array,
      default: null,
    },
    parentArrayIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      newValue: null,
    }
  },
  methods: {
    addString() {
      // console.log('container', this.container)
      // console.log('array', this.container[this.name])
      this.container[this.name].push(this.newValue)
    },
    addObject() {
      if (this.container) {
        this.container[this.name].push({})
      } else {
        this.parentArray[this.parentArrayIndex].push({})
      }
    },
    addArray() {
      this.value().push([])
    },
    elemType(elem) {
      if (elem instanceof Array) return 'array'
      if (elem === null) return 'field'
      return typeof elem
    },
    deleteMe() {
      if (this.name) {
        this.$emit('delete', this.name)
      } else {
        this.$emit('delete', this.parentArrayIndex)
      }
    },
    toNumber() {
      this.newValue = Number(this.newValue)
    },
    toStringValue() {
      this.newValue = this.newValue.toString()
    },
    toBoolean() {
      this.newValue = !!this.newValue
    },
    newValueType() {
      // const attr = this.container[this.name]
      const attr = this.newValue
      if (attr instanceof Array) return 'array'
      if (attr === null) return 'string'
      return typeof this.newValue
    },
    deleteElem(idx) {
      if (this.container) {
        this.container[this.name].splice(idx, 1)
      } else {
        this.parentArray[this.parentArrayIndex].splice(idx, 1)
      }
    },
    value() {
      if (this.container && this.name) {
        return this.container[this.name]
      }
      if (this.parentArray && this.parentArrayIndex) {
        return this.parentArray[this.parentArrayIndex]
      }
      return null
    },
  },
}
</script>

<style scoped>

</style>
