<template>
  <div>
    <b-form-input v-if="field.datatype === 'id'"
                  v-model="value.value.value"
                  placeholder="Place for ID"
                  disabled
    />
    <div v-else-if="field.entitysid === 'parent'">
      <v-select v-if="ref"
                v-model="value.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="ref"
                :filter-by="filterBy"
      />
      <cbs-data-reference hide :entityid="field.parententityid" @load="onLoadRef()" />
    </div>
    <div v-else-if="field.datatype === 'ref'">
      <v-select v-if="ref"
                v-model="value.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="ref"
                :filter-by="filterBy"
      />
      <cbs-data-reference hide :entityid="field.value" @load="onLoadRef" />
    </div>
    <b-form-input v-else-if="field.datatype === 'int'"
                  v-model="value.value.value"
                  placeholder="Input integer value"
                  type="number"
                  :formatter="formatter"
    />
    <div v-else-if="field.datatype === 'datetime'">
      <b-input-group class="mb-1">
        <b-form-input
          id="example-input"
          v-model="value.value.value"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
          show-decade-nav
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="value.value.value"
            show-decade-nav
            button-only
            button-variant="outline-primary"
            right
            size="sm"
            :locale="locale"
            aria-controls="example-input"
            @context="onContext"
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-form-checkbox v-else-if="field.datatype === 'bit'" v-model="value.value.value" />
    <b-form-input v-else
                  v-model="value.value.value"
                  placeholder="Normal Input"
    />
    <cbs-debug v-if="isDebug" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import vSelect from 'vue-select'
import {
  BFormCheckbox, BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsViewInput',
  components: {
    CbsDataReference,
    CbsDebug,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormCheckbox,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ref: null,
      isDebug: false,
    }
  },
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  watch: {
    field() {
      this.ref = null
    },
  },
  methods: {
    formatter(value) {
      return Number(value)
    },
    onLoadRef(ref) {
      this.ref = ref
    },
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
}
</script>

<style scoped>

</style>
