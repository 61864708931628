<template>
  <div class="border-top-secondary mt-1 pt-1">
    <b-row>
      <b-col cols="1" class="pr-0 d-flex align-items-center" align-self="end">
        <b-form-checkbox switch class="custom-control-success" style="margin-bottom: 6px; margin-left: 8px;" v-model="condition.isactive">
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
        <b-button v-if="false" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="condition.isactive ? 'outline-success' : 'outline-danger'"
                  @click="condition.isactive = !condition.isactive"
        >
          <feather-icon :icon="condition.isactive ? 'CheckIcon' : 'XIcon'" />
        </b-button>
      </b-col>
      <b-col cols="10" class="">
        <div v-if="condition.oper.args === 1">
          <b-row>
            <b-col cols="2" class="pl-0" align-self="end">
              <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="condition.oper.name"
                  variant="outline-primary"
                  block
              >
                <b-dropdown-item v-for="(oper, operidx) in opers"
                                 :key="'oper' + operidx"
                                 @click="selectOper(oper.sid)"
                >
                  {{ oper.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="10" class="pl-0 pr-0">
              <cbs-condition-argument :argument="condition.args[0]"
                                      :index="0" :fields="fields" :param-fields="paramFields"
                                      @selecttype="onSelectType"
                                      @change="onChangeArgument"
              />
            </b-col>
          </b-row>
        </div>
        <div v-if="condition.oper.args === 2">
          <b-row>
            <b-col cols="5" class="pl-0">
              <cbs-condition-argument :argument="condition.args[0]"
                                      :index="0" :fields="fields" :param-fields="paramFields"
                                      @selecttype="onSelectType"
                                      @change="onChangeArgument"
              />
            </b-col>
            <b-col cols="2" class="pl-0 pr-0" align-self="end">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="condition.oper.name"
                variant="outline-primary"
                block
              >
                <b-dropdown-item v-for="(oper, operidx) in opers"
                                 :key="'oper' + operidx"
                                 @click="selectOper(oper.sid)"
                >
                  {{ oper.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="5" class="pr-0">
              <cbs-condition-argument :argument="condition.args[1]"
                                      :index="1" :fields="fields" :param-fields="paramFields"
                                      @selecttype="onSelectType"
                                      @change="onChangeArgument"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="1" align-self="end">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" @click="onDelete">
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCol, BDropdown, BDropdownItem, BFormCheckbox,
  BRow, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsConditionArgument from '@/cubus/components/filter/CbsConditionArgument.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsFilterCondition',
  components: {
    CbsConditionArgument,
    BFormCheckbox,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    condition: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    paramFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      opers: [
        {
          id: 1, sid: 'equals', name: '=', args: 2,
        },
        {
          id: 2, sid: 'notequals', name: '<>', args: 2,
        },
      ],
      isEdit: false,
      isExpanded: true,
      uuid: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      this.isExpanded = false
      this.loadOpers()
    },
    selectOper(sid) {
      console.log('select oper', sid)
      this.condition.oper = this.opers.find(o => o.sid === sid)
    },
    onDelete() {
      console.log('delete cond')
      this.$emit('delete', this.index)
    },
    onSelectType(prm) {
      this.processCondition()
    },
    onChangeArgument() {
      // console.log('change argument')
      this.processCondition()
    },
    processCondition() {
      if (this.condition.oper.args === 2) {
        if (this.condition.args[0].type.sid === 'entity' && this.condition.args[1].type.sid === 'value' && this.condition.args[0].value) {
          this.condition.args[0].datatype = null
          this.condition.args[0].entityid = null
          this.condition.args[0].entitysid = null
          this.condition.args[0].parententityid = null
          this.condition.args[1].datatype = this.condition.args[0].value.datatype
          this.condition.args[1].entityid = this.condition.args[0].value.value
          this.condition.args[1].entitysid = this.condition.args[0].value.entitysid
          this.condition.args[1].parententityid = this.condition.args[0].value.parententityid
        } else if (this.condition.args[1].type.sid === 'entity' && this.condition.args[0].type.sid === 'value' && this.condition.args[1].value) {
          this.condition.args[1].datatype = null
          this.condition.args[1].entityid = null
          this.condition.args[1].entitysid = null
          this.condition.args[1].parententityid = null
          this.condition.args[0].datatype = this.condition.args[1].value.datatype
          this.condition.args[0].entityid = this.condition.args[1].value.value
          this.condition.args[0].entitysid = this.condition.args[1].value.entitysid
          this.condition.args[0].parententityid = this.condition.args[1].value.parententityid
        } else if (this.condition.args[0].type.sid === 'paramentity' && this.condition.args[0].value && this.condition.args[1].type.sid === 'value') {
          this.condition.args[1].datatype = this.condition.args[0].value.datatype
          this.condition.args[1].attributeid = this.condition.args[0].value.attributeid
        } else if (this.condition.args[1].type.sid === 'paramentity' && this.condition.args[1].value && this.condition.args[0].type.sid === 'value') {
          this.condition.args[0].datatype = this.condition.args[1].value.datatype
          this.condition.args[0].attributeid = this.condition.args[1].value.attributeid
        } else if (this.condition.args[0].type.sid === 'formula' && this.condition.args[1].type.sid === 'value') {
          if (this.condition.args[0].value && this.condition.args[0].value.entity) {
            this.condition.args[1].datatype = this.condition.args[0].value.entity.datatype.sid
            this.condition.args[1].entityid = this.condition.args[0].value.entity.id
          } else {
            this.condition.args[1].datatype = null
            this.condition.args[1].entityid = null
          }
        }
      }
    },
    loadOpers() {
      useJwt.query({
        query: {
          method: 'conditionoperands',
          param: {},
        },
      })
        .then(response => {
          console.log('conditionOperands response', response)
          if (response.data.thread) {
            this.delayOpers(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('conditionOperands error', error)
          useCubus.toastError(this, error)
        })
    },
    delayOpers(thread) {
      setTimeout(() => this.threadOpers(thread), 500)
    },
    threadOpers(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: {
            threadname: thread,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.thread.status === 'done') {
            this.opers = response.data.thread.result.result.opers
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            this.delayOpers(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
</style>
