<template>
  <div>
    <!--<div>
      <p>name: {{value.name}}</p>
      <p>descr: {{value.descr}}</p>
    </div>-->
    <div style="margin-top:1rem;">
      <b-button-toolbar>
        <b-button-group style="margin-right:1rem;align-items: center;">
          <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                        class="cursor-pointer"
                        @click="expanded = !expanded"
          />
          &nbsp;&nbsp;
          <b-form-checkbox switch class="custom-control-success" v-model="value.isactive" />&nbsp;&nbsp;
        </b-button-group>
        <b-button-group>
          <b-button size="sm" variant="outline-primary" @click="addMod()">
            <feather-icon icon="PlusIcon" /> Add
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <div v-if="expanded && opers" style="margin-top:1rem;margin-left:1.5rem;">
      <cbs-modification v-for="(mod,index) in value.list"
                        :key="index"
                        :modification="mod"
                        style="margin-top:0.5rem;padding-top:0.5rem;border-top:solid #cccccc 1px;"
                        :opers="opers"
                        :fields="fields"
                        @delete="onDelete(index)"
      />
    </div>
    <cbs-data-reference hide entitysid="modoper" @load="onLoadOpers" />
    <cbs-debug :name="$options.name" :context="context()" style="margin-top: 1rem;" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsModification from '@/cubus/components/modification/CbsModification.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, BFormCheckbox,
} from 'bootstrap-vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'

export default {
  name: 'CbsModificationSet',
  components: {
    CbsDataReference,
    CbsModification,
    CbsDebug,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      result: this.value,
      expanded: true,
      opers: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // console.log('CbsModificationSet', this.value)
    },
    context() { return this },
    addMod() {
      this.value.list.push(this.newMod())
    },
    newMod() {
      return {
        isactive: false,
        name: `Modification ${this.value.list.length + 1}`,
        descr: '',
        destentity: null,
        oper: this.defaultOper(),
        value: { value: { value: null } },
        entity: { id: null, sid: null, title: null },
        scalar: { id: null, sid: null, title: null },
        formula: { id: null, sid: null, title: null },
      }
    },
    onDelete(index) {
      console.log('delete', index)
      this.value.list.splice(index, 1)
    },
    onLoadOpers(ref) {
      this.opers = ref
    },
    defaultOper() {
      if (this.opers) {
        return this.opers.find(o => o.sid === 'value')
      }
      return null
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0px;
}
</style>
