<template>
  <div>
    <div>
      <b-dropdown
        :text="value.argtype.title"
        variant="flat-primary"
        size="sm"
      >
        <b-dropdown-item v-for="(type, index) in types" :key="'argtype' + index" @click="selectType(type.sid)">
          {{ type.title }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div>
      <div v-if="value.argtype.sid === 'value'">
        <b-form-checkbox v-if="entity.datatype.sid === 'bit'"
                         v-model="value.argvalue.value"
        />
        <b-form-textarea v-else-if="entity.datatype.sid === 'text'"
                         :id="fld.key + 'Label'"
                         v-model="value.argvalue.value"
                         placeholder="Textarea"
                         rows="3"
        />
        <b-form-input v-else-if="entity.datatype.sid === 'int'"
                      v-model="value.argvalue.value"
                      placeholder="Input integer value"
                      type="number"
                      :formatter="formatter"
        />
        <cbs-date-picker v-else-if="entity.datatype.sid === 'datetime'" :cell="value.argvalue" />
        <cbs-reference-picker-three v-else-if="entity.datatype.sid === 'ref'" :record="value" field-name="argvalue" :entity-id="entity.id" />
        <b-form-input v-else
                      v-model="value.argvalue.value"
                      placeholder="Enter value"
        />
      </div>
      <div v-else-if="value.argtype.sid === 'formula'">
        <cbs-reference-picker-sid
          :value-container="value"
          value-name="argvalue"
          entitysid="formula"
        />
      </div>
      <div v-else-if="value.argtype.sid === 'entity'">
        <cbs-reference-picker-sid
          :value-container="value"
          value-name="argvalue"
          entitysid="entity"
        />
      </div>
      <div v-else>
        Unknown argument type {{value.argtype.title}}
      </div>
    </div>
    <cbs-data-reference entitysid="condargtype" hide @load="onLoadTypes" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import {
  BDropdown, BDropdownItem, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'
import cbsDatePicker from '@/cubus/components/date-picker/СbsDatePicker.vue'
import CbsReferencePickerThree from '@/cubus/components/reference/CbsReferencePickerThree.vue'

export default {
  name: 'CbsArgument',
  components: {
    CbsReferencePickerSid,
    CbsDataReference,
    CbsDebug,
    BDropdownItem,
    BDropdown,
    BFormInput,
    cbsDatePicker,
    CbsReferencePickerThree,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      types: [],
    }
  },
  methods: {
    onLoadTypes(ref) {
      this.types = ref
    },
    selectType(sid) {
      this.value.argtype = this.types.find(t => t.sid === sid)
      this.value.argvalue.value = null
      this.value.argvalue.sid = null
      this.value.argvalue.title = null
    },
    formatter(value) {
      return Number(value)
    },
  },
}
</script>

<style scoped>

</style>
