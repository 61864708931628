<template>
  <div>
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsDataEntity',
  components: {
    CbsDebug,
  },
  props: {
    entityid: {
      type: Number,
      default: null,
    },
    entitysid: {
      type: String,
      default: null,
    },
  },
  emits: [
    'load',
  ],
  data() {
    return {
      hide: false,
      entity: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadEntity()
    },
    loadEntity() {
      useJwt.query({
        query: {
          method: 'entity',
          param: {
            entityid: this.entityid ? this.entityid : 0,
            entitysid: this.entitysid ? this.entitysid : '',
          },
        },
      })
        .then(response => {
          // console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadEntity(response.data.thread)
          } else {
            useCubus.toastError(this, `Entity ${this.entitysid ? this.entitysid : this.entityid} not found.`)
          }
        })
        .catch(error => {
          console.log('entref error', error)
          useCubus.toastError(this, error)
        })
    },
    threadEntity(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.entity = response.data.thread.result.entity
                this.onLoad()
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayEntity(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayEntity(thread) { setTimeout(() => this.threadEntity(thread), 500) },
    onLoad() {
      this.$emit('load', this.entity)
    },
  },
}
</script>

<style scoped>

</style>
