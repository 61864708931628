<template>
  <div class="mb-1">
    <b-row>
      <b-col cols="1">
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-form-checkbox v-model="value.isActive"
                             name="check-button"
                             switch
                             inline
                             class="custom-control-success"
            />
          </b-button-group>
        </b-button-toolbar>
      </b-col>
      <b-col cols="9">
        <cbs-reference-picker2 :record="value" field="join" :options="options" />
      </b-col>
      <b-col cols="2">
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" @click="onDelete">
              <feather-icon icon="TrashIcon" />
              Delete
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsReferencePicker2 from '@/cubus/components/reference/CbsReferencePicker2.vue'

export default {
  name: 'CbsJoin',
  directives: {
    Ripple,
  },
  components: {
    CbsReferencePicker2,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BFormCheckbox,
    BButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete',
  ],
  methods: {
    onDelete() {
      // console.log('delete join')
      this.$emit('delete', this.index)
    },
  },
}
</script>

<style scoped>

</style>
