<template>
  <div class="m-1 p-1">
    <div class="d-flex justify-content-center align-items-center">
      <div v-if="error" class="text-center text-danger">
        <feather-icon size="24" icon="AlertTriangleIcon" />
        <p>Error: {{ error }}</p>
      </div>
      <div v-else-if="message" class="text-center text-info">
        <feather-icon size="24" icon="InfoIcon" />
        <p>Message: {{ message }}</p>
      </div>
      <div v-else class="d-flex align-items-center">
        <b-spinner variant="secondary" label="Loading..." />
        <span class="ml-1 text-secondary">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'CbsSpinner',
  components: {
    BSpinner,
  },
  props: {
    message: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
