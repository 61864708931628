<template>
  <div>
    <cbs-collapse :trigger="ref != null">
      <v-select v-if="ref"
                v-model="record[field.key]"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="ref"
                @input="onInputVSelect($event)"
                :class="variant"
                :filterBy="filterBy"
      >
        <template slot="option" slot-scope="item">
          {{ item.title }}
          <small class="text-muted"><i>({{ item.value }})</i></small>
        </template>
      </v-select>
    </cbs-collapse>
    <cbs-data-refsid hide :entityid="refEntityId()" @load="onLoadRef" />
    <cbs-debug hidden :context="this" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsDataRefsid from '@/cubus/components/reference-sid/CbsDataRefsid.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsRefsidPicker',
  components: {
    CbsCollapse,
    vSelect,
    CbsDataRefsid,
    CbsDebug,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ref: null,
    }
  },
  methods: {
    onLoadRef(ref) {
      this.ref = ref
    },
    filterBy(option, label, search) {
      const pattern = `^${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onInputVSelect(val) {
      // console.log('onInputVSelect', val)
      if (!val) this.record[this.field.key] = { value: '', title: '' }
    },
    refEntityId() {
      if (this.field.entitysid === 'parent') {
        return this.field.parententityid
      }
      return this.field.entityid
    },
  },
}
</script>

<style scoped>

</style>
