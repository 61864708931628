<template>
  <div>
    <cbs-objects-tbl meta-filter="mount" title="MOUNTS" />

    <b-row v-if="false">
      <b-col>
        <cbs-card-actions title="MOUNTS">
          <b-card-body>
            <cbs-object-table v-if="obj" :object-full="obj" />
          </b-card-body>
        </cbs-card-actions>
      </b-col>
    </b-row>

    <cbs-data-object v-if="false" objectsid="object" @load="onLoadObject" />
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import {
  BCardBody, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'CbsObjectsMount',
  components: {
    CbsCardActions,
    CbsObjectTable,
    CbsDataObject,
    CbsObjectsTbl,
    BRow,
    BCol,
    BCardBody,
  },
  data() {
    return {
      obj: null,
    }
  },
  created() {
    useCubus.setPageTitle('Mounts')
  },
  methods: {
    onLoadObject(obj) { this.obj = obj },
  },
}
</script>

<style scoped>

</style>
