<template>
  <div>
    <cbs-card-actions action-close title="Upload File" @close="onCancel">
      <b-card-body>
        <b-form-file
          v-model="file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        />
        <!-- buttons -->
        <b-button-toolbar
          key-nav
          aria-label="Toolbar with button groups"
          class="demo-inline-spacing"
        >
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="danger"
                @click="onCancel"
            >
              <feather-icon icon="XIcon" />
              Cancel
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="warning"
              @click="clearFile"
            >
              <feather-icon icon="DeleteIcon" />
              Clear
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              :disabled="!file"
              @click="onUpload"
            >
              <feather-icon icon="UploadIcon" />
              Upload
            </b-button>
          </b-button-group>
          <b-button-group v-if="isAdmin()" size="sm" class="cbs-inline-spacing">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="isDebug ? 'primary' : 'outline-primary'"
                @click="isDebug = !isDebug"
            >
              <feather-icon icon="CodeIcon" /> Debug
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <!-- /buttons -->

        <!-- debug -->
        <b-row v-if="isDebug">
          <b-col>
            <div style="margin-top:10px;">
              <div style="margin:10px">
                Props: <json-viewer :value="getProps()" :expand-depth=0 sort/>
                Data: <json-viewer :value="getData()" :expand-depth=0 sort/>
                Session: <json-viewer :value="session()" :expand-depth=0 sort/>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- /debug -->
      </b-card-body>
    </cbs-card-actions>
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar, BCardBody, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import useJwt from '@/cubus/jwt/useJwt'

export default {
  name: 'CbsFileUpload',
  components: {
    BFormFile,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    CbsCardActions,
    BCardBody,
  },
  emits: [
    'save',
  ],
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      isDebug: false,
      record: null,
    }
  },
  methods: {
    onCancel() {},
    clearFile() {
      this.file = null
    },
    onUpload() {
      this.threadLog = []
      // this.record = this.$refs.recordForm.getRecord()
      const formData = new FormData()
      formData.append('file0', this.file)
      formData.append('query', JSON.stringify({
        method: 'uploadfile',
        param: {
          attachments: 1,
          filekeys: ['file0'],
        },
      }))
      console.log('formData ready', formData)
      useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then(response => {
          console.log('upload response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.delayUpload(response.data.thread)
          }
        })
        .catch(error => {
          console.log('upload error', error)
          useCubus.toastError(this, error)
        })
    },
    delayUpload(thread) {
      setTimeout(() => this.threadUpload(thread), 1000)
    },
    threadUpload(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('thread upload response', response)
          if (response.data && response.data.thread) {
            this.thread = response.data.thread
            if (response.data.thread.log) {
              this.threadLog = response.data.thread.log
            }
            if (response.data.thread.status === 'done') {
              this.onSave(response.data.thread.result.record)
              // this.record = response.data.thread.result.record
              // console.log('this', this)
              // this.onSave(response.data.thread.result.record)
              // this.$refs.modalComplete.click()
            } else {
              this.delayUpload(thread)
            }
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('thread upload error', error)
          useCubus.toastError(this, error)
        })
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
    onSave(rec) {
      this.$emit('save', rec)
    },
  },
}
</script>

<style scoped>

</style>
