<template>
  <div v-show="false" style="margin-top:20px;">
    <div>
      <h1>CbsZipper</h1>
    </div>
    <div>
      <input v-if="false" type="file" multiple @change="handleFileChange">
      <b-form-file
        style="margin-top:10px;margin-bottom:10px;"
        v-model="files"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        multiple
      />
      <b-button size="sm" variant="primary" @click="downloadArchive">Скачать архив</b-button>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import { BButton, BFormFile } from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsZipper',
  components: {
    BButton,
    BFormFile,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'zipready',
  ],
  data() {
    return {
      // files: [], // массив для хранения выбранных файлов
      zipBlob: null, // объект архива
    }
  },
  methods: {
    async handleFileChange(event) {
      // Сохраняем выбранные файлы в массив files
      this.files = [...event.target.files]
    },
    async zipFiles() {
      const zip = new JSZip()
      // Добавляем каждый выбранный файл в архив
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i]
        // eslint-disable-next-line no-await-in-loop
        const fileContent = await this.readFileContent(file)
        zip.file(file.name, fileContent, { compression: 'DEFLATE' }) // Указываем сжатие для файла
      }
      this.zipBlob = await zip.generateAsync({ type: 'blob' })
      // return await zip.generateAsync({ type: 'blob' })
      this.$emit('zipready', this.zipBlob)
    },
    async zip_v1() {
      const zip = new JSZip()
      // Добавляем каждый выбранный файл в архив
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i]
        // eslint-disable-next-line no-await-in-loop
        const fileContent = await this.readFileContent(file)
        zip.file(file.name, fileContent, { compression: 'DEFLATE' }) // Указываем сжатие для файла
      }
      this.zipBlob = await zip.generateAsync({ type: 'blob' })
      // return await zip.generateAsync({ type: 'blob' })
      this.$emit('zipready', this.zipBlob)
    },
    async zip() {
      this.zipBlob = await useCubus.zipFiles(this.files)
      this.$emit('zipready', this.zipBlob)
    },
    async readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
        reader.readAsArrayBuffer(file)
      })
    },
    async downloadArchive() {
      // Создаем архив
      const zipBlob = await this.zipFiles()
      // Создаем ссылку для скачивания архива
      const url = window.URL.createObjectURL(zipBlob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'archive.zip'
      link.click()
      // Освобождаем ссылку
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped>

</style>
