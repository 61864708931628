<template>
  <div>
    <b-row>
      <b-col>
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button size="sm" variant="flat-primary" @click="isExpanded = !isExpanded"
                      :disabled="!value || !value.joins || !value.joins.length"
            >
              <feather-icon :icon="expandIcon()" />
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="add()">
              <feather-icon icon="PlusIcon" />
              Join
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <div v-if="isExpanded" style="margin-left:20px;margin-top:15px;">
          <div v-for="(join, index) in value.joins" :key="index">
            <cbs-join :value="join"
                      :index="index"
                      :options="ref"
                      @delete="onDelete(index)"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <cbs-data-reference entitysid="join" @load="onLoadRef" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsJoin from '@/cubus/components/join/CbsJoin.vue'

export default {
  name: 'CbsJoinSet',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
    CbsDataReference,
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BButtonToolbar,
    CbsJoin,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      ref: null,
      isExpanded: false,
    }
  },
  methods: {
    onLoadRef(ref) {
      this.ref = ref
    },
    expandIcon() {
      if (!this.value || !this.value.joins || !this.value.joins.length) {
        return 'XIcon'
      }
      if (this.isExpanded) {
        return 'ChevronDownIcon'
      }
      return 'ChevronRightIcon'
    },
    add() {
      this.value.joins.push(this.getDefault())
    },
    getDefault() {
      return {
        isActive: false,
        join: {
          value: null,
          sid: null,
          title: null,
        },
      }
    },
    onDelete(index) {
      this.value.joins.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
