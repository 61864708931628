<template>
  <div>
    <b-row v-if="recordCount > pageSize">
      <b-col cols="12">
        <b-pagination
          v-model="current"
          :total-rows="recordCount"
          :per-page="pageSize"
          first-number
          last-number
          align="right"
          class="mt-2"
        >
          <template #first-text>
            <span class="text-default">
              <feather-icon icon="ChevronsLeftIcon" />
              First
            </span>
          </template>
          <template #prev-text>
            <span class="text-default">
              <feather-icon icon="ChevronLeftIcon" />
              Prev
            </span>
          </template>
          <template #next-text>
            <span class="text-default">
              Next
              <feather-icon icon="ChevronRightIcon" />
            </span>
          </template>
          <template #last-text>
            <span class="text-default">
              Last
              <feather-icon icon="ChevronsRightIcon" />
            </span>
          </template>
          <template #ellipsis-text>
            <div>
              ...
            </div>
          </template>
          <template #page="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <span v-else>{{ page }}</span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BFormInput, BPagination, BRow,
} from 'bootstrap-vue'

export default {
  name: 'CbsPagination',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormInput,
  },
  props: {
    recordCount: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: ['page-change'],
  data() {
    return {
      current: this.currentPage,
    }
  },
  watch: {
    current(val) {
      this.$emit('page-change', val)
    },
  },
}
</script>

<style scoped>

</style>
