<template>
<div>
  <div style="text-align: center;">
    {{ scalar.title }}
    <feather-icon size="18" icon="PlusSquareIcon" class="cursor-pointer text-primary" />
  </div>
</div>
</template>

<script>
export default {
  name: 'CbsViewScalar',
  components: {
  },
  props: {
    scalar: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
