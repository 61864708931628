<template>
  <div>
    <div style="margin-left:10px; margin-top:10px;">
      <h3 style="margin-bottom:15px;">{{propObject.name || 'Order Timeline'}}</h3>
      <!--<b-row>
        <b-col cols="12">
            <div style="display: flex;">
              <div>
                <b-button
                    variant="outline-primary"
                    class="btn-icon rounded-circle btn-sm"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </div>
              <div style="margin-left:10px;">
                <b style="font-size:1.2rem;">Stage name</b>
              </div>
            </div>
            <div style="border-left: solid lightgrey 1px; margin-left:14px;padding-left:25px; padding-bottom:15px;">
              Stage content
            </div>
        </b-col>
      </b-row>-->
      <cbs-collapse :trigger="stageQry && stageQry.recordset && stageQry.recordset.records && stageQry.recordset.records.length > 0">
        <div v-if="stageQry && stageQry.recordset && stageQry.recordset.records">
          <b-row v-for="stage in stageQry.recordset.records" :key="stage.id.value">
            <b-col cols="12">
                <div style="display: flex;">
                  <div>
                    <b-button
                        :variant="activityByStage(stage) ? 'success' : 'outline-secondary'"
                        class="btn-icon rounded-circle btn-sm"
                    >
                      <feather-icon :icon="activityByStage(stage) ? 'CheckIcon' : 'CircleIcon'" />
                    </b-button>
                  </div>
                  <div style="margin-left:10px; width: 100%;">
                    <b style="font-size:1.2rem;" :class="activityByStage(stage) ? '' : 'text-secondary'">{{stage.name.value || 'Stage name'}}</b>
                  </div>
                  <div style="width: 100%; text-align: right; margin-right: 10px; margin-top:4px;">
                    {{ dateByStage(stage) }}
                  </div>
                </div>
                <div style="border-left: solid lightgrey 1px; margin-left:14px;padding-left:26px; padding-bottom:15px;">
                  <div v-if="stage.name.value === 'Контакт'">{{ propRecord.phonenumber.value }} ; {{ propRecord.fldaddress.value }}</div>
                  <div v-else-if="stage.name.value === 'Оформление'">{{ propRecord.ent_110.value }} тенге</div>
                  <div v-else>&nbsp;</div>
                </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="display: flex;">
                <div>
                  <b-button
                      variant="flat-secondary"
                      class="btn-icon btn-sm"
                  >
                    <feather-icon icon="SquareIcon" />
                  </b-button>
                </div>
                <div style="margin-left:10px;">
                  <p style="margin:0px; margin-top:4px;">конец</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </cbs-collapse>
    </div>
    <div v-show="false">
      <cbs-data-object v-if="propObject && propObject.id" :objectid="propObject.id" hide @load="onLoadObject" />
      <cbs-data-object v-if="stageEntId" :entityid="stageEntId" @load="onLoadStageObj" />
      <cbs-data-query v-if="stageObj" :object="stageObj" @load="onLoadStageQry" :orderby="activityOrderby()" />
      <cbs-data-query v-if="objectFull" :object="objectFull" :filter="activityFilter()" :groupby="activityGroupby()" @load="onLoadActivityQry" />
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>

import { BButton, BCol, BRow } from 'bootstrap-vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'CbsOrderTimeline',
  props: {
    propObject: {
      type: Object,
      default: null,
    },
    propRecord: {
      type: Object,
      default: null,
    },
  },
  components: {
    CbsCollapse,
    CbsDataQuery,
    CbsDataObject,
    BButton,
    BRow,
    BCol,
    CbsDebug,
  },
  data() {
    return {
      objectFull: null,
      stageEntId: null,
      parentEntId: null,
      dateEntId: null,
      stageObj: null,
      stageQry: null,
      activityQry: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // console.log('CbsOrderTimeline created')
    },
    onLoadObject(obj) {
      this.objectFull = obj
      this.initStageEntId()
      this.initParentEntId()
      this.initDateEntId()
    },
    initStageEntId() {
      if (this.objectFull) {
        // console.log('initStageEnt', this.objectFull)
        const prm = this.objectFull.params.params.find(p => p.paramname === 'ordertimeline_stageent')
        if (prm && prm.entityid && prm.entityid > 0) {
          // console.log('initStageEnt', prm)
          this.stageEntId = prm.entityid
        }
      }
    },
    initParentEntId() {
      if (this.objectFull) {
        const prm = this.objectFull.params.params.find(p => p.paramname === 'ordertimeline_parentent')
        if (prm && prm.entityid && prm.entityid > 0) {
          this.parentEntId = prm.entityid
        }
      }
    },
    initDateEntId() {
      if (this.objectFull) {
        const prm = this.objectFull.params.params.find(p => p.paramname === 'ordertimeline_dateent')
        if (prm && prm.entityid && prm.entityid > 0) {
          this.dateEntId = prm.entityid
        }
      }
    },
    onLoadStageObj(obj) {
      this.stageObj = obj
    },
    onLoadStageQry(qry) {
      this.stageQry = qry
    },
    activityFilter() {
      return {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                {
                  type: { sid: 'entity' },
                  value: { value: this.parentEntId },
                },
                {
                  type: { sid: 'value' },
                  value: { value: this.propRecord.id.value },
                },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: { value: 'del' },
                },
                {
                  type: { sid: 'value' },
                  value: { value: false },
                },
              ],
            },
          ],
        },
      }
    },
    activityGroupby() {
      return {
        isActive: true,
        groups: [
          {
            isActive: true,
            oper: { sid: 'group' },
            entity: { value: this.stageEntId },
          },
          {
            isActive: true,
            oper: { sid: 'min' },
            entity: { value: this.dateEntId },
          },
        ],
      }
    },
    activityOrderby() {
      return {
        isActive: true,
        sorts: [
          {
            isActive: true,
            oper: { sid: 'asc' },
            entity: { sid: 'code' },
          },
        ],
      }
    },
    onLoadActivityQry(qry) {
      this.activityQry = qry
    },
    activityByStage(stage) {
      if (this.activityQry && this.activityQry.recordset && this.activityQry.recordset.records && this.activityQry.recordset.records.length > 0) {
        return this.activityQry.recordset.records.find(row => row[`ent_${this.stageEntId}`].value === stage.id.value)
      }
      return null
    },
    dateByStage(stage) {
      const st = this.activityByStage(stage)
      if (st) {
        return st.flddate.value
      }
      return ''
    },
  },
}
</script>
