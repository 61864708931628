<template>
  <div>
    <b-row>
      <b-col>
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button size="sm" variant="flat-primary" @click="isExpanded = !isExpanded"
                      :disabled="!value.steps.length">
              <feather-icon :icon="expandIcon()" />
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="addStep()">
              <feather-icon icon="PlusIcon" />
              New Step
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <cbs-collapse :trigger="isExpanded" style="margin-left:20px;margin-top:10px;">
          <div>
            <div v-for="(step, index) in value.steps" :key="index" style="border-top:solid #cccccc 1px; padding-bottom: 0.75rem;">
              <cbs-report-config-step
                :object="object"
                :param-record="paramRecord"
                :step="step"
                :index="index"
                :opers="opers"
                :can-up="value.steps.length > 1 && index > 0"
                :can-down="value.steps.length > 1 && index < (value.steps.length - 1)"
                @delete="onStepDelete(index)"
                @moveup="stepUp(index)"
                @movedown="stepDown(index)"
              />
            </div>
          </div>
        </cbs-collapse>
      </b-col>
    </b-row>
    <cbs-data-reference entitysid="reportstepoperand" @load="onLoadOperands" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCol,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsReportConfigStep from '@/cubus/components/object-report/CbsReportConfigStep.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsDataReportConfig',
  directives: {
    Ripple,
  },
  components: {
    CbsCollapse,
    CbsDataReference,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    CbsDebug,
    CbsReportConfigStep,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    object: {
      type: Object,
      default: null,
    },
    paramRecord: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      opers: null,
    }
  },
  methods: {
    expandIcon() {
      if (this.value.steps.length > 0) {
        if (this.isExpanded) {
          return 'ChevronDownIcon'
        }
        return 'ChevronRightIcon'
      }
      return 'XIcon'
    },
    addStep() {
      this.value.steps.push(this.defaultStep())
    },
    defaultStep_v1() {
      return {
        isActive: false,
        operand: {
          value: 1,
          sid: 'query',
          title: 'Query',
        },
        name: `step ${useCubus.guid()}`,
        isExpanded: false,
        queryFilter: {
          node: {
            isactive: false,
            oper: {
              title: 'And',
              sid: 'and',
              value: 1,
            },
            nodes: [],
            conds: [],
            isexpanded: false,
          },
        },
      }
    },
    defaultStep() {
      return {
        isActive: false,
        operand: null,
        name: `step ${useCubus.guid()}`,
        isExpanded: false,
        queryFilter: {
          node: {
            isactive: false,
            oper: {
              title: 'And',
              sid: 'and',
              value: 1,
            },
            nodes: [],
            conds: [],
            isexpanded: false,
          },
        },
      }
    },
    onStepDelete(index) {
      this.value.steps.splice(index, 1)
    },
    onLoadOperands(ref) {
      this.opers = ref
    },
    stepUp(idx) {
      // console.log('step up')
      const tmp1 = JSON.parse(JSON.stringify(this.value.steps[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.value.steps[idx - 1]))
      this.$set(this.value.steps, idx, tmp2)
      this.$set(this.value.steps, idx - 1, tmp1)
    },
    stepDown(idx) {
      // console.log('step down')
      const tmp1 = JSON.parse(JSON.stringify(this.value.steps[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.value.steps[idx + 1]))
      this.$set(this.value.steps, idx, tmp2)
      this.$set(this.value.steps, idx + 1, tmp1)
    },
  },
}
</script>

<style scoped>

</style>
