<template>
  <div>
    <b-row v-if="ref">
      <b-col cols="12" class="mb-1">
        <b-button block :variant="selected === -1 ? 'primary' : 'outline-primary'" @click="onSelectAll">
          All
        </b-button>
      </b-col>
      <b-col cols="12" class="mb-1">
        <b-button block :variant="selected === 0 ? 'primary' : 'outline-primary'" @click="onSelectEmpty">
          Empty
        </b-button>
      </b-col>
      <b-col v-for="el in ref" :key="el.value" cols="12" class="mb-1">
        <b-button block :variant="selected === el.value ? 'primary' : 'outline-primary'" @click="onSelect(el)">
          {{el.title}}
        </b-button>
      </b-col>
    </b-row>
    <cbs-data-reference v-if="object" :entityid="entityid" hide @load="onLoadRef" />
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'CbsRefSelParent',
  components: {
    CbsDataReference,
    CbsDebug,
    BRow,
    BCol,
    BButton,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    entityid: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'select',
  ],
  data() {
    return {
      ref: null,
      selected: -1,
    }
  },
  methods: {
    onLoadRef(ref) { this.ref = ref },
    onSelect(el) {
      if (this.selected === el.value) {
        this.selected = -1
      } else {
        this.selected = el.value
      }
      this.emitSelect()
    },
    onSelectAll() {
      this.selected = -1
      this.emitSelect()
    },
    onSelectEmpty() {
      if (this.selected === 0) {
        this.selected = -1
      } else {
        this.selected = 0
      }
      this.emitSelect()
    },
    emitSelect() { this.$emit('select', this.selected) },
  },
}
</script>

<style scoped>

</style>
