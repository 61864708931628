<template>
  <div>
    <cbs-record-form-old
      v-if="false && record"
      :object-full="objectFull"
      ref="recordForm"
      :fields="fields"
      :prop-record="record"
      no-debug
    />
    <cbs-record-form v-if="record" :object="objectFull" :fields="fields" :record="record" :compare="srcRecord" />
    <cbs-data-new-record hide :object-full="objectFull" @load="onLoadNewRecord" />
    <!-- buttons -->
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="onCancel"
        >
          <feather-icon icon="XIcon" />
          {{t('Cancel')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :disabled="!isRunnable() || status === 'processing'"
          @click="onRun"
        >
          <feather-icon icon="PlayIcon" />
          {{t('Run')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle="`collapse_${uuid}`"
          :variant="isLog ? 'primary' : 'outline-primary'"
          @click="isLog = !isLog"
        >
          <feather-icon icon="SettingsIcon" />
          {{t('Log')}}
        </b-button>
        <b-button v-if="isDeveloper()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle="`collapse_${uuid}`"
                  :variant="isSettings ? 'primary' : 'outline-primary'"
                  @click="isSettings = !isSettings"
        >
          <feather-icon icon="SettingsIcon" />
          {{t('Settings')}}
        </b-button>
        <b-button v-if="isDeveloper()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="isDebug ? 'primary' : 'outline-primary'"
                  @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" /> {{ $options.name }}
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <!-- /buttons -->

    <cbs-thread :thread="thread" />

    <!-- settings -->
    <!--
    <div v-if="false && isSettings" style="border: solid #cccccc 1px; border-radius: 5px; margin-top: 20px; padding: 10px;">
      <cbs-object-table v-if="mountstepObj" :object-full="mountstepObj" :filter="settingsFilter()" />
      <cbs-data-object objectsid="mountstep" hide @load="onLoadMountstepObj" />
    </div>
    -->
    <!-- /settings -->

    <!-- params -->
    <cbs-collapse :trigger="isSettings">
    <!--<b-collapse v-if="isSettings" :id="`collapse_${uuid}`">-->
      <div style="border: solid #cccccc 1px; border-radius:5px; margin-top: 20px; margin-bottom: 10px; padding: 10px;">
        <cbs-mount-param :object-full="objectFull" />
      </div>
    <!--</b-collapse>-->
    </cbs-collapse>
    <!-- /params -->

    <!-- thread log -->
    <cbs-collapse :trigger="isLog">
      <div style="margin-top:20px;border: solid 1px #cccccc;border-radius: 5px;padding: 15px;">
      <p><b>Thread Log:</b></p>
      <div v-if="threadLog.length > 0">
        <p v-for="(logLine, logIdx) in threadLog" :key="logIdx">
          <span style="font-size: 0.8rem;">{{ logLine }}</span>
        </p>
      </div>
      <div v-else>
        <p>Thread log is empty.</p>
      </div>
    </div>
    </cbs-collapse>
    <!-- /thread log -->

    <!-- modal -->
    <div>
      <b-button v-show="false"
                :ref="'modal_mount_complete_' + uuid"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :v-b-modal="'modal_procedure_' + uuid"
                variant="outline-primary"
      >
        Message Form
      </b-button>
      <b-modal
        :id="'modal_procedure_' + uuid"
        ok-only
        ok-title="OK"
        centered
        title="Message"
        @ok="onModalClick"
      >
        Mount completed.
      </b-modal>
    </div>
    <cbs-modal :ref="'mount_modal_' + uuid" message="Mount completed." />
    <!-- /modal -->

    <cbs-debug v-if="isDebug" :context="this" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar, VBToggle,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import CbsRecordFormOld from '@/cubus/components/object/CbsRecordForm_old.vue'
import CbsMountParam from '@/cubus/components/object-mount/CbsMountParam.vue'
import CbsRecordForm from '@/cubus/components/record/CbsRecordForm.vue'
import CbsDataNewRecord from '@/cubus/components/query/CbsDataNewRecord.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsThread from '@/cubus/components/thread/CbsThread.vue'
import CbsModal from '@/cubus/components/modal/CbsModal.vue'

export default {
  name: 'CbsObjectMount',
  components: {
    CbsDebug,
    CbsDataNewRecord,
    CbsRecordForm,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    CbsRecordFormOld,
    CbsMountParam,
    CbsCollapse,
    CbsThread,
    CbsModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [
    'close',
  ],
  data() {
    return {
      isDebug: false,
      fields: [],
      record: null,
      srcRecord: null,
      threadLog: [],
      isSettings: false,
      uuid: null,
      mountstepObj: null,
      status: 'init',
      isLog: false,
      thread: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
    },
    onCancel() {
      this.$emit('close')
    },
    onRun() {
      this.status = 'processing'
      this.getRecordFromForm()
      this.threadLog = []
      useJwt.query({
        query: {
          method: 'runmount',
          param: {
            objectid: this.objectFull.object.id,
            record: this.record,
          },
        },
      })
        .then(response => {
          console.log('runprocedure response', response)
          if (response.data) {
            if (response.data.thread) {
              this.delayProc(response.data.thread)
            } else if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('runprocedure error', error)
        })
    },
    delayProc(thread) {
      setTimeout(() => this.threadProc(thread), 500)
    },
    threadProc(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.thread) this.thread = response.data.thread
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
              this.status = 'error'
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('procedure result', response.data.thread.result)
                this.threadLog = response.data.thread.log
                this.status = 'done'
                // this.$refs[`modal_mount_complete_${this.uuid}`].click()
                setTimeout(() => this.showModal(), 500)
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
                this.status = 'error'
              } else {
                this.delayProc(thread)
              }
            }
          } else this.delayProc(thread)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    isAdmin() { return useCubus.isAdmin() },
    isDeveloper() { return useCubus.isDeveloper() },
    onModalClick() {},
    settingsFilter() {
      return {
        isActive: true,
        node: {
          isactive: true,
          oper: { sid: 'and', name: 'And' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: {
                sid: 'equal',
                name: '=',
              },
              args: [
                {
                  type: {
                    sid: 'entitysid',
                    name: 'EntitySid',
                  },
                  value: 'object',
                },
                {
                  type: {
                    sid: 'value',
                    name: 'Value',
                  },
                  value: `${this.objectFull.object.id}`,
                },
              ],
            },
          ],
        },
      }
    },
    getRecordFromForm() {
      // console.log('record from form', this.$refs.recordForm.getRecord())
      // this.record = this.$refs.recordForm.getRecord()
      // console.log('this record', this.record)
    },
    onLoadMountstepObj(obj) {
      this.mountstepObj = obj
    },
    onLoadNewRecord(rec) {
      this.fields = rec.columns
      this.record = rec.record
      this.srcRecord = JSON.parse(JSON.stringify(rec.record))
    },
    showModal() {
      this.$nextTick(() => {
        this.$refs[`mount_modal_${this.uuid}`].show()
      })
    },
    isRunnable() {
      if (!this.record) return false
      if (this.objectFull.attributes.some(a => a.isrequired && !this.record[a.key].value)) return false
      return true
    },
  },
}
</script>

<style scoped>

</style>
