<template>
  <div>
    <b-button-toolbar class="cbs-inline-spacing mb-1">
      <b-button-group>
        <b-button variant="flat-primary" disabled>
          <feather-icon v-if="valueType() === 'string'" icon="TypeIcon" />
          <feather-icon v-else-if="valueType() === 'number'" icon="HashIcon" />
          <feather-icon v-else-if="valueType() === 'boolean'" :icon="value() ? 'CheckSquareIcon' : 'SquareIcon'" />
          <feather-icon v-else-if="valueType() === 'date'" icon="CalendarIcon" />
          <feather-icon v-else icon="CircleIcon" />
        </b-button>
      </b-button-group>
      <b-input-group>
        <div class="ml-1 mr-1">{{ name ? name : 'no_name' }}:</div>
      </b-input-group>
      <b-col>
        <b-input-group>
          <b-input-group-prepend v-if="valueType() === 'boolean'" is-text>
            <b-form-checkbox :v-model="container ? container[name] : parentArray[arrayIndex]" />
          </b-input-group-prepend>
          <b-form-input v-if="valueType() !== 'boolean' && container" v-model="container[name]" />
          <b-form-input v-if="valueType() !== 'boolean' && !container" v-model="parentArray[arrayIndex]" />
          <b-input-group-append>
            <b-button :variant="valueType() === 'string' ? 'primary' : 'outline-primary'" @click="toStringValue">
              String
            </b-button>
            <b-button :variant="valueType() === 'number' ? 'primary' : 'outline-primary'" @click="toNumber">
              Number
            </b-button>
            <b-button :variant="valueType() === 'boolean' ? 'primary' : 'outline-primary'" @click="toBoolean">
              Boolean
            </b-button>
            <b-button :variant="valueType() === 'date' ? 'primary' : 'outline-primary'" disabled>
              Date
            </b-button>
            <b-button variant="outline-danger" @click="deleteValue"><feather-icon icon="TrashIcon" /> Delete</b-button>
          </b-input-group-append>
        </b-input-group>

      </b-col>
    </b-button-toolbar>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  name: 'CbsJsonValue',
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCol,
    BFormCheckbox,
  },
  props: {
    container: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    parentArray: {
      type: Array,
      default: null,
    },
    arrayIndex: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      mode: 'string',
    }
  },
  create() {
    this.init()
  },
  methods: {
    init() {
      console.log('parentArray', this.parentArray)
    },
    valueType() {
      // const attr = this.container[this.name]
      const attr = this.value()
      if (attr instanceof Array) return 'array'
      if (attr === null) return 'string'
      return typeof this.container[this.name]
    },
    toNumber() {
      if (this.container) {
        this.container[this.name] = Number(this.container[this.name])
      } else {
        this.parentArray[this.arrayIndex] = Number(this.parentArray[this.arrayIndex])
      }
    },
    toStringValue() {
      this.container[this.name] = this.container[this.name].toString()
    },
    toBoolean() {
      this.container[this.name] = !!this.container[this.name]
    },
    deleteValue() {
      if (this.name) {
        this.$emit('delete', this.name)
      } else {
        this.$emit('delete', this.arrayIndex)
      }
    },
    value() {
      if (this.container && this.name) {
        return this.container[this.name]
      }
      if (this.container && this.arrayIndex) {
        return this.container[this.arrayIndex]
      }
      return null
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

</style>
