<template>
  <div>
    <!-- debug -->
    <div>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="isDebug ? 'primary' : 'outline-primary'"
                  @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" />
          {{ $options.name }}
        </b-button>
      </b-button-group>
      <div v-if="isDebug" style="margin-left: 10px;margin-right: 10px;">
        Props: <json-viewer :value="getProps()" :expand-depth=0 sort/>
        Data: <json-viewer :value="getData()" :expand-depth=0 sort/>
      </div>
    </div>
    <!-- /gebug -->
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import JsonViewer from 'vue-json-viewer'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CbsDataSaveRecord',
  directives: {
    Ripple,
  },
  components: {
    BButtonGroup,
    BButton,
    JsonViewer,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'save',
  ],
  data() {
    return {
      isDebug: false,
      savedRecord: null,
    }
  },
  methods: {
    save() {
      // console.log('save.objf', this.objectFull)
      // console.log('save.rec', this.record)
      this.saveRecord()
    },
    saveRecord() {
      const rec = this.record
      const formData = new FormData()
      const filekeys = []
      this.objectFull.attributes.forEach(f => {
        if (rec[f.key] && rec[f.key].file) {
          const key = `file_${f.entityid}`
          filekeys.push(key)
          formData.append(key, rec[f.key].file)
        }
      })
      formData.append('query', JSON.stringify({
        method: 'saverecord',
        param: {
          objectid: this.objectFull.object.id,
          record: rec,
          attachments: filekeys.length,
          filekeys,
        },
      }))
      useJwt.upload(formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          console.log('save file response', response)
          if (response.data) {
            if (response.data.thread) {
              this.delaySave(response.data.thread)
            } else if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else {
              useCubus.toastError(this, 'No thread name provided.')
            }
          } else {
            useCubus.toastError(this, 'No data provided.')
          }
        })
        .catch(error => {
          console.log('save file error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySave(thread) { setTimeout(() => this.threadSave(thread), 1000) },
    threadSave(thread) {
      const rec = this.record
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecordFile response', response)
          if (response.data && response.data.thread && response.data.thread.status === 'done') {
            this.savedRecord = response.data.thread.result.record
            this.afterSave()
          } else if (response.data && response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            this.delaySave(thread, rec)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    afterSave() {
      this.$emit('save', this.savedRecord)
    },
    afterSave_v1(savedRec) {
      const initRec = this.record
      savedRec.status = 'saved'
      if (initRec.id.value === null) {
        this.addNewRecord(savedRec)
      } else {
        this.objectFull.fields.forEach(fld => {
          initRec[fld.key] = savedRec[fld.key]
        })
        initRec.status = 'saved'
      }
    },
    getData() { return this._data },
    getProps() { return this._props },
  },
}
</script>

<style scoped>

</style>
