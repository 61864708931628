<template>
  <div>
    <!-- toolbar -->
    <div v-if="!noToolbar" style="margin-bottom: 10px;">
      <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="demo-inline-spacing">
        <b-button-group size="sm" class="cbs-inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="refreshTable"
          >
            <feather-icon icon="PlayIcon" />
            Refresh
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="cbs-inline-spacing">
          <b-button
            ref="btnNew"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle="'collapse-new' + objectid"
            :variant="isNew ? 'primary' : 'outline-primary'"
            @click="toggleNewRecord"
          >
            <feather-icon icon="PlusIcon" />
            New
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="cbs-inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isSearch ? 'primary' : 'outline-primary'"
            @click="toggleSearch"
          >
            <feather-icon icon="SearchIcon" />
            Search
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isSettings ? 'primary' : 'outline-primary'"
            @click="toggleSettings"
          >
            <feather-icon icon="SettingsIcon" />
            Settings
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isFilter ? 'primary' : 'outline-primary'"
            @click="toggleFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isGroupby ? 'primary' : 'outline-primary'"
            @click="toggleGroupby"
          >
            <feather-icon icon="PackageIcon" />
            Group
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isOrderby ? 'primary' : 'outline-primary'"
            @click="isOrderby = !isOrderby"
          >
            <feather-icon icon="ChevronsUpIcon" />
            Sort
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isFields ? 'primary' : 'outline-primary'"
            @click="isFields = !isFields"
          >
            <feather-icon icon="EyeIcon" />
            Fields
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isView ? 'primary' : 'outline-primary'"
            @click="isView = !isView"
          >
            <feather-icon icon="SaveIcon" />
            Views
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <!-- /toolbar -->

    <!-- search -->
    <div v-if="isSearch" style="margin-bottom:10px;">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input placeholder="Search" v-model="search" />
      </b-input-group>
    </div>
    <!-- /search -->

    <!-- view -->
    <div v-if="isView"
         style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px; padding: 10px;">
      <b-row>
        <b-col>
          <cbs-table-view-param :object="object" />
        </b-col>
      </b-row>
    </div>
    <!-- /view -->

    <!-- settings -->
    <div v-if="isSettings" style="margin-bottom:10px;border: solid #d8d6de 1px;border-radius:0.357rem;padding:10px;">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label="Page size:"
            label-for="input-pagesize"
          >
            <b-form-input
              id="input-pagesize"
              type="number"
              placeholder="Page zise"
              :formatter="formatterNumber"
              v-model="perPage"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <span class="text-secondary">Record count: </span><span class="text-primary">{{ recordCount }}</span>
        </b-col>
      </b-row>
    </div>
    <!-- /settings -->

    <!-- filter -->
    <div v-if="isFilter"
         style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px; padding: 10px;">
      <b-row>
        <b-col>
          <cbs-filter :filter="userFilter" :fields="objAttrsFull()" />
        </b-col>
      </b-row>
    </div>
    <!-- /filter -->

    <!-- groupby -->
    <div v-if="isGroupby" style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px; padding: 10px;">
      <cbs-groupby :groupby="groupby" :fields="objAttrsFull()" />
    </div>
    <!-- /groupby -->

    <!-- orderby -->
    <div v-if="isOrderby" style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px; padding: 10px;">
      <cbs-orderby :orderby="orderby" :fields="objAttrsFull()" />
    </div>
    <!-- /orderby -->

    <!-- fields -->
    <div v-if="isFields" style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px; padding: 10px;">
      <p>Fields:</p>
      <table>
        <tr v-for="(fld, fldIdx) in prmFields" :key="fldIdx">
          <td>
            <feather-icon v-if="fldIdx > 0" icon="ArrowUpCircleIcon" size="20" class="text-primary cursor-pointer"
                          @click="fieldUp(fldIdx)"
            />
            &nbsp;
          </td>
          <td>
            <feather-icon v-if="fldIdx < (fields.length-1)" icon="ArrowDownCircleIcon" size="20"
                          class="text-primary cursor-pointer"
                          @click="fieldDown(fldIdx)"
            />
            &nbsp;&nbsp;
          </td>
          <td>
            <feather-icon :icon="fld.visible ? 'EyeIcon' : 'EyeOffIcon'" size="20"
                          class="cursor-pointer"
                          :class="fld.visible ? 'text-success' : 'text-danger'"
                          @click="fld.visible = ! fld.visible"
            />
            &nbsp;&nbsp;
          </td>
          <td>&nbsp;{{ fld.label }}</td>
        </tr>
      </table>
    </div>
    <!-- /fields -->

    <!-- new record -->
    <b-collapse v-if="isNew && newRecord" :id="'collapse-new' + objectFull.object.id">
      <div style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px;">
        <cbs-file-upload v-if="objectFull.object.sid === 'file'" @save="addNewRecord" />
        <cbs-record-card v-else
                         :object-full="objectFull"
                         title="New record"
                         :prop-record="newRecord"
                         :fields="realFields()"
                         :row-index="0"
                         @close="closeNew"
                         @save="onSave(newRecord)"
        />
      </div>
    </b-collapse>
    <!-- /new record -->

    <!-- table -->
    <b-table style="min-height: 250px;" responsive small
             :fields="visibleFields()"
             :items="records" :filter="search"
    >

      <!-- cell template -->
      <template v-for="(field, fldidx) in realFields()"
                v-slot:[`cell(${field.key})`]="data"
      >

        <span v-if="data.value.title" v-bind:key="fldidx">
          {{ data.value.title }}
          <span style="font-size: 0.8em;">({{ data.value.value }})</span>
        </span>

        <span v-else-if="typeof data.value.value == 'boolean'" v-bind:key="fldidx">
          <feather-icon v-if="data.value.value === true" icon="CheckSquareIcon" />
          <feather-icon v-else icon="SquareIcon" />
        </span>

        <div v-else-if="field.datatype === 'double'" v-bind:key="fldidx" style="text-align: right;">
          {{ formatDouble(data.value.value) }}
        </div>

        <div v-else-if="field.datatype === 'int'" v-bind:key="fldidx" style="text-align: right;">
          {{ formatInteger(data.value.value) }}
        </div>

        <span v-else :key="fldidx">
          {{ data.value.value }}
          <!--<p>{{data}}</p>-->
        </span>

      </template>
      <!-- /cell template -->

      <!-- actions cell -->
      <template #cell(actions)="row">
        <div class="text-nowrap">
          <cbs-table-record-status :status="row.item.status" />
          <!-- row buttons -->
          <span v-for="(btn, rowBtnIdx) in rowButtons" :key="rowBtnIdx">
            &nbsp;
            <feather-icon :icon="btn.icon"
                          class="cursor-pointer"
                          :class="'text-' + btn.class"
                          size="16"
                          :title="btn.title"
                          @click="onClickRowButton(btn,row)"
            />
          </span>
          <!-- /row buttons -->
          <b-dropdown v-if="relations.length > 0"
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="Share2Icon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-for="(rel, relIndex) in relations"
                             :key="relIndex"
                             @click="onClickRelation(row,rel)"
            >
              <span class="align-middle ml-50">{{ rel.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
          &nbsp;
          <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item @click="showDetails(row)" variant="primary">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
            <b-dropdown-item v-for="(btn, rowDropdownBtnIndex) in rowDropdownButtons"
                             :key="rowDropdownBtnIndex"
                             @click="onClickRowDropdownButton(btn,row)"
            >
              <feather-icon :icon="btn.icon" /> {{ btn.title }}
            </b-dropdown-item>
            <b-dropdown-item v-if="isDeleteAvailable && !isRowDeleted(row)" variant="warning" @click="onDelete(row)">
              <feather-icon icon="TrashIcon" />&nbsp;&nbsp;Delete
            </b-dropdown-item>
            <b-dropdown-item v-if="isRestoreAvailable && isRowDeleted(row)" variant="warning" @click="onRestore(row)">
              <feather-icon icon="CornerUpLeftIcon" />&nbsp;&nbsp;Restore
            </b-dropdown-item>
            <b-dropdown-item v-if="isAccessAvailable('remove') && isRowDeleted(row)" variant="warning"
                             @click="onRemove(row)"
            >
              <feather-icon icon="Trash2Icon" />&nbsp;&nbsp;Remove
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <!-- /actions cell -->

      <!-- record details -->
      <template #row-details="row">
        <div style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px;">
          <cbs-record-card v-if="row.item._detailsMode === 'card'"
                           :objectid="objectid"
                           :object-full="objectFull"
                           :object="object"
                           :fields="realFields()"
                           :prop-record="row.item"
                           :row-index="row.index"
                           title="Record card"
                           @close="row.toggleDetails"
                           @save="onSave(row.item)"
          />
          <cbs-object v-if="row.item._detailsMode === 'relation'"
                      :objectid="row.item._relation.objectid"
                      :filter="row.item._relationFilter"
                      @close="row.toggleDetails"
          />
        </div>
      </template>
      <!-- /record details -->

    </b-table>
    <!-- /table -->

    <!-- pagination -->
    <b-row v-if="recordCount > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="recordCount"
          :per-page="perPage"
          first-number
          last-number
          align="right"
          class="mt-2"
        >
          <template #first-text>
            <span class="text-default">
              <feather-icon icon="ChevronsLeftIcon" />
              First
            </span>
          </template>
          <template #prev-text>
            <span class="text-default">
              <feather-icon icon="ChevronLeftIcon" />
              Prev
            </span>
          </template>
          <template #next-text>
            <span class="text-default">
              Next
              <feather-icon icon="ChevronRightIcon" />
            </span>
          </template>
          <template #last-text>
            <span class="text-default">
              Last
              <feather-icon icon="ChevronsRightIcon" />
            </span>
          </template>
          <template #ellipsis-text>
            <div>
              ...
            </div>
          </template>
          <template #page="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <span v-else>{{ page }}</span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <!-- /pagination -->

    <!-- remove modal -->
    <div>
      <b-button v-show="false" :ref="`refBtmRemove_${uuid}`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal="`modal-remove-${uuid}`"
                variant="outline-primary"
      >
        Login Form
      </b-button>
      <b-modal
        :id="`modal-remove-${uuid}`"
        cancel-variant="outline-warning"
        ok-title="Yes"
        cancel-title="No"
        centered
        title="Remove record"
        @ok="onOkRemove"
        @cancel="onCancelRemove"
        @close="onCloseRemove"
      >
        Are you sure to remove record?
      </b-modal>
    </div>
    <!-- /remove modal -->

    <!-- debug -->
    <div v-if="isAdmin()">
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" /> {{ $options.name }}
        </b-button>
      </b-button-group>
      <div v-if="isDebug" style="margin-left: 10px;margin-right: 10px;">
        Props: <json-viewer :value="getProps()" :expand-depth="0" sort />
        Data: <json-viewer :value="getData()" :expand-depth="0" sort />
      </div>
    </div>
    <!-- /gebug -->

  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BButton, BButtonToolbar, BButtonGroup,
  VBToggle,
  BCollapse, BDropdown, BDropdownItem,
  BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormGroup, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsRecordCard from '@/cubus/components/object/CbsRecordCard.vue'
import JsonViewer from 'vue-json-viewer'
import useCubus from '@/cubus/services/useCubus'
import CbsFilter from '@/cubus/components/filter/CbsFilter.vue'
import CbsFileUpload from '@/cubus/components/file/CbsFileUpload.vue'
import CbsGroupby from '@/cubus/components/groupby/CbsGroupby.vue'
import CbsOrderby from '@/cubus/components/orderby/CbsOrderby.vue'
import CbsTableViewParam from '@/cubus/components/table-view-param/CbsTableViewParam.vue'
import CbsTableRecordStatus from '@/cubus/components/query/CbsTableRecordStatus.vue'

export default {
  name: 'CbsDataTable',
  components: {
    CbsTableViewParam,
    CbsGroupby,
    CbsFileUpload,
    CbsFilter,
    CbsObject: () => import('@/cubus/components/object/CbsObject.vue'),
    CbsRecordCard,
    BTable,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    JsonViewer,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    CbsOrderby,
    CbsTableRecordStatus,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    rowDropdownButtons: {
      type: Array,
      default: () => ([]),
    },
    domainsid: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    noToolbar: {
      type: Boolean,
      default: false,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'close',
    'clickrowbutton',
    'readyQuery',
  ],
  data() {
    return {
      object: {},
      query: {},
      fields: [],
      thread: {},
      records: [],
      isNew: false,
      isSearch: false,
      newRecord: null,
      search: null,
      isRefreshQuery: false,
      rowToRemove: {},
      isDebug: false,
      relations: [],
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50],
      isSettings: false,
      recordCount: -1,
      isFilter: false,
      userFilter: {
        node: {
          isactive: false,
          isexpanded: false,
          name: 'Root Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [],
          conds: [],
        },
      },
      isGroupby: false,
      groupby: {
        isActive: false,
        groups: [],
      },
      attributes: [],
      numberFormatConfig: {
        decimal: '.',
        separator: ',',
        prefix: '$ ',
        suffix: ' #',
        precision: 2,
        masked: false,
      },
      isOrderby: false,
      orderby: {
        isActive: false,
        sorts: [],
      },
      isView: false,
      prmFields: [],
      uuid: null,
      isFields: false,
    }
  },
  watch: {
    currentPage(newValue, oldValue) {
      console.log('watch currentPage', oldValue, newValue)
      this.changePage()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      this.object = this.objectFull.object
      this.objectid = this.objectFull.object.id
      this.loadQuery()
    },
    loadQuery() {
      useJwt.query({
        query: {
          method: 'query',
          param: {
            objectid: this.objectFull.object.id,
            filter: this.filter,
            userfilter: this.userFilter,
            limit: Number(this.perPage),
            offset: this.perPage * (this.currentPage - 1),
            groupby: this.groupby,
            orderby: this.orderby,
          },
        },
      })
        .then(response => {
          console.log('query response', response)
          if (response.data.thread) {
            this.threadQuery(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'No thread found.')
          }
        })
        .catch(errQry => {
          console.log('query error', errQry)
          useCubus.toastError(this, errQry)
        })
    },
    delayQuery(thread) { setTimeout(() => this.threadQuery(thread), 500) },
    threadQuery(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: {
            threadname: thread,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.thread && response.data.thread.status === 'done' && response.data.thread.result) {
            this.setQuery(response.data.thread.result.query)
          } else if (response.data.thread && response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayQuery(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    initTable() {
      this.records = this.query.recordset.records
      this.fields = this.query.columns
      // if (this.prmFields.length === 0) this.initFields()
      this.initFields()
      this.recordCount = this.query.recordcount
    },
    initFields() {
      this.hiddenFields.forEach(f => {
        const ff = this.fields.find(fld => fld.key === f)
        if (ff) ff.visible = false
      })
      this.prmFields = this.fields.map(fld => ({ key: fld.key, label: fld.label, visible: fld.visible }))
    },
    refreshStop(cardName) {
      this.init()
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    closeCard() {
      console.log('cbs object : close card')
      this.$emit('close')
    },
    realFields() {
      return this.fields.filter(fld => fld.key !== 'actions')
    },
    toggleNewRecord() {
      if (this.isNew) {
        this.clearNew()
      } else {
        if (this.objectFull.object.sid !== 'file') {
          this.loadNewRecord()
        }
        this.isNew = true
      }
    },
    clearNew() {
      this.isNew = false
      // this.newRecord = {}
      this.newRecord = null
    },
    toggleSearch() {
      this.isSearch = !this.isSearch
    },
    getNewRecord() {
      this.loadNewRecord()
      /*
      const n = {}
      for (let i = 0; i < this.realFields().length; i += 1) {
        const fld = this.realFields()[i]
        n[fld.key] = fld.default
      }
      // console.log('new record', n)
      return JSON.parse(JSON.stringify(n))
      */
    },
    loadNewRecord() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'newrecord',
          param: {
            objectid: this.objectFull.object.id,
            filter: this.filter,
            userfilter: this.userFilter,
          },
        },
      })
        .then(response => {
          // console.log('newRecord response', response)
          if (response.data.thread) {
            this.threadNewRecord(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('newRecord error', error)
          useCubus.toastError(this, error)
        })
    },
    delayNewRecord(thread) { setTimeout(() => this.threadNewRecord(thread), 500) },
    threadNewRecord(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('new record result', response.data.thread.result)
                this.newRecord = response.data.thread.result.record.record
              } else {
                this.delayNewRecord(threadName)
              }
            }
          } else this.delayNewRecord(threadName)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    closeNew() {
      this.$refs.btnNew.click()
    },
    onSave(record, caller) {
      if (this.hasFileEntity()) {
        this.saveRecordFile(record, caller)
      } else {
        this.saveRecord(record, caller)
      }
    },
    saveRecordFile(record, caller) {
      console.log('save file', record)
      const formData = new FormData()
      const filekeys = []
      this.fields.forEach(f => {
        if (record[f.key] && record[f.key].file) {
          const key = `file_${f.entityid}`
          filekeys.push(key)
          formData.append(key, record[f.key].file)
        }
      })
      formData.append('query', JSON.stringify({
        method: 'saverecord',
        param: {
          objectid: this.object.id,
          record,
          attachments: filekeys.length,
          filekeys,
        },
      }))
      useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then(response => {
          console.log('save file response', response)
          if (response.data) {
            if (response.data.thread) {
              this.threadSaveRecordFile(response.data.thread, record, caller)
            } else if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else {
              useCubus.toastError(this, 'No thread name provided.')
            }
          } else {
            useCubus.toastError(this, 'No data provided.')
          }
        })
        .catch(error => {
          console.log('save file error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecordFile(thread, record, caller) {
      setTimeout(() => this.threadSaveRecordFile(thread, record, caller), 1000)
    },
    threadSaveRecordFile(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecordFile response', response)
          if (response.data && response.data.thread && response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record, caller)
          } else if (response.data && response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            this.delaySaveRecordFile(thread, record)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.delaySaveRecord(response.data.thread, record)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    delaySaveRecord(thread, record) {
      setTimeout(() => this.threadSaveRecord(thread, record), 1000)
    },
    threadSaveRecord(thread, record) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    afterSave(initRec, savedRec, caler) {
      savedRec.status = 'saved'
      if (initRec.id.value === null) {
        this.addNewRecord(savedRec)
      } else {
        this.fields.forEach(fld => {
          initRec[fld.key] = savedRec[fld.key]
        })
        initRec.status = 'saved'
      }
    },
    getRecordClone(record) {
      return JSON.parse(JSON.stringify(record))
    },
    onClickRowDropdownButton(btn, index) {
      console.log('clickRowDropdownButton', btn, index)
    },
    onClickRowButton(btn, row) {
      console.log('clickRowButton', btn, row)
      this.$emit('clickRowButton', btn, row)
    },
    isDeleteAvailable() {
      return this.access && this.access.delete && (this.access.delete === 'grant' || this.access.delete === 'withgrant')
    },
    isRestoreAvailable() {
      return this.access && this.access.restore && (this.access.restore === 'grant' || this.access.restore === 'withgrant')
    },
    isAccessAvailable(permissiontype) {
      return this.objectFull.access && this.objectFull.access[permissiontype]
          && (this.objectFull.access[permissiontype] === 'grant' || this.objectFull.access[permissiontype] === 'withgrant')
    },
    onDelete(row) {
      // console.log('onDelete', row)
      this.deleteRecord(row)
    },
    onRemove(row) {
      this.rowToRemove = row
      this.$refs[`refBtmRemove_${this.uuid}`].click()
      // this.removeRecord(row)
    },
    onRestore(row) {
      console.log('onRestore', row)
      this.restoreRecord(row)
    },
    deleteRecord(row) {
      // console.log('restore record', row)
      row.item.status = 'changed'
      row.item.del.value = true
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'deleterecord',
          param: {
            objectid: this.objectid,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('delete record response', response)
          if (response.data) {
            if (response.data.thread) this.delayThreadDelete(response.data.thread, row)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    removeRecord(row) {
      row.item.status = 'changed'
      row.item.del.value = true
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'removerecord',
          param: {
            objectid: this.objectid,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('remove record response', response)
          if (response.data) {
            if (response.data.thread) this.delayThreadRemove(response.data.thread, row)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    restoreRecord(row) {
      console.log('restore record', row)
      row.item.status = 'changed'
      row.item.del.value = false
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'restorerecord',
          param: {
            objectid: this.objectid,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('restore record response', response)
          if (response.data) {
            if (response.data.thread) this.delayThreadRestore(response.data.thread, row)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    delayThreadDelete(threadName, row) {
      setTimeout(() => this.loadThreadDelete(threadName, row), 500)
    },
    delayThreadRemove(threadName, row) {
      setTimeout(() => this.loadThreadRemove(threadName, row), 500)
    },
    delayThreadRestore(threadName, row) {
      setTimeout(() => this.loadThreadRestore(threadName, row), 500)
    },
    loadThreadDelete(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadDelete response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done'
                  && response.data.thread.result && response.data.thread.result.record) {
                // this.records.splice(row.index, 1, response.data.thread.result.record)
                this.records[row.index].del.value = true
              } else {
                this.delayThreadDelete(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    loadThreadRemove(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadDelete response', response)
          if (response.data) {
            if (response.data.error) {
              this.toast(response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.records.splice(row.index, 1)
              } else {
                this.delayThreadRemove(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    loadThreadRestore(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadRestore response', response)
          if (response.data) {
            if (response.data.error) {
              this.toast(response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done'
                  && response.data.thread.result && response.data.thread.result.record) {
                this.records.splice(row.index, 1, response.data.thread.result.record)
              } else {
                this.delayThreadRestore(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    toast(error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Something went wrong',
          icon: 'CoffeeIcon',
          variant: 'warning',
          text: error,
        },
      })
      if (error === 'Session not found.') {
        useCubus.logout()
        this.$router.push({ name: 'auth-login' })
      }
    },
    isRowDeleted(row) {
      return row.item && row.item.del && row.item.del.value === true
    },
    onOkRemove() {
      this.removeRecord(this.rowToRemove)
    },
    onCancelRemove() {
      this.rowToRemove = {}
    },
    onCloseRemove() {
      this.rowToRemove = {}
    },
    isAdmin() {
      const sess = this.$store.getters['cubus-store/SESSION']
      return sess && sess.user && sess.user.sid && (sess.user.sid === 'root' || sess.user.sid === 'batman')
    },
    // eslint-disable-next-line no-underscore-dangle
    getData() { return this._data },
    // eslint-disable-next-line no-underscore-dangle
    getProps() { return this._props },
    showDetails(row) {
      console.log('showDetails row', row)
      console.log('showDetails record', this.records[row.index])
      this.$set(row.item, '_detailsMode', 'card')
      this.$set(row.item, '_showDetails', true)
    },
    onClickRelation(row, rel) {
      console.log('click relation', row, rel)
      this.$set(row.item, '_relation', rel)
      this.$set(row.item, '_relationFilter', this.relationFilter(row, rel))
      this.$set(row.item, '_detailsMode', 'relation')
      this.$set(row.item, '_showDetails', true)
    },
    relationFilter(row, rel) {
      console.log('row', row)
      console.log('rel', rel)
      const key = rel.identityid && rel.identityid !== 0 ? this.fields.find(f => f.entityid === rel.identityid).key : 'id'
      console.log('key', key)
      return {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entity' }, value: `${rel.entityid}` },
                { type: { sid: 'value' }, value: `${row.item[key].value}` },
              ],
            },
          ],
        },
      }
    },
    toggleSettings() {
      this.isSettings = !this.isSettings
    },
    toggleFilter() {
      this.isFilter = !this.isFilter
    },
    refreshTable() {
      this.records = []
      this.currentPage = 1
      this.loadQuery()
    },
    formatterNumber(value) {
      return Number(value)
    },
    changePage() { this.loadQuery() },
    objAttrs() { return this.fields.filter(f => f.entityid).map(f => useCubus.attributeToField(f)) },
    objAttrsFull() { return this.objectFull.attributes.map(f => useCubus.attributeToField(f)) },
    addNewRecord(rec) {
      this.records.push(rec)
      this.$refs.btnNew.click()
    },
    hasFileEntity() {
      return this.fields.some(f => f.datatype === 'file')
    },
    toggleGroupby() {
      this.isGroupby = !this.isGroupby
    },
    formatDouble(value) {
      return useCubus.formatNumber(value, null)
    },
    formatInteger(value) {
      return useCubus.formatNumber(value, { precision: 0 })
    },
    shownFields() {
      return this.fields.filter(fld => fld.visible)
    },
    setQuery(qry) {
      this.query = qry
      this.initTable()
      this.$emit('readyQuery', this.query)
    },
    visibleFields() {
      // return this.fields.filter(fld => fld.key && fld.visible)
      return this.prmFields
        .filter(prm => prm.visible)
        .filter(prm => this.fields.some(fld => fld.key === prm.key))
        .map(prm => this.fields.find(fld => fld.key === prm.key))
    },
    fieldUp(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.prmFields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.prmFields[idx - 1]))
      this.$set(this.prmFields, idx, tmp2)
      this.$set(this.prmFields, idx - 1, tmp1)
    },
    fieldDown(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.prmFields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.prmFields[idx + 1]))
      this.$set(this.prmFields, idx, tmp2)
      this.$set(this.prmFields, idx + 1, tmp1)
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  margin-top: 0;
}
.cbs-dropdown /deep/ .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
