<template>
  <div>
    <div style="border:solid #cccccc 1px;border-radius:5px;">
      <table class="table table-sm">
        <tr>
          <td><b-button block pill size="sm" variant="link-primary" @click="decrYear"><feather-icon icon="ChevronLeftIcon"></feather-icon></b-button></td>
          <td colspan="2"><b-button block size="sm" :variant="yearVariant()" @click="selectYear()">{{ year }}</b-button></td>
          <td><b-button block pill size="sm" variant="link-primary" @click="incrYear"><feather-icon icon="ChevronRightIcon"/></b-button></td>
          <td></td>
          <td><b-button block pill size="sm" variant="outline-danger" @click="clear()"><feather-icon icon="XIcon" /></b-button></td>
        </tr>
        <tr>
          <td><b-button block size="sm" :variant="monthVariant(1)" @click="selectMonth(1)">{{ monthName(1) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(2)" @click="selectMonth(2)">{{ monthName(2) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(3)" @click="selectMonth(3)">{{ monthName(3) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(4)" @click="selectMonth(4)">{{ monthName(4) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(5)" @click="selectMonth(5)">{{ monthName(5) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(6)" @click="selectMonth(6)">{{ monthName(6) }}</b-button></td>
        </tr>
        <tr>
          <td><b-button block size="sm" :variant="monthVariant(7)" @click="selectMonth(7)">{{ monthName(7) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(8)" @click="selectMonth(8)">{{ monthName(8) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(9)" @click="selectMonth(9)">{{ monthName(9) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(10)" @click="selectMonth(10)">{{ monthName(10) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(11)" @click="selectMonth(11)">{{ monthName(11) }}</b-button></td>
          <td><b-button block size="sm" :variant="monthVariant(12)" @click="selectMonth(12)">{{ monthName(12) }}</b-button></td>
        </tr>
      </table>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsPeriodPicker',
  components: {
    CbsDebug,
    BButton,
  },
  props: {
    start: {
      type: Object,
      default: null,
    },
    finish: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      beg: null,
      end: null,
    }
  },
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  methods: {
    monthName(index) {
      const objDate = new Date()
      objDate.setDate(1)
      objDate.setMonth(index - 1)
      const loc = this.locale
      const month = objDate.toLocaleString(loc, { month: 'short' })
      return month
    },
    monthVariant(index) {
      if (index === this.beg) return 'primary'
      if (index === this.end) return 'primary'
      if (this.beg && this.end && index >= this.beg && index <= this.end) return 'primary'
      return 'outline-primary'
    },
    yearVariant() {
      if (this.beg && this.end && this.beg === 1 && this.end === 12) return 'primary'
      return 'outline-primary'
    },
    selectMonth(index) {
      if (!this.beg) {
        this.setBeg(index)
        this.setEnd(index)
      } else if (this.beg && this.beg === this.end && index === this.beg) {
        this.clear()
      } else if (this.beg && this.beg === this.end && index !== this.beg) {
        if (index < this.beg) {
          this.setEnd(this.beg)
          this.setBeg(index)
        } else {
          this.setEnd(index)
        }
      } else if (this.beg && this.end && index === this.end) {
        this.setEnd(this.end - 1)
      } else if (this.beg && this.end && index > this.beg && index !== this.end) {
        this.setEnd(index)
      } else if (index === this.beg) {
        this.setBeg(index + 1)
      } else if (index < this.beg) {
        this.setBeg(index)
      }
    },
    selectYear() {
      if (this.beg && this.end && this.beg === 1 && this.end === 12) {
        this.clear()
      } else {
        this.setBeg(1)
        this.setEnd(12)
      }
    },
    clear() {
      this.setBeg(null)
      this.setEnd(null)
    },
    setBeg(index) {
      this.beg = index
      if (!index) {
        this.start.cell.value = null
      } else {
        this.start.cell.value = this.dateToString(new Date(new Date().setFullYear(this.year, index - 1, 1)))
      }
    },
    setEnd(index) {
      this.end = index
      if (!index) {
        this.finish.cell.value = null
      } else {
        this.finish.cell.value = this.dateToString(new Date(new Date().setFullYear(this.year, index, 0)))
      }
    },
    dateToString(dt) {
      const mm = dt.getMonth() + 1 // getMonth() is zero-based
      const dd = dt.getDate()
      return [
        dt.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
      ].join('-')
    },
    incrYear() {
      this.year += 1
      this.clear()
    },
    decrYear() {
      this.year -= 1
      this.clear()
    },
  },
}
</script>

<style scoped>

</style>
