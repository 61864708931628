<template>
  <div>
    <div v-if="fieldset">
      <table>
        <tr v-for="(fld, fldIdx) in fieldset.fields" :key="fldIdx">
          <td>
            <feather-icon v-if="fldIdx > 0" icon="ArrowUpCircleIcon" size="16" class="text-primary cursor-pointer"
                          @click="fieldUp(fldIdx)"
            />
            &nbsp;
          </td>
          <td>
            <feather-icon v-if="fldIdx < (fieldset.fields.length-1)" icon="ArrowDownCircleIcon" size="16"
                          class="text-primary cursor-pointer"
                          @click="fieldDown(fldIdx)"
            />
            &nbsp;&nbsp;
          </td>
          <td>
            <feather-icon :icon="fld.visible ? 'EyeIcon' : 'EyeOffIcon'" size="16"
                          class="cursor-pointer"
                          :class="fld.visible ? 'text-success' : 'text-danger'"
                          @click="fld.visible = ! fld.visible"
            />
            &nbsp;&nbsp;
          </td>
          <td>&nbsp;{{ fld.label }}</td>
        </tr>
      </table>
    </div>
    <b-button v-if="true || isAdmin" size="sm" variant="outline-primary" @click="reset">Reset</b-button>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'CbsFields',
  components: {
    CbsDebug,
    BButton,
  },
  props: {
    fieldset: {
      type: Object,
      default: null,
    },
  },
  methods: {
    fieldUp(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.fieldset.fields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.fieldset.fields[idx - 1]))
      this.$set(this.fieldset.fields, idx, tmp2)
      this.$set(this.fieldset.fields, idx - 1, tmp1)
    },
    fieldDown(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.fieldset.fields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.fieldset.fields[idx + 1]))
      this.$set(this.fieldset.fields, idx, tmp2)
      this.$set(this.fieldset.fields, idx + 1, tmp1)
    },
    reset() {
      this.fieldset.fields = []
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
  },
}
</script>

<style scoped>

</style>
