<template>
  <div>
    <div>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th style="position: sticky; left: 0; z-index: 3;"></th>
          <th>jan</th>
          <th>feb</th>
          <th>mar</th>
          <th>apr</th>
          <th>may</th>
          <th>jun</th>
          <th>jul</th>
          <th>aug</th>
          <th>sep</th>
          <th>oct</th>
          <th>nov</th>
          <th>dec</th>
          <th>2025</th>
          <th>2026</th>
          <th>2027</th>
          <th>2028</th>
          <th>2029</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="table-light" style="position: sticky; left: 0; z-index: 2;">Начисление</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="table-light" style="position: sticky; left: 0; z-index: 2;">Оплата</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsBudreqFinance',
  components: {
    CbsDebug,
  },
  props: {
    budgetRequest: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
