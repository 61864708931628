<template>
  <div>
    <b-input-group class="mb-1">
      <b-form-input
        id="example-input"
        v-model="cell.value"
        type="text"
        placeholder="YYYY-MM-DD"
        autocomplete="off"
        show-decade-nav
      />
      <b-input-group-append>
        <b-form-datepicker
          v-model="cell.value"
          show-decade-nav
          button-only
          button-variant="outline-primary"
          right
          size="sm"
          locale="en-US"
          aria-controls="example-input"
          @context="onContext"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'CbsDatePicker',
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
  },
  props: {
    cell: {
      type: Object,
      default: null,
    },
    status: {
      type: Object,
      default: () => ({ state: 'init' }),
    },
    params: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
}
</script>

<style scoped>

</style>
