<template>
  <div class="frame">
    <div>
      <span style="cursor: pointer;" @click="expand()">
        <feather-icon style="margin-bottom: 3px;" :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="16" />&nbsp;
        <span style="cursor: pointer;">{{ role ? role.name.value : user.name.value }}</span>
      </span>
      <span style="margin-left: 1rem;">
        <feather-icon icon="SaveIcon" size="16" class="text-primary" style="margin-left: 1rem; cursor: pointer; margin-bottom: 3px;" @click="save()" />
      </span>
    </div>
    <cbs-collapse :trigger="record && expanded">
      <div class="expand">
        <b-row>
          <b-col cols="1"></b-col>
          <b-col cols="11">
            <b-row>
              <b-col cols="1"></b-col>
              <b-col cols="3" style="padding-left: 1rem;"><label>Title:</label></b-col>
              <b-col cols="3"><label>Icon:</label></b-col>
              <b-col cols="5"><label>Object:</label></b-col>
            </b-row>
          </b-col>
        </b-row>
        <div v-if="false">
          <cbs-user-menu-item v-for="(item, idx) in record[keyJsonMenu].value.items" :key="idx" :item="item"
                              :ent-object="entObject" :ref-object="refObject"
                              @remove-item="removeItem"
          />
        </div>
        <div>
          <draggable v-model="record[keyJsonMenu].value.items" tag="ul" handle=".handle">
            <b-list-group-item v-for="(item, idx) in record[keyJsonMenu].value.items" :key="idx" tag="div" class="list">
              <div style="padding: 0;">
                <b-row>
                  <b-col cols="1" style="margin-bottom: 1rem;">
                    <div class="handle cursor-move handler border-secondary">
                      <feather-icon icon="MoreVerticalIcon" size="16" />
                    </div>
                  </b-col>
                  <b-col cols="11" style="padding-left: 0;">
                    <cbs-user-menu-item :item="item"
                                        :ent-object="entObject" :ref-object="refObject"
                                        @remove-item="removeItem"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-list-group-item>
          </draggable>
        </div>
        <b-button variant="outline-primary" class="btn-sm" @click="addItem()">+ Add Element</b-button>
      </div>
    </cbs-collapse>

    <cbs-debug hidden :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsUserMenuItem from '@/cubus/components/cbs-form/custom-forms/user-menu/CbsUserMenuItem.vue'
import {
  BButton, BCol, BListGroupItem, BRow,
} from 'bootstrap-vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import draggable from 'vuedraggable'

export default {
  name: 'CbsUserMenuConstr',
  components: {
    CbsCollapse,
    CbsDebug,
    CbsUserMenuItem,
    BRow,
    BCol,
    BButton,
    BListGroupItem,
    draggable,
  },
  props: {
    role: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    keyJsonMenu: {
      type: String,
      default: 'jsonusermenu',
    },
    entObject: {
      type: Object,
      default: null,
    },
    refObject: {
      type: Array,
      default: null,
    },
  },
  emits: [
    'save',
  ],
  created() {
    this.init()
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    init() {},
    items() {
      return this.record[this.keyJsonMenu].value.items
    },
    expand() {
      this.expanded = !this.expanded
    },
    addItem() {
      this.record[this.keyJsonMenu].value.items.push({
        title: 'New Item',
        icon: 'CircleIcon',
        object: '',
        route: '',
        children: [],
      })
    },
    removeItem(item) {
      const idx = this.record[this.keyJsonMenu].value.items.indexOf(item)
      this.record[this.keyJsonMenu].value.items.splice(idx, 1)
    },
    save() {
      this.$emit('save')
    },
  },
}
</script>

<style scoped>
.frame {
  margin: 0.5rem;
  padding: 1rem;
  border: solid #cccccc 1px;
  border-radius: 0.5rem;
}
.expand {
  margin: 0.5rem;
  padding: 0.5rem;
  border-bottom: solid #cccccc 1px;
  border-left: solid #cccccc 1px;
  border-right: solid #cccccc 1px;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.list {
  padding: 0;
  margin: 0;
  border: none;
}
.handler {
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: .3rem;
  max-width: 2rem;
}
ul {
  padding-left:1rem;
}
</style>
