<template>
  <div>
    <div>
      <table v-if="qry" class="table">
        <thead v-if="!parentid">
          <tr>
            <th></th>
            <th>код</th>
            <th>наименование</th>
            <th>descr</th>
          </tr>
        </thead>
        <tbody v-for="rec in qry.recordset.records" :key="rec.id.value">
          <tr>
            <td :style="rec.isfolder.value ? 'cursor: pointer;' : ''" @click="expand(rec)">
              <feather-icon v-if="rec.isfolder.value" :icon="rec.expand ? 'MinusSquareIcon' : 'PlusSquareIcon'" size="16" />
            </td>
            <td>{{ rec.code.value }}</td>
            <td>{{ rec.name.value }}</td>
            <td><!--{{ rec }}--></td>
          </tr>
          <tr v-if="rec.expand">
            <td colspan="4" style="margin-left: 0; padding: 0;">
              <cbs-collapse :trigger="rec.expand">
                <div class="cbs-subtable">
                  <cbs-tree-view :object="object" :parentid="rec.id.value" />
                </div>
              </cbs-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-data-query hidden :object="object" :filter="filter()" :orderby="orderby()" :page-size="100000" @load="onLoadQry" />
    <cbs-debug hidden :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'CbsTreeView',
  components: {
    CbsCollapse,
    CbsDataQuery,
    CbsDebug,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    parentid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      qry: null,
      childrenExpanded: false,
      cardExpanded: false,
    }
  },
  methods: {
    filter() {
      if (!this.parentid) {
        return {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'isnull' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'parent' },
                ],
              },
            ],
          },
        }
      }
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'parent' },
                { type: { sid: 'value' }, value: this.parentid },
              ],
            },
          ],
        },
      }
    },
    orderby() {
      return {
        isActive: true,
        sorts: [
          {
            isActive: true,
            oper: { sid: 'asc' },
            entity: { sid: 'code' },
          },
        ],
      }
    },
    onLoadQry(qry) {
      this.qry = qry
    },
    expand(rec) {
      if (!rec.isfolder.value)
        return
      if (Object.keys(rec).includes('expand')) {
        rec.expand = !rec.expand
      } else {
        this.$set(rec, 'expand', true)
      }
    },
  },
}
</script>

<style scoped>
.cbs-subtable {
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-left: solid #cccccc 1px;
  border-bottom: solid #cccccc 1px;
  border-bottom-left-radius: 1rem;
}

</style>
