<template>
  <div>
    <b-button-toolbar class="cbs-inline-spacing mb-1">
      <b-button-group>
        <b-button variant="flat-primary" disabled>
          <feather-icon v-if="valueType() === 'string'" icon="TypeIcon" />
          <feather-icon v-else-if="valueType() === 'number'" icon="HashIcon" />
          <feather-icon v-else-if="valueType() === 'boolean'" :icon="value() ? 'CheckSquareIcon' : 'SquareIcon'" />
          <feather-icon v-else-if="valueType() === 'date'" icon="CalendarIcon" />
          <feather-icon v-else icon="CircleIcon" />
        </b-button>
      </b-button-group>
      <b-col>
        <b-input-group>
          <b-input-group-prepend v-if="valueType() === 'boolean'" is-text>
            <b-form-checkbox v-model="parentArray[arrayIndex]" disabled/>
          </b-input-group-prepend>
          <b-form-input v-if="valueType() !== 'boolean'" v-model="parentArray[arrayIndex]" disabled />
          <b-input-group-append>
            <b-button variant="outline-danger" @click="deleteMe"><feather-icon icon="TrashIcon" /> Delete</b-button>
          </b-input-group-append>
        </b-input-group>

      </b-col>
    </b-button-toolbar>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import CbsDebug from "@/cubus/components/debug/CbsDebug";

export default {
  name: 'CbsJsonArrayValue',
  components: {
    CbsDebug,
    BFormGroup,
    BFormInput,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCol,
    BFormCheckbox,
  },
  props: {
    container: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    parentArray: {
      type: Array,
      default: null,
    },
    arrayIndex: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      mode: 'string',
    }
  },
  create() {
    this.init()
  },
  methods: {
    init() {
      console.log('parentArray', this.parentArray)
    },
    valueType() {
      // const attr = this.container[this.name]
      const attr = this.parentArray[this.arrayIndex]
      if (attr instanceof Array) return 'array'
      if (attr === null) return 'string'
      return typeof this.parentArray[this.arrayIndex]
    },
    toNumber() {
      if (this.container) {
        this.container[this.name] = Number(this.container[this.name])
      } else {
        this.parentArray[this.arrayIndex] = Number(this.parentArray[this.arrayIndex])
      }
    },
    toStringValue() {
      this.container[this.name] = this.container[this.name].toString()
    },
    toBoolean() {
      this.container[this.name] = !!this.container[this.name]
    },
    deleteMe() {
      this.$emit('delete', this.arrayIndex)
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

</style>
