<template>
  <div>
    <cbs-table-view-param v-if="objectFull.object.sid !== 'tableviewparam'"
                          hide
                          :object="objectFull.object"
                          @applydefault="onApplyParam"
    />
    <cbs-object-table v-if="isViewParam"
                      ref="refTable"
                      :objectid="objectFull.object.id"
                      :filter="resFilter()"
                      :prop-groupby="prmGroupby"
                      :prop-orderby="prmOrderby"
                      :domainsid="domainsid"
                      :row-buttons="rowButtons"
                      :row-dropdown-buttons="rowDropdownButtons"
                      :object-full="objectFull"
                      :hidden-fields="propHiddenFields()"
                      @clickRowButton="onClickRowButton"
    />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsTableViewParam from '@/cubus/components/table-view-param/CbsTableViewParam.vue'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'

export default {
  name: 'CbsTableView',
  components: {
    CbsDebug,
    CbsTableViewParam,
    CbsObjectTable,
  },
  props: {
    objectFull: {
      type: Object,
      required: true,
    },
    domainsid: {
      type: String,
      default: '',
    },
    rowDropdownButtons: {
      type: Array,
      default: () => ([]),
    },
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isViewParam: false,
      prmFilter: null,
      prmGroupby: null,
      prmOrderby: null,
      prmPagesize: null,
      prmFields: null,
    }
  },
  methods: {
    onApplyParam(prm) {
      if (prm.status === 'default') {
        this.prmFilter = prm.filter
        this.prmGroupby = prm.groupby
        this.prmOrderby = prm.orderby
        this.prmPagesize = prm.pagesize
        this.prmFields = prm.fields
      }
      this.isViewParam = true
    },
    propHiddenFields() {
      const h = [...this.hiddenFields]
      if (!this.prmFields) return h
      this.prmFields.forEach(fld => {
        if (fld.visible === false && this.hiddenFields.includes(fld.key) === false) {
          this.hiddenFields.push(fld.key)
        }
      })
      return h
    },
    onClickRowButton(btn, row) {
      this.$emit('clickRowButton', btn, row)
    },
    resFilter() {
      if (this.filter !== {}) {
        return this.filter
      }
      return this.prmFilter
    },
  },
}
</script>

<style scoped>

</style>
